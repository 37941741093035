import {
  Box,
  Button,
  Flex,
  Grid,
  IconButton,
  Link,
  Text,
} from "@chakra-ui/react";
import { Toast } from "components/toast";
import apiDigitacao from "api/api-digitacao";
import {
  Attendance,
  Convenio,
} from "components/atendimentos-components/atendimento-form/types";
import { useAttendanceContext } from "contexts/attendance-context";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { DocumentIcon } from "components/vectors/document-icon";
import {
  ModalDigitarPropostaFGTS,
  openModalDigitarProposta,
} from "../modal-digitar-proposta";
import { SkeletonLoading } from "components/skeleton-loading";
import {
  ModalAcompanharProposta,
  openModalAcompanharProposta,
} from "./modal-acompanhar-proposta";
import { CopyIcon, DeleteIcon } from "@chakra-ui/icons";
import { openModalConfirm } from "components/modal-confirm-new";
import { dropdownConvenioAtendimento } from "components/atendimentos-components/atendimento-form/fields-data";
import { dropdownBancosSimulacaoFGTS } from "../../simulacoes/utils";
import {
  ModalDigitacaoINSS,
  openModalDigitacaoINSS,
} from "../../margens/modal-digitacao-inss";
import { bancosParse } from "../modal-digitar-proposta/utils";
import { AttendanceTabKey } from "components/atendimentos-components/pagina-atendimento/attendance-data-form";
import {
  ModalConsultarProposta,
  openModalConsultarProposta,
} from "./modal-consultar-proposta";
import { currencyMask, currencyMaskFormat } from "utils/string-formats";
import {
  ModalDigitacaoCartao,
  openModalDigitacaoCartao,
} from "../../margens/modal-digitacao-cartao";
import {
  ModalDigitacaoSaque,
  openModalDigitacaoSaque,
} from "../../contratos/modal-digitacao-saque";

export interface DigitadoRow {
  convenio: Convenio;
  banco: string;
  ade: string;
  lastStatusADE?: string;
  linkFormalizacao: string;
  dataDigitacao: string;
  id: number;
  status: "PENDING" | "FINISHED" | "SYSTEM_ERROR" | null;
  messageDigitacao: string | null;
  codeRequestDigitacao: "SUCCESS" | "SYSTEM_ERROR" | null;
  isLoading?: boolean; // Campo da interface (não vem no backend)
  stormContratoFF: string | null;
  valorLiquidoCliente: number;
  operacao: string;
  valorLimiteCartao?: number;
}

export function DigitadosAtendimentoPart({
  isLoading,
  setIsLoading,
  onChangeTab,
  telaAtendimento,
}: {
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  onChangeTab: (tabKey: AttendanceTabKey) => void;
  telaAtendimento: boolean;
}) {
  const [loadingTabela, setLoadingTabela] = useState(false);
  const [emptyMessage, setEmptyMessage] = useState("");
  const [digitadosList, setDigitadosList] = useState<DigitadoRow[]>([]);
  const { formControl } = useAttendanceContext();
  const attendance = formControl.values as Attendance;

  const getDigitados = async () => {
    const url =
      attendance.convenio === "INSS"
        ? `/api/digitacoes/inss?atendimento_id=${attendance.id}`
        : `/api/digitacoes?atendimento_id=${attendance.id}`;
    setLoadingTabela(true);
    try {
      const { data } = await apiDigitacao.get(url);
      if (!data.length) {
        setEmptyMessage("Não há digitações");
      } else setEmptyMessage("");
      setDigitadosList(data);
    } catch (e) {
      Toast({ title: "Erro ao obter lista", status: "error" });
    } finally {
      setLoadingTabela(false);
    }
  };

  useEffect(() => {
    getDigitados();
  }, []);

  const consultarProposta = async (
    row: DigitadoRow,
    onChange: (k: keyof DigitadoRow | null, v: any) => void
  ) => {
    onChange("isLoading", true);
    try {
      const { data } = await apiDigitacao.get(
        `/api/digitacoes/fgts/consultar-proposta?digitacao_id=${row.id}`
      );

      if (data.status === "PENDING") {
        Toast({ title: "Digitação em andamento", status: "info" });
      } else if (
        data.status === "FINISHED" &&
        data.codeRequestDigitacao === "SUCCESS"
      ) {
        Toast({ title: "Consultado com sucesso", status: "success" });
      }
      onChange(null, data);
    } catch (e) {
      Toast({ title: "Erro ao consultar", status: "error" });
    } finally {
      onChange("isLoading", false);
    }
  };

  const deleteDigitacaoFGTS = async ({
    onChange,
    index,
    row,
  }: {
    onChange: (k: keyof DigitadoRow | null, v: any) => void;
    index: number;
    row: DigitadoRow;
  }) => {
    onChange("isLoading", true);
    try {
      const { data } = await apiDigitacao.delete(
        `/api/digitacoes/fgts/${row.id}`
      );
      setDigitadosList((prev) => prev.filter((r, i) => i !== index));
    } catch (e) {
      Toast({ title: "Erro ao excluir digitação" });
    } finally {
      onChange("isLoading", false);
    }
  };

  const deleteDigitacaoINSS = async ({
    onChange,
    index,
    row,
  }: {
    onChange: (k: keyof DigitadoRow | null, v: any) => void;
    index: number;
    row: DigitadoRow;
  }) => {
    onChange("isLoading", true);
    try {
      const { data } = await apiDigitacao.delete(
        `/api/digitacoes/inss/${row.id}`
      );
      setDigitadosList((prev) => prev.filter((r, i) => i !== index));
    } catch (e) {
      Toast({ title: "Erro ao excluir digitação" });
    } finally {
      onChange("isLoading", false);
    }
  };

  return (
    <>
      {loadingTabela ? (
        <SkeletonLoading isLoading w="100%" h="192px" borderRadius="4px" />
      ) : (
        <>
          {emptyMessage}
          {digitadosList.map((row, index, arr) => {
            const isLast = index + 1 === arr.length;
            const onChange = (k: keyof DigitadoRow | null, v: any) =>
              setDigitadosList((prev) =>
                prev.map((r, i) =>
                  i === index ? (k == null ? v : { ...r, [k]: v }) : r
                )
              );
            const allDisabledDigitacaoINSS = ["C6", "FACTA"].includes(row.banco)
              ? row.codeRequestDigitacao !== "SYSTEM_ERROR"
              : true;

            const isVisibleAcompanharProposta =
              row.convenio === "ANTECIPACAO_FGTS" || row.convenio === "INSS";

            const isVisibleConsultarProposta =
              row.convenio === "ANTECIPACAO_FGTS" || row.convenio === "INSS";

            const digitacaoCartao =
              row.operacao === "CARTAO_NOVO_RMC" ||
              row.operacao === "CARTAO_NOVO_RCC";

            return (
              <Box
                key={row.id}
                border="1px solid var(--chakra-colors-custom-gray)"
                bg="var(--chakra-colors-gray-100)"
                p="10px 15px"
                borderRadius="4px"
                mb={isLast ? "" : "20px"}
                pos="relative"
                fontWeight="medium"
              >
                {row.codeRequestDigitacao === "SYSTEM_ERROR" &&
                (row.convenio === "ANTECIPACAO_FGTS" ||
                  row.convenio === "INSS") ? (
                  <IconButton
                    pos="absolute"
                    top="10px"
                    right="15px"
                    aria-label=""
                    size="xs"
                    variant="danger"
                    icon={<DeleteIcon />}
                    isLoading={row.isLoading}
                    onClick={() => {
                      openModalConfirm({
                        message: "Deseja excluir digitação?",
                        onConfirm: () => {
                          if (row.convenio === "ANTECIPACAO_FGTS") {
                            deleteDigitacaoFGTS({ onChange, index, row });
                          } else {
                            deleteDigitacaoINSS({ onChange, index, row });
                          }
                        },
                      });
                    }}
                  />
                ) : null}
                <Grid templateColumns="1fr 1fr" gap="14px">
                  <Text>
                    <b>Convênio: </b>
                    <br />
                    <Text as="span">
                      {dropdownConvenioAtendimento.find(
                        ({ value }) => value === row.convenio
                      )?.name || ""}
                      &nbsp;
                    </Text>
                  </Text>
                  <Text>
                    <b>Banco: </b>
                    <br />
                    <Text as="span">
                      {bancosParse.find(({ value }) => value === row.banco)
                        ?.name || ""}
                      &nbsp;
                    </Text>
                  </Text>
                  <Text>
                    <b>Operação: </b>
                    <br />
                    <Text as="span">
                      {[
                        { name: "Cartão Novo RMC", value: "CARTAO_NOVO_RMC" },
                        { name: "Cartão Novo RCC", value: "CARTAO_NOVO_RCC" },
                        {
                          name: "Saque Complementar RCC",
                          value: "SAQUE_COMPLEMENTAR_RCC",
                        },
                        {
                          name: "Saque Complementar RMC",
                          value: "SAQUE_COMPLEMENTAR_RMC",
                        },
                      ].find(({ value }) => value === row.operacao)?.name ||
                        row.operacao ||
                        ""}
                      &nbsp;
                    </Text>
                  </Text>
                  <Text>
                    <b>Data digitação: </b>
                    <br />
                    <Text as="span">{row.dataDigitacao || ""}&nbsp;</Text>
                  </Text>
                  <Text>
                    <b>
                      {digitacaoCartao
                        ? "Valor Saque Previsto"
                        : "Valor Líquido"}
                      :{" "}
                    </b>
                    <br />
                    <Text as="span">
                      {row.valorLiquidoCliente
                        ? `R$ ${currencyMask(
                            row.valorLiquidoCliente?.toFixed(2)
                          )}`
                        : null}
                      &nbsp;
                    </Text>
                  </Text>
                  {digitacaoCartao ? (
                    <Text>
                      <b>Valor Limite Cartão:</b>
                      <br />
                      <Text as="span">
                        {row.valorLimiteCartao
                          ? `R$ ${currencyMask(
                              row.valorLimiteCartao?.toFixed(2)
                            )}`
                          : null}
                        &nbsp;
                      </Text>
                    </Text>
                  ) : null}

                  <Text>
                    <b>Storm FF: </b>
                    <br />
                    <Text as="span">{row.stormContratoFF || ""}&nbsp;</Text>
                  </Text>
                  <Text>
                    <b>ADE: </b>
                    <br />
                    <Text as="span">{row.ade || ""}&nbsp;</Text>
                  </Text>
                  <Text>
                    <b>Status ADE: </b>
                    <br />
                    <Text as="span">{row.lastStatusADE || ""}&nbsp;</Text>
                  </Text>

                  <Text gridArea="auto / span 2">
                    <b>Mensagem Digitação: </b>
                    <br />
                    <Text
                      as="span"
                      color={
                        row.codeRequestDigitacao === "SYSTEM_ERROR"
                          ? "red.600"
                          : row.codeRequestDigitacao === "SUCCESS"
                          ? "green.600"
                          : ""
                      }
                    >
                      {row.messageDigitacao || ""}&nbsp;
                    </Text>
                  </Text>
                  <Flex
                    flexDir="column"
                    // alignItems="center"
                    gridArea="auto / span 2"
                  >
                    <b>Link Formalização: </b>
                    {row.linkFormalizacao ? (
                      <Flex
                        bg="var(--chakra-colors-custom-gray)"
                        px="5px"
                        py="2px"
                        borderRadius="3px"
                        justifyContent="space-between"
                      >
                        {row.linkFormalizacao.includes(".") ? (
                          <Link
                            target="_blank"
                            href={"https://" + row.linkFormalizacao}
                            // whiteSpace="nowrap"
                            fontWeight="500"
                            maxW="calc(100% - 72px - 4px)"
                            whiteSpace="pre-wrap"
                          >
                            {row.linkFormalizacao}
                          </Link>
                        ) : (
                          <Text
                            whiteSpace="pre-wrap"
                            maxW="calc(100% - 72px - 4px)"
                          >
                            {row.linkFormalizacao}
                          </Text>
                        )}
                        <Button
                          aria-label=""
                          size="xs"
                          leftIcon={<CopyIcon />}
                          variant="outline"
                          px="5px"
                          // ml="auto"
                          border="none"
                          h="20px"
                          minW="72px"
                          ml="4px"
                          onClick={() => {
                            navigator.clipboard.writeText(row.linkFormalizacao);
                          }}
                        >
                          Copiar
                        </Button>
                      </Flex>
                    ) : null}
                  </Flex>
                  <Grid
                    templateColumns={`1fr${
                      isVisibleConsultarProposta ? " 1fr" : ""
                    }${isVisibleAcompanharProposta ? " 1fr" : ""}`}
                    gap="12px"
                    gridArea="auto / span 2"
                    mt="12px"
                  >
                    {isVisibleConsultarProposta ? (
                      <Button
                        size={telaAtendimento ? undefined : "xs"}
                        rightIcon={<DocumentIcon width="20px" height="20px" />}
                        onClick={() => {
                          if (row.convenio === "INSS") {
                            openModalConsultarProposta({
                              row,
                              onChangeRow: onChange,
                            });
                          } else if (row.convenio === "ANTECIPACAO_FGTS") {
                            consultarProposta(row, onChange);
                          }
                        }}
                        isDisabled={row.status !== "PENDING"}
                        isLoading={row.isLoading}
                      >
                        Consultar
                      </Button>
                    ) : null}
                    {isVisibleAcompanharProposta ? (
                      <Button
                        size={telaAtendimento ? undefined : "xs"}
                        rightIcon={<DocumentIcon width="20px" height="20px" />}
                        isDisabled={!row.ade || row.isLoading}
                        onClick={() => {
                          openModalAcompanharProposta({
                            row,
                            onChangeRow: onChange,
                          });
                        }}
                      >
                        Acompanhar
                      </Button>
                    ) : null}

                    <Button
                      size={telaAtendimento ? undefined : "xs"}
                      rightIcon={<DocumentIcon width="20px" height="20px" />}
                      onClick={() => {
                        if (row.convenio === "ANTECIPACAO_FGTS") {
                          openModalDigitarProposta({
                            bancoDigitacao: row.banco,
                            updateOptions: {
                              allDisabled:
                                row.codeRequestDigitacao !== "SYSTEM_ERROR",
                              digitacaoData: row,
                              onUpdate: (newRow: DigitadoRow) => {
                                onChange(null, newRow);
                              },
                            },
                            digitacaoId: row.id,
                          });
                        } else if (row.convenio === "INSS") {
                          if (
                            row.operacao === "CARTAO_NOVO_RMC" ||
                            row.operacao === "CARTAO_NOVO_RCC"
                          ) {
                            openModalDigitacaoCartao({
                              modalConfig: {
                                allDisabled: allDisabledDigitacaoINSS,
                                idDigitacao: row.id,
                                onUpdate: (newRow: DigitadoRow) => {
                                  onChange(null, newRow);
                                },
                              },
                              digitacaoFields: {
                                operacao:
                                  row.operacao === "CARTAO_NOVO_RMC"
                                    ? "RMC"
                                    : "RCC",
                              },
                              simulacao: { banco: row.banco },
                            });
                          } else if (
                            row.operacao === "SAQUE_COMPLEMENTAR_RMC" ||
                            row.operacao === "SAQUE_COMPLEMENTAR_RCC"
                          ) {
                            openModalDigitacaoSaque({
                              modalConfig: {
                                allDisabled: allDisabledDigitacaoINSS,
                                idDigitacao: row.id,
                                onUpdate: (newRow: DigitadoRow) => {
                                  onChange(null, newRow);
                                },
                              },
                              digitacaoFields: {
                                tipoSaque:
                                  row.operacao === "SAQUE_COMPLEMENTAR_RMC"
                                    ? "SAQUE_RMC"
                                    : "SAQUE_RCC",
                              },
                              simulacao: { banco: row.banco },
                            });
                          } else {
                            openModalDigitacaoINSS({
                              modalConfig: {
                                allDisabled: allDisabledDigitacaoINSS,
                                idDigitacao: row.id,
                                onUpdate: (newRow: DigitadoRow) => {
                                  onChange(null, newRow);
                                },
                              },
                              simulacao: { banco: row.banco },
                            });
                          }
                        }
                      }}
                      isDisabled={row.isLoading}
                    >
                      {!allDisabledDigitacaoINSS
                        ? "Corrigir Fomulário"
                        : "Ver Fomulário"}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            );
          })}
        </>
      )}

      <ModalDigitarPropostaFGTS />
      <ModalDigitacaoINSS />
      <ModalDigitacaoCartao />
      <ModalDigitacaoSaque />

      <ModalConsultarProposta attendance={attendance} />
      <ModalAcompanharProposta attendance={attendance} />
    </>
  );
}
