import { Column } from "components/dynamic-table/types";
import { currencyMask, maskCPF } from "utils/string-formats";
import { ContratoCartao, EmprestimoBancario } from "../consulta-fields/types";
import { PDFColumn } from "components/pdf-table";
import { CSSProperties } from "react";

export const completeCPF = (cpf: string) => {
  if (cpf != null && cpf)
    for (let i = 0; i <= 11; i++) if (i > cpf.length) cpf = "0" + cpf;
  return maskCPF(cpf);
};

const dateFormat = (data: string, withOutYears = false) => {
  const today = new Date();
  const birthDate = new Date(data);
  let age = today.getFullYear() - birthDate.getUTCFullYear();
  const month = today.getMonth() - birthDate.getUTCMonth();
  const day = today.getDate() - birthDate.getUTCDate();

  if (month < 0) age -= 1;
  else if (month === 0 && day < 0) age -= 1;

  return `${data?.split("-").reverse().join("/") ?? ""}${
    withOutYears ? "" : ` (${age} anos)`
  }`;
};

export function getDemonstrativosEmprestimosColumns(): PDFColumn[] {
  return [
    {
      name: (
        <Text fontSize="12px" textAlign="start">
          Empréstimo
        </Text>
      ),
      render: (row) => {
        return (
          <Text fontSize="12px" textAlign="start">
            {(row.contrato as string).slice(0, 4)}
          </Text>
        );
      },
    },
    {
      name: (
        <Text fontSize="12px" textAlign="start">
          CBC/Banco
        </Text>
      ),
      render: (row) => {
        return (
          <Text whiteSpace="pre-wrap" fontSize="12px" textAlign="start">{`${
            row.banco.codigo
          } - ${row.banco.nome ?? "Não informado"}`}</Text>
        );
      },
    },
    {
      name: (
        <Text whiteSpace="pre-wrap" fontSize="12px" textAlign="start">
          Comp. 1ª Parcela
        </Text>
      ),
      render: (row) => {
        return row.competenciaInicioDesconto ? (
          <Text fontSize="12px" textAlign="start">
            {dateFormat(row.competenciaInicioDesconto, true)}
          </Text>
        ) : null;
      },
    },
    {
      name: (
        <Text whiteSpace="pre-wrap" fontSize="12px" textAlign="start">
          Taxa Sugerida
        </Text>
      ),
      render: (row: EmprestimoBancario) => (
        <Text fontSize="12px" textAlign="start">
          {Number(row.taxa).toFixed(2)}%
        </Text>
      ),
    },
    {
      name: (
        <Text whiteSpace="pre-wrap" fontSize="12px" textAlign="start">
          Data Averbação
        </Text>
      ),
      render: (row) => {
        return row.dataInclusao ? (
          <Text fontSize="12px" textAlign="start">
            {dateFormat(row.dataInclusao, true)}
          </Text>
        ) : null;
      },
    },
    {
      name: (
        <Text fontSize="12px" textAlign="start">
          Parcelas
        </Text>
      ),
      render: (row) => {
        return (
          <Text fontSize="12px" textAlign="start">
            {row.quantidadeParcelasEmAberto}/{row.quantidadeParcelas}
          </Text>
        );
      },
    },
    {
      name: (
        <Text whiteSpace="pre-wrap" fontSize="12px" textAlign="start">
          Valor Parcela
        </Text>
      ),
      render: (row) => {
        return typeof row.valorParcela === "number" ? (
          <Text fontSize="12px" textAlign="start">
            R$ {currencyMask(row.valorParcela.toFixed(2))}
          </Text>
        ) : null;
      },
    },
    {
      name: (
        <Text whiteSpace="pre-wrap" fontSize="12px" textAlign="start">
          Valor Emprestado
        </Text>
      ),
      render: (row: EmprestimoBancario) => {
        return typeof row.valorEmprestado === "number" ? (
          <Text fontSize="12px" textAlign="start">
            R$ {currencyMask(row.valorEmprestado.toFixed(2))}
          </Text>
        ) : null;
      },
    },
  ];
}

export function getContratosCartaoTableColumns(): PDFColumn[] {
  return [
    {
      name: (
        <Text fontSize="12px" textAlign="start">
          Nº do Contrato
        </Text>
      ),
      render: (row: ContratoCartao) => {
        return (
          <Text fontSize="12px" textAlign="start">
            {row.contrato}
          </Text>
        );
      },
    },
    {
      name: (
        <Text fontSize="12px" textAlign="start">
          CBC/Banco
        </Text>
      ),
      render: (row: ContratoCartao) => {
        return (
          <Text whiteSpace="pre-wrap" fontSize="12px" textAlign="start">{`${
            row.banco.codigo
          } - ${row.banco.nome ?? "Não informado"}`}</Text>
        );
      },
    },
    {
      name: (
        <Text fontSize="12px" textAlign="start">
          Data Inclusão
        </Text>
      ),
      render: (row: ContratoCartao) => {
        return (
          <Text fontSize="12px" textAlign="start">
            {row.dataInclusao}
          </Text>
        );
      },
    },
    {
      name: (
        <Text fontSize="12px" textAlign="start">
          Situação
        </Text>
      ),
      render: (row: ContratoCartao) => {
        return (
          <Text fontSize="12px" textAlign="start">
            {row.situacao}
          </Text>
        );
      },
    },
    {
      name: (
        <Text fontSize="12px" textAlign="start">
          Limite
        </Text>
      ),
      render: (row: ContratoCartao) => {
        return typeof row.limiteCartao === "number" ? (
          <Text color="whatsapp.400" fontSize="12px" textAlign="start">
            R$ {currencyMask(row.limiteCartao.toFixed(2))}
          </Text>
        ) : null;
      },
    },
    {
      name: (
        <Text fontSize="12px" textAlign="start">
          Valor Reservado
        </Text>
      ),
      render: (row: ContratoCartao) => {
        return typeof row.valorReservado === "number" ? (
          <Text color="whatsapp.400" fontSize="12px" textAlign="start">
            R$ {currencyMask(row.valorReservado.toFixed(2))}
          </Text>
        ) : null;
      },
    },
  ];
}

export function StylesPDF() {
  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
  .table-container {
  position: relative;
  padding-bottom: 30px;
  width: 100%;
  overflow: overlay;
  font-size: 14px;
  scrollbar-color: #bbb transparent;
  scrollbar-width: thin;
}

.dark {
  background: #1c1c1c;
}
.light {
  background: #ffffff;
}
.dark1 {
}
.light1 {
}

.table-container::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.table-container::-webkit-scrollbar-track {
  width: 4px;
}

.table-container::-webkit-scrollbar-thumb {
  background: #bbb;
}

.table-container table {
  width: 100%;
}

.table-header-cell {
  text-transform: none;
  font-size: 14px;
  font-weight: bold;
  font-family: Inter;
  padding: 0.75rem 1.5rem;
}

.header-cell-align {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 26px;
  text-align: center;
}
.table-row {
}
.table-row.dark {
  border-bottom: 1px solid var(--chakra-colors-gray-700);
}
.table-row.light {
  border-bottom: 1px solid var(--chakra-colors-gray-100);
}
.table-row.dark:hover {
  background: rgba(255, 255, 255, 0.05);
}
.table-row.light:hover {
  background: rgba(45, 61, 133, 0.03);
}

.cursor-pointer {
  cursor: pointer;
}

.table-data-cell {
  color: var(--chakra-colors-text);
  padding: 8.4px 24px;
  line-height: var(--chakra-lineHeights-5);
  white-space: nowrap;
}

.loading-container {
  display: flex;
  min-height: 478px;
  justify-content: center;
  align-items: center;
}
.empty-message {
  margin: 15px 0 0 20px;
}

.table-footer-container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 30px 40px 0;
}

.buttons-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.icon-button {
  min-width: 36px;
  height: 36px;
  padding: 0;
  transition: 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-button.light {
  background: #edf2f7;
}
.icon-button.dark {
  background: rgba(255, 255, 255, 0.08);
}
.icon-button.light:hover {
  background: #e2e8f0;
}
.icon-button.dark:hover {
  background: rgba(255, 255, 255, 0.16);
}
.icon-button.light:active {
  background: #cbd5e0;
}
.icon-button.dark:active {
  background: rgba(255, 255, 255, 0.24);
}

.icon-button:disabled {
  opacity: 40%;
  cursor: not-allowed;
}

.outline.light {
  border: 1px solid #e2e8f0;
  background: transparent;
}
.outline.dark {
  border: 1px solid rgba(255, 255, 255, 0.16);
  background: transparent;
}
.outline.light:hover {
  background: #edf2f7;
}
.outline.dark:hover {
  background: rgba(255, 255, 255, 0.08);
}
.outline.light:active {
  background: #e2e8f0;
}
.outline.dark:active {
  background: rgba(255, 255, 255, 0.24);
}

.page-number {
  padding: 4px 5px;
  margin: 0 10px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
}
.page-number:hover {
  text-decoration: underline;
}

.flex {
  display: flex;
}
body {
    font-family: Poppins,sans-serif;
    --chakra-colors-gray-200: #E2E8F0;
}


  `,
        }}
      ></style>

      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossOrigin="anonymous"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,400&display=swap"
        rel="stylesheet"
      />
    </>
  );
}

function Text({
  children,
  className,
  ...rest
}: { children?: any; className?: string } & CSSProperties) {
  return (
    <p className={className} style={{ ...rest }}>
      {children}
    </p>
  );
}
