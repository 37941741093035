import { CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Grid,
  Input,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import api from "api/api";
import {
  dropdownConvenioAtendimento,
  dropdownSimNao,
} from "components/atendimentos-components/atendimento-form/fields-data";
import { getErrorUploadingMailingByMessage } from "components/atendimentos-components/atendimento-form/functions/mapeamentos";
import { CustomModal } from "components/custom-modal";
import { Dropdown } from "components/dropdown";
import { DraggableFileField } from "components/input-file";
import { UploadIcon } from "components/vectors/upload-icon";
import { useEffect, useRef, useState } from "react";
import { useEventListener, makeEvent } from "services/events";
import {
  MailingField,
  dropdownTipoOperacaoMailing,
  compareHeaders,
  uploadMailingFields,
} from "./consts";
import { Toast } from "components/toast";
import { csvToJSON } from "components/consulta-in100-components/utils";
import { Checkbox } from "components/checkbox";

export function MailingUploadModal() {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [digitacaoLote, setDigitacaoLote] = useState(false);
  const [tipoOperacao, setTipoOperacao] = useState("");
  const [convenio, setConvenio] = useState("");

  const [file, setFile] = useState<File | null>(null);
  const [fileError, setFileError] = useState("");
  const [nameError, setNameError] = useState("");
  const [tipoOperacaoError, setTipoOperacaoError] = useState("");
  const [convenioError, setConvenioError] = useState("");
  const [values, setValues] = useState<{ [k: string]: string | string[] }>({});
  const [csvHeaderOptions, setCsvHeaderOptions] = useState<
    { name: string; value: string | undefined }[]
  >([]);
  const isVisibleMessage = [
    "NOVO",
    "REFIN_CARTEIRA",
    "PORT_COM_REFIN_DA_PORT",
  ].includes(tipoOperacao);
  const nameRef = useRef<HTMLInputElement>(null);

  const onCloseModal = () => {
    onClose();
    setLoading(false);
    setFile(null);
    setValues({});
    setCsvHeaderOptions([]);
    setConvenio("");
    setNameError("");
    setFileError("");
    setConvenioError("");
    setTipoOperacao("");
    setTipoOperacaoError("");
    setDigitacaoLote(false);
  };

  const onChangeValues = (field: string, value: any) => {
    setValues((values: any) => ({ ...values, [field]: value }));
  };

  const getDropdownOptions = async (file: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = () => {
        const result = reader.result as string;
        const header = result.split?.("\n")?.[0]?.split?.(";");
        if (header?.length) resolve(header);
        else reject(undefined);
      };
      reader.onerror = function (error) {
        reject(undefined);
      };
    });
  };

  const handleSubmit = async () => {
    const name = nameRef.current!.value;
    if (!name || !file || !convenio || !tipoOperacao) {
      if (!convenio) setConvenioError("Preencha o campo Convênio");
      if (!file) setFileError("Insira um arquivo CSV");
      if (!name) setNameError("Preencha o campo Nome");
      if (!tipoOperacao) setTipoOperacaoError("Preecha o campo Tipo Operação");
      return;
    }

    const formData = new FormData();
    formData.append("convenio", convenio);
    formData.append("files", file!);
    formData.append("filename", file!.name);
    formData.append("name", name);
    formData.append("tipoOperacao", tipoOperacao);
    formData.append("digitacaoLote", `${digitacaoLote}`);
    Object.keys(values).forEach((key) => {
      if (values[key]) formData.append(key, values[key] as string);
    });
    setLoading(true);
    try {
      const response = await api.post("/mailings/manual", formData);
      Toast({ title: "Mailing criado com sucesso" });
      onCloseModal();
      makeEvent("update-mailings-table");
    } catch (error: any) {
      Toast({
        title: getErrorUploadingMailingByMessage(
          error,
          "Erro ao criar mailing"
        ),
        status: "error",
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    Object.keys(values).forEach((key) => {
      let current: undefined | MailingField;
      uploadMailingFields.forEach(({ fields }) => {
        const curr = fields.find(({ field }) => field === key);
        if (curr) current = curr;
      });
      if (!current?.isVisible(convenio))
        setValues((values) => {
          delete values[key];
          return { ...values };
        });
    });
  }, [convenio]);

  useEffect(() => {
    if (file)
      getDropdownOptions(file).then((headers) => {
        const headersColumns = headers as string[];
        const headersOptions = [undefined, ...headersColumns];
        compareHeaders(headersColumns, onChangeValues);
        setCsvHeaderOptions(
          headersOptions.map((column) => ({
            name: column ?? "Selecione",
            value: column,
          }))
        );
      });
    else setCsvHeaderOptions([]);
  }, [file]);

  const handleFile = async (file: File) => {
    if (file) setFileError("");
    // const filteredFile = await csvToJSON(file);
    // console.log(filteredFile);
    setFile(file);
  };

  const handleDelete = () => setFile(null);

  useEventListener("open-mailing-upload", onOpen);

  const modalFooter = (
    <>
      <Button
        isDisabled={!values.colunaCPF}
        onClick={() => {
          handleSubmit();
        }}
        isLoading={loading}
        leftIcon={<UploadIcon />}
      >
        Upload Mailing
      </Button>
      <Button
        onClick={onCloseModal}
        leftIcon={<CloseIcon w="12px" h="12px" />}
        variant="outline"
      >
        Fechar
      </Button>
    </>
  );

  return (
    <CustomModal
      modalTitle="Upload Mailing"
      isOpen={isOpen}
      onClose={onCloseModal}
      modalFooter={modalFooter}
      isLoading={loading}
      size="4xl"
      scroll="inside"
      maxH="90vh"
    >
      <Grid templateColumns="1fr 1fr" gap="15px">
        <Box>
          <Text fontSize="16" mb="5px">
            Convênio
          </Text>
          <Dropdown
            value={convenio}
            w="full"
            isInvalid={convenioError !== ""}
            options={dropdownConvenioAtendimento}
            onChange={(value) => {
              setConvenioError("");
              setConvenio(value);
            }}
          />
        </Box>
        <Box>
          <Text fontSize="16" mb="5px">
            Nome
          </Text>
          <Input
            isInvalid={nameError !== ""}
            _invalid={{
              border: "3px solid var(--chakra-colors-secondary-600)",
            }}
            onChange={() => setNameError("")}
            ref={nameRef}
          />
        </Box>
        <Box>
          <Text mb="8px">Tipo Operação:</Text>
          <Dropdown
            w="100%"
            options={dropdownTipoOperacaoMailing}
            onChange={(value) => {
              setTipoOperacao(value);
              if (value === "REFIN_CARTEIRA") setDigitacaoLote(true);
              else setDigitacaoLote(false);
            }}
            value={tipoOperacao}
            isInvalid={tipoOperacaoError !== ""}
          />
        </Box>
        {tipoOperacao === "REFIN_CARTEIRA" ? (
          <Box>
            <Text mb="8px">&nbsp;</Text>
            <Checkbox
              isChecked={digitacaoLote}
              onChange={(isChecked) => setDigitacaoLote(isChecked)}
            >
              Higienização Off. Auto.
            </Checkbox>
            {/* <Text mb="8px">Digitação Lote:</Text>
          <Dropdown
            w="100%"
            options={dropdownSimNao}
            onChange={setDigitacaoLote}
            value={digitacaoLote}
          /> */}
          </Box>
        ) : null}
        {isVisibleMessage ? (
          <Box gridArea="auto / span 2">
            <Box
              w="100%"
              bg="gray.100"
              border={"1px solid var(--chakra-colors-gray-200)"}
              p="4px"
              borderRadius="4px"
              mb="8px"
            >
              <Text fontSize="12" whiteSpace="pre-wrap">
                <Text as="span" fontWeight="bold">
                  Atenção:
                </Text>
                <br />
                Para essas operações, é necessário ter obrigatoriamente{" "}
                <b>CPF</b> e <b>Benefício/Matrícula</b>.
              </Text>
            </Box>
            <Box
              w="100%"
              bg="gray.100"
              border={"1px solid var(--chakra-colors-gray-200)"}
              p="4px"
              borderRadius="4px"
              mb="8px"
            >
              <Text fontSize="12" whiteSpace="pre-wrap">
                <Text as="span" fontWeight="bold">
                  Atenção:
                </Text>
                <br />
                Para a digitação em lote do banco <b>iCred</b>, é necessário ter
                obrigatoriamente <b>CPF</b> e <b>Telefone</b>.
              </Text>
            </Box>
          </Box>
        ) : null}
      </Grid>
      <Box mb="15px">
        <DraggableFileField
          handleFile={handleFile}
          handleDelete={handleDelete}
          error={fileError}
          fieldTitle="Selecionar CSV:"
          file={file}
          dragFieldStyle={{ p: file ? "0" : "40px 20px" }}
        />
      </Box>

      {csvHeaderOptions.length ? (
        <>
          {uploadMailingFields.map((fieldsGroup, index) => {
            const { fields, title } = fieldsGroup;
            const isLast = index + 1 === uploadMailingFields.length;
            return (
              <>
                <Box mb={isLast ? undefined : "20px"}>
                  <Text fontSize="18" mb="15px">
                    {title}
                  </Text>
                  <hr style={{ margin: "15px 0" }} />
                  <Grid templateColumns="1fr 1fr 1fr" gap="15px">
                    {fields.map(({ field, isVisible, name, type }) => {
                      if (isVisible(convenio)) {
                        if (type === "multi-select")
                          return (
                            <Flex key={field} flexDir="column">
                              <Text mb="5px">{name}</Text>
                              <Dropdown
                                value={values[field] || []}
                                onChange={(value) => {
                                  if (values[field]?.length) {
                                    const isInclude =
                                      values[field].includes(value);
                                    if (isInclude)
                                      onChangeValues(
                                        field,
                                        (values[field] as string[]).filter(
                                          (curr: any) => curr !== value
                                        )
                                      );
                                    else
                                      onChangeValues(field, [
                                        ...values[field],
                                        value,
                                      ]);
                                  } else onChangeValues(field, [value]);
                                }}
                                options={csvHeaderOptions}
                                w="full"
                                multiSelect={true}
                              >
                                {(values[field] || [])?.length > 1
                                  ? `${
                                      (values[field] || [])?.length
                                    } - Selecionados`
                                  : ((values[field] as string[]) || []).join(
                                      ","
                                    )}
                              </Dropdown>
                            </Flex>
                          );
                        else
                          return (
                            <Flex key={field} flexDir="column">
                              <Text mb="5px">{name}</Text>
                              <Dropdown
                                w="100%"
                                options={csvHeaderOptions}
                                value={values[field]}
                                onChange={(value) =>
                                  onChangeValues(field, value)
                                }
                              />
                            </Flex>
                          );
                      } else return null;
                    })}
                  </Grid>
                </Box>
              </>
            );
          })}
        </>
      ) : null}
    </CustomModal>
  );
}
