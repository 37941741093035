import { Box, Button, Center, Flex, Grid, Text } from "@chakra-ui/react";
import { useApplicationContext } from "contexts/ApplicationContext";
import { useAttendanceContext } from "contexts/attendance-context";
import { Toast } from "components/toast";
import { useEffect, useState } from "react";
import AttendanceInput from "../../../attendance-field/attendance-input";
import { CustomTableColor } from "components/dynamic-table/color-table";
import {
  dropdownBancosSimulacaoFGTS,
  getTabelaOptions,
  simulacoesColumns,
} from "./utils";
import { RepeatIcon } from "@chakra-ui/icons";

import { WithInvalidBox } from "components/invalid-box";
import { isColomboUser } from "utils/is-colombo-user";
import {
  ModalDigitarPropostaFGTS,
  openModalDigitarProposta,
} from "../digitacao/modal-digitar-proposta";
import { KeyboardIcon } from "components/vectors/keyboard-icon";
import { DropdownField } from "components/dropdown-field";
import apiDigitacao from "api/api-digitacao";
import { consultarFGTS, reCalcularFGTS } from "./utils";
import { AttendanceTabKey } from "components/atendimentos-components/pagina-atendimento/attendance-data-form";

export interface TabelaDigitacaoProps {
  valorLiberado: number;
  banco: string;
  codTable: string;
  observacao: string;
  statusSaldo?: "FINISHED" | "PENDING";
  statusRecalculo?: "FINISHED" | "PENDING";
  parcelas: {
    id: number;
    checked: boolean;
    data: string;
    valorDescontado: number;
    sequence: number;
  }[];
}

export function SimulacoesAtendimentoFormPart({
  onChangeTab,
  telaAtendimento,
}: {
  onChangeTab: (tabKey: AttendanceTabKey) => void;
  telaAtendimento: boolean;
}) {
  let [tabelaDigitacao, setTabelaDigitacao] = useState<
    Partial<TabelaDigitacaoProps>
  >({});
  const [errors, setErrors] = useState<{ [k: string]: string }>({});
  const [dropdownTabela, setDropdownTabela] = useState<
    { name: string; value: string }[]
  >([]);
  const [loadingTabela, setLoadingTabela] = useState(false);
  const {
    formControl,
    dispatch,
    setReConsultandoFGTS,
    reConsultandoFGTS,
    consultandoFGTS,
    setConsultandoFGTS,
  } = useAttendanceContext();
  const { user } = useApplicationContext();
  const formValues = formControl.values;

  const isShowRecalular =
    tabelaDigitacao?.banco === "PAN" || tabelaDigitacao.banco === "FACTA";

  const changeErrorField = (k: string, v?: string) =>
    setErrors((errors) => {
      if (!v) delete errors[k];
      else errors[k] = v;
      return { ...errors };
    });

  const onChangeTabelaDigitacao = (
    key: keyof TabelaDigitacaoProps,
    value: any
  ) => {
    tabelaDigitacao = { ...tabelaDigitacao, [key]: value };
    changeErrorField(key);
    if (key === "banco") {
      tabelaDigitacao = {
        ...tabelaDigitacao,
        codTable: undefined,
        parcelas: [],
        valorLiberado: undefined,
        observacao: "",
      };
      changeErrorField("codTable");
      changeErrorField("parcelas");
      changeErrorField("valorLiberado");

      getTabelaOptions({
        setDropdownTabela,
        setLoadingTabela,
        bancoDigitacaoFGTSAPI: value,
        dispatch,
      });
    } else if (key === "codTable") {
      tabelaDigitacao = {
        ...tabelaDigitacao,
        parcelas: [],
        valorLiberado: undefined,
      };
      changeErrorField("parcelas");
      changeErrorField("valorLiberado");
    }
    setTabelaDigitacao(tabelaDigitacao);
  };

  const getConsultaData = async () => {
    setLoadingTabela(true);
    try {
      const { data }: { data: TabelaDigitacaoProps } = await apiDigitacao.get(
        `/api/simulacoes/fgts?atendimento_id=${formValues.id}`
      );
      tabelaDigitacao = data;
      if (data.statusSaldo === "PENDING") {
        consultarFGTS({
          formControl,
          loading: setConsultandoFGTS,
          tabelaDigitacao,
          setTabelaDigitacao,
          dispatch,
          queryAlreadyCreated: true,
        });
      } else if (data.statusRecalculo === "PENDING") {
        reCalcularFGTS({
          dispatch,
          formControl,
          loading: setReConsultandoFGTS,
          tabelaDigitacao,
          setTabelaDigitacao,
          queryAlreadyCreated: true,
        });
      }
      setTabelaDigitacao(tabelaDigitacao);
    } catch (e) {
      Toast({ title: "Erro ao obter tabelas", status: "error" });
    } finally {
      setLoadingTabela(false);
    }
  };

  useEffect(() => {
    getConsultaData();
  }, []);

  useEffect(() => {
    if (tabelaDigitacao.banco)
      getTabelaOptions({
        setDropdownTabela,
        setLoadingTabela,
        bancoDigitacaoFGTSAPI: tabelaDigitacao.banco,
        dispatch,
      });
  }, [tabelaDigitacao.banco]);

  return (
    <>
      <Grid
        templateColumns={telaAtendimento ? "1fr 1fr" : "1fr"}
        columnGap="20px"
        rowGap="40px"
        mb="15px"
      >
        <Box>
          <Box mb="15px">
            <DropdownField
              title="Banco"
              onChange={(value) => {
                onChangeTabelaDigitacao("banco", value);
              }}
              value={tabelaDigitacao.banco}
              options={dropdownBancosSimulacaoFGTS}
              dropdownProps={{
                variant: "outline-custom",
                w: "full",
                isLoading: loadingTabela,
                loadingText: "Carregando",
                isDisabled: consultandoFGTS || reConsultandoFGTS,
              }}
              errorMessage={errors["banco"]}
            />
          </Box>
          <Text mb="8px" fontSize="15px" color="#4C545F" fontWeight="medium">
            Tabelas
          </Text>
          <WithInvalidBox
            display="flex"
            flexDir="column"
            maxH="160px"
            w="100%"
            overflow="auto"
            borderRadius="5px"
            errorMessage={errors["codTable"]}
          >
            {dropdownTabela.length === 0 ? "Não há tabelas" : null}
            {dropdownTabela.map(({ name, value }, i) => {
              const isActive = tabelaDigitacao?.codTable === value;
              return (
                <Flex
                  w="100%"
                  key={`${value}-${i}`}
                  border={"1px solid #E8EAED"}
                  alignItems="center"
                  px="16px"
                  // h="42px"
                  py="4px"
                  minH="42px"
                  borderRadius="5px"
                  mb="10px"
                  cursor="pointer"
                  _hover={{ bg: "gray.50" }}
                  opacity={
                    loadingTabela || consultandoFGTS || reConsultandoFGTS
                      ? 0.6
                      : 1
                  }
                  pointerEvents={
                    loadingTabela || consultandoFGTS || reConsultandoFGTS
                      ? "none"
                      : undefined
                  }
                  onClick={() => {
                    onChangeTabelaDigitacao("codTable", value);
                  }}
                >
                  <Center
                    borderRadius="full"
                    border="1px solid #141B3B"
                    w="12px"
                    minW="12px"
                    h="12px"
                    mr="9px"
                  >
                    <Box
                      bg={isActive ? "#141B3B" : ""}
                      borderRadius="full"
                      h="8px"
                      w="8px"
                    />
                  </Center>
                  <Text fontSize="15px" color="#4C545F">
                    {name}
                  </Text>
                </Flex>
              );
            })}
          </WithInvalidBox>
        </Box>
        <Flex
          flexDir="column"
          justifyContent="center"
          bg="#F5F7F9"
          borderRadius="5px"
          p="20px"
        >
          <Flex mb="20px">
            <Box mr="8px" />
            <Text fontWeight="bold">Valor liberado</Text>
          </Flex>
          <Flex
            justifyContent="space-between"
            px="16px"
            py="14px"
            borderRadius="5px"
            bg="#fff"
            mb="25px"
          >
            <Text fontSize="15px" mr="auto"></Text>
            <Text fontWeight="bold" fontSize="15px">
              R${" "}
              {tabelaDigitacao.valorLiberado
                ? tabelaDigitacao.valorLiberado
                : "--"}
            </Text>
          </Flex>
          <Grid templateColumns="1fr 1fr" gap="10px">
            <Button
              w="100%"
              gridArea={isShowRecalular ? undefined : `auto / span 2`}
              isLoading={consultandoFGTS}
              loadingText="Consultando"
              isDisabled={reConsultandoFGTS || !tabelaDigitacao.codTable}
              onClick={() => {
                consultarFGTS({
                  formControl,
                  loading: setConsultandoFGTS,
                  tabelaDigitacao,
                  setTabelaDigitacao,
                  dispatch,
                });
              }}
            >
              Consultar
            </Button>
            {isShowRecalular ? (
              <Button
                w="100%"
                leftIcon={<RepeatIcon />}
                isLoading={reConsultandoFGTS}
                loadingText="Recalculando"
                isDisabled={
                  dropdownTabela.length === 0 ||
                  consultandoFGTS ||
                  !tabelaDigitacao.codTable ||
                  !tabelaDigitacao.parcelas?.length ||
                  !tabelaDigitacao.banco
                }
                onClick={() => {
                  reCalcularFGTS({
                    dispatch,
                    formControl,
                    loading: setReConsultandoFGTS,
                    tabelaDigitacao,
                    setTabelaDigitacao,
                  });
                }}
              >
                Recalcular
              </Button>
            ) : null}
            {!isColomboUser(user) &&
            ["FACTA", "VCTEX", "ITAU", "LOTUS_TAYA", "TA_QUITADO"].includes(
              tabelaDigitacao.banco!
            ) ? (
              <Button
                gridArea="auto / span 2"
                w="100%"
                leftIcon={<KeyboardIcon />}
                onClick={() => {
                  if (tabelaDigitacao.banco) {
                    openModalDigitarProposta({
                      bancoDigitacao: tabelaDigitacao.banco,
                    });
                  } else {
                    changeErrorField("banco", "Preencha o campo");
                  }
                }}
                isDisabled={
                  consultandoFGTS || reConsultandoFGTS || loadingTabela
                }
              >
                Digitar Proposta
              </Button>
            ) : null}
          </Grid>
        </Flex>
        <Box gridArea={telaAtendimento ? "auto / span 2" : ""}>
          <AttendanceInput
            title="Observação"
            value={tabelaDigitacao.observacao}
            inputProps={{
              color: "red",
              as: "textarea",
              height: "60px",
              minH: "60px",
              resize: "none",
            }}
          />
        </Box>
      </Grid>
      <CustomTableColor
        columns={simulacoesColumns({
          formValues,
          parcelas: tabelaDigitacao.parcelas!,
          setTabelaDigitacao,
        })}
        rows={tabelaDigitacao.parcelas ?? []}
        headerStyle={{ whiteSpace: "normal", fontSize: "12px" }}
        containerProps={
          telaAtendimento
            ? undefined
            : { overflowY: "visible", overflowX: "visible" }
        }
      />

      <ModalDigitarPropostaFGTS />

      {/* <Accordion title="Desconto anual FGTS" containerProps={{ mb: "20px" }}>
        <CustomTableColor
          columns={simulacoesColumns({ dispatch, formValues })}
          rows={simulacaoAntecipacaoFactaFGTS}
          headerStyle={{ whiteSpace: "normal", fontSize: "12px" }}
        />
      </Accordion> */}
    </>
  );
}
