import { Button, Center, Flex, Grid, Text } from "@chakra-ui/react";
import { CustomModal } from "components/custom-modal";
import { useEffect, useState } from "react";
import { makeEvent, useEventListener } from "services/events";
import {
  DigitacaoINSSProps,
  TabelaProps,
  UsuarioBanco,
} from "../simulacao-fields/types";
import { CloseIcon, CopyIcon, Search2Icon } from "@chakra-ui/icons";
import { useAttendanceContext } from "contexts/attendance-context";
import { Attendance } from "components/atendimentos-components/atendimento-form/types";
import { InputField } from "components/input-field";
import {
  currencyMaskFormat,
  maskCEPFormat,
  maskCPFFormat,
} from "utils/string-formats";
import { DropdownField } from "components/dropdown-field";
import {
  CEPProps,
  dropdownOrgaosEmissoresDocDigitacaoFGTSFacta,
  getCEPData,
  maskDateFormat,
  UFsBrasil,
} from "components/atendimentos-components/atendimento-form/step-form/tabs-components/digitacao-fields/parts/const";
import { ButtonWithLoading } from "components/mailing-components/mailing-table/icon-button-with-loading";
import { Toast } from "components/toast";
import { factaCitiesArray } from "components/atendimentos-components/atendimento-form/step-form/tabs-components/digitacao-fields/parts/cities-array";
import { normalizeText } from "utils/filter-array-by";
import { dropdownEspecies } from "components/atendimentos-components/atendimento-form/fields-data";
import {
  bankTypeOptions,
  copyAttendanceDataToDigitacaoINSS,
  phoneSeparate,
  validateDigitacaoINSS,
} from "../simulacao-fields/utils";
import { SimulacaoResultProps } from "..";
import apiDigitacao from "api/api-digitacao";
import { ModalSimulacaoApi } from "../simulacao-fields/requests";
import { AttendanceTabKey } from "components/atendimentos-components/pagina-atendimento/attendance-data-form";
import { DigitadoRow } from "../../digitacao/digitados-part";
import { isColomboUser } from "utils/is-colombo-user";
import { useApplicationContext } from "contexts/ApplicationContext";
import { organCodeOptions } from "./utils";

interface ModalDigitacaoParams {
  simulacao: SimulacaoResultProps;
  digitacaoFields?: DigitacaoINSSProps;
  modalConfig?: ModalConfigProps;
}

interface ModalConfigProps {
  allDisabled: boolean;
  idDigitacao?: number;
  onUpdate?: (row: DigitadoRow) => void;
}

export function ModalDigitacaoINSS({
  onChangeTab,
}: {
  onChangeTab?: (tabKey: AttendanceTabKey) => void;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [simulacao, setSimulacao] = useState<SimulacaoResultProps>({});
  const [digitacaoFields, setDigitacaoFields] = useState<DigitacaoINSSProps>(
    {}
  );
  const [modalConfig, setModalConfig] = useState<ModalConfigProps>({
    allDisabled: false,
  });
  const [errors, setErrors] = useState<{ [k: string]: string }>({});
  const [tabelasList, setTabelasList] = useState<
    { name: string; value: string; completedTable: TabelaProps }[]
  >([]);
  const [usuariosBancos, setUsuariosBancos] = useState<
    { name: string; value: string; completedUser: UsuarioBanco }[]
  >([]);
  const [loadingTabelas, setLoadingTabelas] = useState(false);
  const [loadingUsuarios, setLoadingUsuarios] = useState(false);
  const { formControl, dispatch } = useAttendanceContext();
  const attendance = formControl.values as Attendance;
  const modalApi = ModalSimulacaoApi();
  const { allDisabled, idDigitacao } = modalConfig;
  const isRedigitar =
    !!idDigitacao && !allDisabled && ["C6", "FACTA"].includes(simulacao.banco!);

  const { user } = useApplicationContext();

  const isVisibleUsuarioSelect =
    ["C6", "FACTA", "BMG", "PAN", "PRESENCA"].includes(simulacao.banco || "") &&
    !isColomboUser(user);

  const onOpen = (props: ModalDigitacaoParams) => {
    setIsOpen(true);
    setSimulacao(props.simulacao);
    const idDigitacao = props.modalConfig?.idDigitacao;
    if (props.modalConfig) setModalConfig(props.modalConfig);
    if (props.modalConfig?.idDigitacao) loadDigitacaoData(idDigitacao!);
  };
  const onClose = () => {
    setIsOpen(false);
    setErrors({});
    setSimulacao({});
    setDigitacaoFields({});
  };

  const loadDigitacaoData = async (idDigitacao: number) => {
    const url = `/api/digitacoes/inss/formulario?digitacao_id=${idDigitacao}`;
    setIsLoading(true);
    try {
      const { data } = await apiDigitacao.get(url);
      setDigitacaoFields(data);
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  const onChangeDigitacao = ({
    key,
    value,
  }: {
    key: keyof DigitacaoINSSProps | (keyof DigitacaoINSSProps)[];
    value: any;
  }) => {
    if (Array.isArray(key)) {
      key.forEach((k) => {
        setDigitacaoFields((prevFields) => ({ ...prevFields, [k]: value }));
        setErrors((errors) => {
          delete errors[k];
          return { ...errors };
        });
      });
    } else {
      setDigitacaoFields((prevFields) => ({ ...prevFields, [key]: value }));
      setErrors((errors) => {
        delete errors[key];
        return { ...errors };
      });
    }
  };

  const loadCEPData = async (cep: string) => {
    const { error, errorMessage, ...cpfData } = await getCEPData(cep);
    if (error) {
      Toast({ title: errorMessage, status: "error" });
    } else {
      setCpfData(cpfData as CEPProps);
    }
  };

  const setCpfData = (cep: CEPProps) => {
    onChangeDigitacao({ key: "addressStreet1", value: cep.logradouro });
    onChangeDigitacao({ key: "addressNeigh1", value: cep.bairro });

    const possiveisCidades = factaCitiesArray.filter((crr) => {
      return (
        crr.value.uf === cep.uf &&
        normalizeText(crr.value.cityName).includes(
          normalizeText(cep.localidade!)
        )
      );
    });
    if (possiveisCidades.length === 1) {
      onChangeDigitacao({
        key: "addressCity1",
        value: possiveisCidades[0].value.cityName,
      });
      onChangeDigitacao({
        key: "addressStateCode1",
        value: possiveisCidades[0].value.uf,
      });
    }
  };

  const handleDigitar = async () => {
    // if (simulacao.status !== "FINISHED") {
    //   return Toast({ title: "Faça uma Simulação primeiro" });
    // }
    if (formControl.pendingChanges) {
      return Toast({ title: "Salve as alterações do atendimento" });
    }
    const data = { ...digitacaoFields, banco: simulacao.banco };
    const { isValid, errors, body } = validateDigitacaoINSS(
      data,
      dispatch,
      isColomboUser(user)
    );

    console.log("Errors =>", errors);
    console.log("Body =>", body);

    if (!isValid) {
      setErrors(errors);
      Toast({ title: "Revise os campos", status: "error" });
      return;
    }
    setIsLoading(true);
    try {
      const { data } = await apiDigitacao.post(
        `api/digitacoes/inss/atendimento/${attendance.id}/digitar`,
        body
      );
      onClose();
      Toast({
        title:
          simulacao.banco === "BMG"
            ? "Digitação em andamento"
            : "Digitação concluída",
        description: `Veja resultados na aba "Digitados"`,
        status: "success",
        duration: 6000,
      });
      onChangeTab?.("digitados");
    } catch (e: any) {
      const errorMessage =
        e?.response?.data?.msgInterface || e?.response?.data?.message;
      Toast({
        title: "Erro ao digitar",
        description: errorMessage,
        status: "error",
        duration: 60000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleRedigitar = async () => {
    if (formControl.pendingChanges) {
      return Toast({ title: "Salve as alterações do atendimento" });
    }
    const data = { ...digitacaoFields, banco: simulacao.banco };
    const { isValid, errors, body } = validateDigitacaoINSS(
      data,
      dispatch,
      isColomboUser(user)
    );
    console.log("Errors =>", errors);
    console.log("Body =>", body);

    if (!isValid) {
      setErrors(errors);
      Toast({ title: "Revise os campos", status: "error" });
      return;
    }
    setIsLoading(true);
    try {
      const { data } = await apiDigitacao.post(
        `api/digitacoes/inss/atendimento-digitacao/${idDigitacao}/redigitar`,
        body
      );
      modalConfig?.onUpdate?.(data);
      onClose();
    } catch (e: any) {
      const errorMessage =
        e?.response?.data?.msgInterface || e?.response?.data?.message;
      Toast({
        title: "Erro ao redigitar",
        description: errorMessage,
        status: "error",
        duration: 60000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (simulacao.banco && !isColomboUser(user) && !allDisabled) {
      if (isVisibleUsuarioSelect) {
        modalApi.getUsuariosBancos(
          simulacao.banco,
          setUsuariosBancos,
          setLoadingUsuarios
        );
      }

      modalApi.getTabelasDigitacao(
        simulacao.banco,
        attendance.convenio!,
        setTabelasList,
        setLoadingTabelas
      );
    }
  }, [simulacao.banco]);

  useEffect(() => {
    if (
      isColomboUser(user) &&
      simulacao.banco &&
      simulacao.simulacao?.term &&
      !allDisabled
    ) {
      modalApi.getTabelasDigitacaoColombo(
        attendance.convenio,
        simulacao.banco,
        simulacao.simulacao?.term,
        setTabelasList,
        setLoadingTabelas
      );
    }
  }, [simulacao.banco, simulacao.simulacao?.term]);

  useEventListener("openModalDigitacaoINSS", onOpen);

  const modalFooter = (
    <>
      {isVisibleUsuarioSelect ? (
        <Center>
          <Text mr="12px">Usuário digitador banco: </Text>
          <DropdownField
            onChange={(value, option) => {
              const usuarioBanco: UsuarioBanco = option.completedUser;

              onChangeDigitacao({
                key: "userConsig",
                value: usuarioBanco.username,
              });

              if (simulacao.banco === "PAN") {
                onChangeDigitacao({
                  key: "sellerCpf",
                  value: usuarioBanco.cpfUsuarioCertificado,
                });
              }

              onChangeDigitacao({
                key: "passwordConsig",
                value: usuarioBanco.password,
              });
            }}
            dropdownProps={{
              w: "300px",
              children: allDisabled
                ? digitacaoFields.userConsig ??
                  digitacaoFields.loginConsig ??
                  digitacaoFields.sellerLogin ??
                  undefined
                : undefined,
            }}
            value={
              digitacaoFields.userConsig || digitacaoFields.loginConsig || ""
            }
            options={usuariosBancos || []}
            errorMessage={
              errors["userConsig"] ||
              errors["passwordConsig"] ||
              errors["sellerCpf"]
            }
            isDisabled={isLoading || allDisabled}
            isLoading={loadingUsuarios}
          />
        </Center>
      ) : null}

      <Button
        isDisabled={allDisabled}
        onClick={() => (isRedigitar ? handleRedigitar() : handleDigitar())}
        isLoading={isLoading}
        ml="10px"
      >
        {isRedigitar ? "Redigitar" : "Digitar"}
      </Button>

      <Button
        variant="outline"
        onClick={onClose}
        leftIcon={<CloseIcon w="12px" h="12px" />}
      >
        {allDisabled ? "Fechar" : "Cancelar"}
      </Button>
    </>
  );

  return (
    <CustomModal
      modalTitle="Digitação INSS"
      isOpen={isOpen}
      onClose={onClose}
      modalFooter={modalFooter}
      size="6xl"
      scroll="inside"
      isLoading={isLoading}
    >
      <Grid
        templateColumns="1fr"
        gap="16px"
        border="1px solid var(--chakra-colors-custom-gray)"
        bg="var(--chakra-colors-gray-100)"
        p="20px 10px 10px 10px"
        borderRadius="4px"
      >
        <Flex pos="relative" mb="10px">
          <Center w="100%">
            <Text fontSize="20px" fontWeight="medium">
              Formulário {simulacao.banco}
            </Text>
          </Center>
          <Button
            pos="absolute"
            right="0px"
            top="-5px"
            w="261px"
            leftIcon={<CopyIcon />}
            size="sm"
            onClick={() => {
              setDigitacaoFields({
                ...digitacaoFields,
                ...copyAttendanceDataToDigitacaoINSS(
                  simulacao,
                  attendance,
                  digitacaoFields
                ),
              });
            }}
            isDisabled={allDisabled}
          >
            Copiar dados do atendimento
          </Button>
        </Flex>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="CPF"
            onChange={(e) =>
              onChangeDigitacao({
                key: "taxIdentifier",
                value: maskCPFFormat(e.target.value).raw,
              })
            }
            inputProps={{ bg: "#fff" }}
            value={
              maskCPFFormat(
                digitacaoFields["taxIdentifier"] || digitacaoFields["cpf"]
              ).value || ""
            }
            errorMessage={errors["taxIdentifier"]}
          />
        </Grid>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="Nome"
            onChange={(e) =>
              onChangeDigitacao({ key: "name", value: e.target.value })
            }
            inputProps={{ bg: "#fff" }}
            value={digitacaoFields["name"] || ""}
            errorMessage={errors["name"]}
          />
        </Grid>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <DropdownField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            dropdownProps={{ w: "100%", bg: "#fff" }}
            title="Sexo"
            onChange={(value) =>
              onChangeDigitacao({ key: "gender", value: value })
            }
            value={digitacaoFields["gender"] || ""}
            errorMessage={errors["gender"]}
            options={[
              { name: "Feminino", value: "F" },
              { name: "Masculino", value: "M" },
            ]}
          />
        </Grid>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="Data Nascimento"
            onChange={(e) =>
              onChangeDigitacao({
                key: "birthDay",
                value: maskDateFormat(e.target.value).raw,
              })
            }
            inputProps={{ bg: "#fff" }}
            value={maskDateFormat(digitacaoFields["birthDay"]).value || ""}
            errorMessage={errors["birthDay"]}
          />
        </Grid>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="RG"
            onChange={(e) => {
              onChangeDigitacao({
                key: "documentNumber",
                value: e.target.value,
              });
            }}
            inputProps={{ bg: "#fff" }}
            value={digitacaoFields["documentNumber"] || ""}
            errorMessage={errors["documentNumber"]}
          />
        </Grid>

        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <DropdownField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            dropdownProps={{ w: "100%", bg: "#fff" }}
            title="UF Emissão Documento"
            onChange={(value) =>
              onChangeDigitacao({ key: "documentEmissionState", value: value })
            }
            value={digitacaoFields["documentEmissionState"] || ""}
            errorMessage={errors["documentEmissionState"]}
            options={UFsBrasil}
          />
        </Grid>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <DropdownField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            dropdownProps={{ w: "100%", bg: "#fff" }}
            title="Orgão Emissor Documento"
            onChange={(value) =>
              onChangeDigitacao({ key: "documentIssuer", value: value })
            }
            value={digitacaoFields["documentIssuer"] || ""}
            errorMessage={errors["documentIssuer"]}
            options={dropdownOrgaosEmissoresDocDigitacaoFGTSFacta}
          />
        </Grid>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="Data expedição doc."
            onChange={(e) =>
              onChangeDigitacao({
                key: "documentDate",
                value: maskDateFormat(e.target.value).raw,
              })
            }
            inputProps={{ bg: "#fff" }}
            value={maskDateFormat(digitacaoFields["documentDate"]).value || ""}
            errorMessage={errors["documentDate"]}
          />
        </Grid>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <DropdownField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            dropdownProps={{ w: "100%", bg: "#fff" }}
            title="Estado Nascimento"
            onChange={(value) =>
              onChangeDigitacao({ key: "birthState", value: value })
            }
            value={digitacaoFields["birthState"] || ""}
            errorMessage={errors["birthState"]}
            options={UFsBrasil}
          />
        </Grid>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="Cidade Nascimento"
            onChange={(e) =>
              onChangeDigitacao({ key: "birthCity", value: e.target.value })
            }
            inputProps={{ bg: "#fff" }}
            value={digitacaoFields["birthCity"] || ""}
            errorMessage={errors["birthCity"]}
          />
        </Grid>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="Telefone"
            onChange={(e) => {
              const { ddd, number } = phoneSeparate({
                completedNumber: e.target.value,
              });
              onChangeDigitacao({ key: "phoneNumber1", value: number });
              onChangeDigitacao({ key: "phoneDDD1", value: ddd });
            }}
            inputProps={{ bg: "#fff" }}
            value={
              phoneSeparate({
                dddPhone: digitacaoFields["phoneDDD1"],
                phone: digitacaoFields["phoneNumber1"],
              }).phoneAndDDDFormatedValue || ""
            }
            errorMessage={errors["phoneDDD1"]}
          />
        </Grid>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="Valor Salário"
            onChange={(e) => {
              onChangeDigitacao({
                key: "incomeValue",
                value: currencyMaskFormat(e.target.value).raw,
              });
            }}
            inputProps={{ bg: "#fff" }}
            value={
              currencyMaskFormat(digitacaoFields["incomeValue"]).value || ""
            }
            errorMessage={errors["incomeValue"]}
          />
        </Grid>
        <Grid templateColumns="220px auto 140px" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="CEP"
            onChange={(e) =>
              onChangeDigitacao({
                key: "addressZipCode1",
                value: maskCEPFormat(e.target.value).raw,
              })
            }
            inputProps={{
              bg: "#fff",
              onKeyDown: (e) => {
                if (e.key === "Enter") {
                  loadCEPData(e.currentTarget.value.replace(/\D/g, ""));
                }
              },
            }}
            value={
              maskCEPFormat(digitacaoFields["addressZipCode1"]).value || ""
            }
            errorMessage={errors["addressZipCode1"]}
          />
          <ButtonWithLoading
            isDisabled={
              (digitacaoFields["addressZipCode1"]?.length ?? 0) < 8 ||
              allDisabled
            }
            onClick={async () =>
              loadCEPData(digitacaoFields["addressZipCode1"]!)
            }
            rightIcon={<Search2Icon />}
          >
            Buscar CEP
          </ButtonWithLoading>
        </Grid>

        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="Estado"
            onChange={(e) =>
              onChangeDigitacao({
                key: "addressStateCode1",
                value: e.target.value,
              })
            }
            inputProps={{ bg: "#fff" }}
            value={digitacaoFields["addressStateCode1"] || ""}
            errorMessage={errors["addressStateCode1"]}
          />
        </Grid>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="Cidade"
            onChange={(e) =>
              onChangeDigitacao({ key: "addressCity1", value: e.target.value })
            }
            inputProps={{ bg: "#fff" }}
            value={digitacaoFields["addressCity1"] || ""}
            errorMessage={errors["addressCity1"]}
          />
        </Grid>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="Bairro"
            onChange={(e) =>
              onChangeDigitacao({ key: "addressNeigh1", value: e.target.value })
            }
            inputProps={{ bg: "#fff" }}
            value={digitacaoFields["addressNeigh1"] || ""}
            errorMessage={errors["addressNeigh1"]}
          />
        </Grid>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="Endereço"
            onChange={(e) =>
              onChangeDigitacao({
                key: "addressStreet1",
                value: e.target.value,
              })
            }
            inputProps={{ bg: "#fff" }}
            value={digitacaoFields["addressStreet1"] || ""}
            errorMessage={errors["addressStreet1"]}
          />
        </Grid>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="Número"
            onChange={(e) =>
              onChangeDigitacao({
                key: "addressNumber1",
                value: e.target.value,
              })
            }
            inputProps={{ bg: "#fff" }}
            value={digitacaoFields["addressNumber1"] || ""}
            errorMessage={errors["addressNumber1"]}
          />
        </Grid>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="Nome Mãe"
            onChange={(e) =>
              onChangeDigitacao({ key: "mothersName", value: e.target.value })
            }
            inputProps={{ bg: "#fff" }}
            value={digitacaoFields["mothersName"] || ""}
            errorMessage={errors["mothersName"]}
          />
        </Grid>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="Banco"
            onChange={(e) => {
              onChangeDigitacao({
                key: "bankNumber",
                value: e.target.value.replace(/\D/g, ""),
              });
            }}
            inputProps={{
              bg: "#fff",
              placeholder: "Código banco",
              _placeholder: { opacity: 0.5 },
            }}
            value={digitacaoFields["bankNumber"] || ""}
            errorMessage={errors["bankNumber"]}
          />
        </Grid>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <DropdownField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            dropdownProps={{
              w: "100%",
              bg: "#fff",
              ...(allDisabled
                ? {
                    children:
                      bankTypeOptions.find(
                        (crr) => crr.value === digitacaoFields["bankType"]
                      )?.name ?? digitacaoFields["bankType"],
                  }
                : {}),
            }}
            title="Tipo Conta"
            onChange={(value) =>
              onChangeDigitacao({ key: "bankType", value: value })
            }
            value={digitacaoFields["bankType"] || ""}
            errorMessage={errors["bankType"]}
            options={bankTypeOptions}
          />
        </Grid>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="Agência"
            onChange={(e) =>
              onChangeDigitacao({
                key: "bankAgency",
                value: e.target.value.replace(/\D/g, ""),
              })
            }
            inputProps={{ bg: "#fff" }}
            value={digitacaoFields["bankAgency"] || ""}
            errorMessage={errors["bankAgency"]}
          />
        </Grid>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="Conta"
            onChange={(e) =>
              onChangeDigitacao({
                key: "bankAccountNumber",
                value: e.target.value.replace(/\D/g, ""),
              })
            }
            inputProps={{ bg: "#fff" }}
            value={digitacaoFields["bankAccountNumber"] || ""}
            errorMessage={errors["bankAccountNumber"]}
          />
        </Grid>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="Número Benefício"
            onChange={(e) =>
              onChangeDigitacao({
                key: "benefitNumber",
                value: e.target.value.replace(/\D/g, ""),
              })
            }
            inputProps={{ bg: "#fff" }}
            value={digitacaoFields["benefitNumber"] || ""}
            errorMessage={errors["benefitNumber"]}
          />
        </Grid>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <DropdownField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            dropdownProps={{ w: "100%", bg: "#fff" }}
            title="Espécie Benefício"
            onChange={(value) =>
              onChangeDigitacao({ key: "specie", value: value })
            }
            value={
              Number(
                digitacaoFields["specie"] || digitacaoFields["speciesNb"]
              ) || ""
            }
            errorMessage={errors["specie"]}
            options={dropdownEspecies}
          />
        </Grid>
        {simulacao.banco === "PAN" ? (
          <Grid templateColumns="220px auto" alignItems="center" gap="20px">
            <DropdownField
              isDisabled={allDisabled}
              titleProps={{ textAlign: "end", mb: "0px" }}
              dropdownProps={{ w: "100%", bg: "#fff" }}
              title="Orgão"
              onChange={(value) =>
                onChangeDigitacao({ key: "organCode", value })
              }
              value={digitacaoFields["organCode"] || ""}
              errorMessage={errors["organCode"]}
              options={organCodeOptions}
            />
          </Grid>
        ) : null}
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <DropdownField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            dropdownProps={{ w: "100%", bg: "#fff" }}
            title="Estado Benefício"
            onChange={(value) =>
              onChangeDigitacao({ key: "benefitState", value: value })
            }
            value={digitacaoFields["benefitState"] || ""}
            errorMessage={errors["benefitState"]}
            options={UFsBrasil}
          />
        </Grid>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <DropdownField
            title="Tabelas"
            titleProps={{ textAlign: "end", mb: "0px" }}
            dropdownProps={{
              w: "100%",
              bg: "#fff",
              ...(allDisabled || isRedigitar
                ? { children: digitacaoFields.tableCode }
                : {}),
            }}
            // onChange={(value, option) => {
            //   onChangeDigitacao({ key: "tableCode", value: option.value });
            // }}
            value={
              simulacao.simulacao?.codeTable ||
              simulacao.simulacao?.tabela ||
              simulacao.simulacao?.tableCode ||
              ""
            }
            options={tabelasList}
            isLoading={loadingTabelas}
            errorMessage={errors["tableCode"]}
            isDisabled={true}
          />
        </Grid>
      </Grid>
    </CustomModal>
  );
}

export const openModalDigitacaoINSS = (props: ModalDigitacaoParams) =>
  makeEvent("openModalDigitacaoINSS", props);
