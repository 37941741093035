import { ObjectSchema, objectSchema } from "utils/object-methods";
import { ModalDataDigitarPropostaProps } from ".";
import { fieldValidation } from "utils/field-validation";
import { User } from "contexts/types/application-context-types";
import { isColomboUser } from "utils/is-colombo-user";

export const statusCivilOptions = [
  { name: "CASADO", value: "CASADO" },
  { name: "DESQUITADO", value: "DESQUITADO" },
  { name: "DIVORCIADO", value: "DIVORCIADO" },
  { name: "NÃO CADASTRADO", value: "NAO CADASTRADO" },
  { name: "NÃO DEFINIDO", value: "NAO DEFINIDO" },
  { name: "SEPARADO", value: "SEPARADO" },
  { name: "SOLTEIRO", value: "SOLTEIRO" },
  { name: "UNIÃO ESTÁVEL", value: "UNIÃO ESTÁVEL" },
  { name: "VIUVO", value: "VIUVO" },
  { name: "OUTROS", value: "OUTROS" },
];

export const yesOrNoOptions = [
  { name: "Sim", value: "SIM" },
  { name: "Não", value: "NÃO" },
];

export const dropdownTipoConta = [
  { name: "Conta Corrente", value: "CONTA_CORRENTE_INDIVIDUAL" },
  { name: "Conta Poupança", value: "CONTA_POUPANCA_INDIVIDUAL" },
];

export const validateDigitacao = (
  modalData: ModalDataDigitarPropostaProps,
  user: User
) => {
  const isUpdate = !!modalData.updateOptions;
  let schema: ObjectSchema = {
    atendimentoId: (value: ModalDataDigitarPropostaProps["atendimentoId"]) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value }).required().number();
      return { valid: validation.isValid, message };
    },
    bancoDigitacao: (
      value: ModalDataDigitarPropostaProps["bancoDigitacao"]
    ) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value }).required({ message });
      return { valid: validation.isValid, message: validation.errorMessage };
    },

    cpf: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value })
        .required({ message })
        .cpf({ message: "Insira um cpf válido" });
      return { valid: validation.isValid, message: validation.errorMessage };
    },
    name: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value }).required({ message });
      return {
        valid: validation.isValid,
        message: validation.errorMessage,
      };
    },
    email: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value })
        .required({ message })
        .email({ message: "Insira um email válido" });
      return {
        valid: validation.isValid,
        message: validation.errorMessage,
      };
    },
    birthDay: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value })
        .required({ message })
        .date({ message: "Data inválida" });
      return { valid: validation.isValid, message: validation.errorMessage };
    },
    gender: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value }).required({ message });
      return { valid: validation.isValid, message: validation.errorMessage };
    },
    civilStatus: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value }).required({ message });
      return { valid: validation.isValid, message: validation.errorMessage };
    },
    nationality: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value }).required({ message });
      return { valid: validation.isValid, message: validation.errorMessage };
    },
    originCountry: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value }).required({ message });
      return {
        valid:
          modalData.nationality === "ESTRANGEIRO" ? validation.isValid : true,
        message: validation.errorMessage,
      };
    },
    documentNumber: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value }).required({ message });
      return { valid: validation.isValid, message: validation.errorMessage };
    },
    documentEmissionState: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value }).required({ message });
      return { valid: validation.isValid, message: validation.errorMessage };
    },
    documentIssuer: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value }).required({ message });
      return { valid: validation.isValid, message: validation.errorMessage };
    },
    documentDate: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value })
        .required({ message })
        .date({ message: "Data inválida" });
      return { valid: validation.isValid, message: validation.errorMessage };
    },
    mothersName: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value }).required({ message });
      return { valid: validation.isValid, message: validation.errorMessage };
    },
    fathersName: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value }).required({ message });
      return { valid: validation.isValid, message: validation.errorMessage };
    },
    birthCity: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value }).required({ message });
      return { valid: validation.isValid, message: validation.errorMessage };
    },
    birthState: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value }).required({ message });
      return { valid: validation.isValid, message: validation.errorMessage };
    },
    incomeValue: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value }).required({ message });
      return { valid: validation.isValid, message: validation.errorMessage };
    },
    equityValue: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value }).required({ message });
      return { valid: validation.isValid, message: validation.errorMessage };
    },
    illiterate: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value }).required({ message });
      return { valid: validation.isValid, message: validation.errorMessage };
    },
    phoneDDD1: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value }).required({ message });
      return { valid: validation.isValid, message: validation.errorMessage };
    },
    phoneNumber1: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value }).required({ message });
      return { valid: validation.isValid, message: validation.errorMessage };
    },
    addressStreet1: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value }).required({ message });
      return { valid: validation.isValid, message: validation.errorMessage };
    },
    addressNumber1: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value }).required({ message });
      if (validation.isValid && value.length && Number(value) === 0) {
        validation.errorMessage = "O número do endereço não pode ser zero";
        validation.isValid = false;
      }
      return { valid: validation.isValid, message: validation.errorMessage };
    },
    addressComplement1: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value }).required({ message });
      return { valid: validation.isValid, message: validation.errorMessage };
    },
    addressNeigh1: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value }).required({ message });
      return { valid: validation.isValid, message: validation.errorMessage };
    },
    addressCity1: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value }).required({ message });
      return { valid: validation.isValid, message: validation.errorMessage };
    },
    addressStateCode1: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value }).required({ message });
      return { valid: validation.isValid, message: validation.errorMessage };
    },
    addressZipCode1: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value }).required({ message });
      return { valid: validation.isValid, message: validation.errorMessage };
    },
  };

  if (modalData.civilStatus === "CASADO") {
    schema = {
      ...schema,
      namePartner: (value) => {
        const message = "Preecha o campo";
        const validation = fieldValidation({ value }).required({ message });
        return { valid: validation.isValid, message: validation.errorMessage };
      },
      birthDayPartner: (value) => {
        const message = "Preecha o campo";
        const validation = fieldValidation({ value }).required({ message });
        return { valid: validation.isValid, message: validation.errorMessage };
      },
      cpfPartner: (value) => {
        const message = "Preecha o campo";
        const validation = fieldValidation({ value })
          .required({ message })
          .cpf({ message: "Insira um cpf válido" });
        return { valid: validation.isValid, message: validation.errorMessage };
      },
    };
  }

  if (!isColomboUser(user)) {
    schema = {
      ...schema,
      sellerCode: (value: ModalDataDigitarPropostaProps["sellerCode"]) => {
        const message = "Preecha o campo";
        const usuariosList = modalData.usuariosDigitadores?.map(
          ({ value }) => value
        );
        const validation = fieldValidation({ value })
          .required({ message })
          .custom({
            validate: (data) => !!usuariosList?.includes(data),
            message: "Usuário inexistente, é necessário escolher outro",
          });

        return { valid: validation.isValid, message: validation.errorMessage };
      },
    };
  }
  if (modalData.bancoDigitacao !== "TA_QUITADO") {
    schema = {
      ...schema,
      bankAgency: (value) => {
        const message = "Preecha o campo";
        const validation = fieldValidation({ value }).required({ message });
        return { valid: validation.isValid, message: validation.errorMessage };
      },
      bankNumber: (value) => {
        const message = "Preecha o campo";
        const validation = fieldValidation({ value }).required({ message });
        return { valid: validation.isValid, message: validation.errorMessage };
      },
      bankAccountNumber: (value) => {
        const message = "Preecha o campo";
        const validation = fieldValidation({ value }).required({ message });
        return { valid: validation.isValid, message: validation.errorMessage };
      },
      bankDigit: (value) => {
        const message = "Preecha o campo";
        const validation = fieldValidation({ value }).required({ message });
        return { valid: validation.isValid, message: validation.errorMessage };
      },
      bankType: (value) => {
        const message = "Preecha o campo";
        const validation = fieldValidation({ value }).required({ message });
        return { valid: validation.isValid, message: validation.errorMessage };
      },
    };
  }
  if (modalData.bancoDigitacao === "TA_QUITADO") {
    schema = {
      ...schema,
      pixKey: (value) => {
        const message = "Preecha o campo";
        const validation = fieldValidation({ value }).required({ message });
        return { valid: validation.isValid, message: validation.errorMessage };
      },
      pixKeyType: (value) => {
        const message = "Preecha o campo";
        const validation = fieldValidation({ value }).required({ message });
        return { valid: validation.isValid, message: validation.errorMessage };
      },
    };
  }
  if (isUpdate) {
    schema = {
      ...schema,
      digitacaoId: (value: ModalDataDigitarPropostaProps["digitacaoId"]) => {
        const message = "Preecha o campo";
        const validation = fieldValidation({ value }).required({ message });
        return { valid: validation.isValid, message: validation.errorMessage };
      },
    };
  }

  // if (modalData.bancoDigitacao !== "FACTA") {
  //   Object.keys(schema).forEach((key) => {
  //     schema[key] = () => ({ valid: true });
  //   });
  // }

  return objectSchema(schema, modalData);
};

export const bancosParse = [
  { name: "Facta", value: "FACTA" },
  { name: "PAN", value: "PAN" },
  { name: "C6", value: "C6" },
  { name: "Safra", value: "SAFRA" },
  { name: "Master", value: "MASTER" },
  { name: "Itaú", value: "ITAU" },
  { name: "Bmg", value: "BMG" },
  { name: "Novo Saque", value: "NOVO_SAQUE" },
  { name: "Lotus", value: "LOTUS_TAYA" },
  { name: "UY3", value: "UY3" },
  { name: "VCTeX", value: "VCTEX" },
  { name: "Tá Quitado", value: "TA_QUITADO" },
  { name: "V8", value: "V8" },
  { name: "Presença", value: "PRESENCA" },
];
