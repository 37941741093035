import {
  Box,
  Center,
  Flex,
  Grid,
  Input,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { ContratoInss } from "components/atendimentos-components/atendimento-form/types";
import { AttendanceActionsTypes } from "contexts/attendance-context/actions-reducer";
import { Checkbox } from "components/checkbox";
import { Dropdown } from "components/dropdown";
import { Dispatch, useEffect, useRef, useState } from "react";
import { AnimateElement } from "utils/animations";
import { onlyNumberValidMask } from "utils/string-formats";
import { useAttendanceContext } from "contexts/attendance-context";
import { colorsMap } from "components/atendimentos-components/pagina-atendimento/attendance-field/attendance-input";
import { calculateParcela, calculateValorEmprestimo } from "utils/calculator";
import { banks } from "components/mailing-components/mailing-modal-filter/mailing-filter/consts";
import { AttendanceTabKey } from "components/atendimentos-components/pagina-atendimento/attendance-data-form";

export function CardEmprestimoBancario({
  emprestimo,
  dispatch,
  emprestimoList,
  index,
  activeTab,
  telaAtendimento,
}: {
  emprestimo: ContratoInss;
  emprestimoList: ContratoInss[];
  dispatch: Dispatch<AttendanceActionsTypes>;
  index: number;
  activeTab: AttendanceTabKey;
  telaAtendimento: boolean;
}) {
  let isOpen = !!emprestimo.simular;
  const { formControl } = useAttendanceContext();
  const [isInsideDOM, setIsInsideDOM] = useState(isOpen);

  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const isDisabledNovoPrazo = ["PORT_REDUCAO_PARCELA"].includes(
    emprestimo.tipoOperacao!
  );
  const isDisabledCodBancoNovaOperacao = [
    "REFIN_TROCO",
    "REFIN_REDUCAO_PARCELA",
  ].includes(emprestimo.tipoOperacao!);

  const calculateFields = (c: ContratoInss) => {
    const isChangedTipoOperacao = c.tipoOperacao !== emprestimo.tipoOperacao;

    if (c.tipoOperacao === "REFIN_TROCO") {
      if (isChangedTipoOperacao) {
        c.novaTaxa = undefined;
        c.novoPrazo = undefined;
      }
      c.novaParcela = c.valorParcela;
      c.valorReducao = 0;
      c.codBancoNovaOperacao = c.codBanco;

      let valorNovoEmprestimo = calculateValorEmprestimo(
        c.novaParcela,
        c.novaTaxa,
        c.novoPrazo
      );
      c.troco =
        valorNovoEmprestimo && c.saldoQuitacao
          ? Math.floor((valorNovoEmprestimo - c.saldoQuitacao) * 100) / 100
          : undefined;
    } else if (c.tipoOperacao === "REFIN_REDUCAO_PARCELA") {
      if (isChangedTipoOperacao) {
        c.novaTaxa = undefined;
        c.novoPrazo = undefined;
        c.troco = undefined;
      }
      c.novaParcela = calculateParcela(
        c.saldoQuitacao,
        c.novaTaxa,
        c.novoPrazo
      );

      c.codBancoNovaOperacao = c.codBanco;
      c.valorReducao =
        c.valorParcela && c.novaParcela
          ? Math.floor((c.valorParcela - c.novaParcela) * 100) / 100
          : undefined;
    } else if (c.tipoOperacao === "PORT_REDUCAO_PARCELA") {
      if (isChangedTipoOperacao) {
        c.novaTaxa = undefined;
        c.codBancoNovaOperacao = undefined;
        c.troco = undefined;
      }
      c.novoPrazo = c.quantidadeParcelasEmAberto;
      c.novaParcela = calculateParcela(
        c.saldoQuitacao,
        c.novaTaxa,
        c.novoPrazo
      );

      c.valorReducao =
        c.valorParcela && c.novaParcela
          ? Math.floor((c.valorParcela - c.novaParcela) * 100) / 100
          : undefined;
    } else if (c.tipoOperacao === "PORT_REFIN_REDUCAO_PARCELA") {
      if (isChangedTipoOperacao) {
        c.novaTaxa = undefined;
        c.codBancoNovaOperacao = undefined;
        c.novoPrazo = undefined;
        c.troco = undefined;
      }
      c.novaParcela = calculateParcela(
        c.saldoQuitacao,
        c.novaTaxa,
        c.novoPrazo
      );

      c.valorReducao =
        c.valorParcela && c.novaParcela
          ? Math.floor((c.valorParcela - c.novaParcela) * 100) / 100
          : undefined;
    } else if (c.tipoOperacao === "PORT_REFIN_TROCO") {
      if (isChangedTipoOperacao) {
        c.novaTaxa = undefined;
        c.codBancoNovaOperacao = undefined;
        c.novoPrazo = undefined;
      }
      c.novaParcela = c.valorParcela;
      c.valorReducao = 0;
      let valorNovoEmprestimo = calculateValorEmprestimo(
        c.novaParcela,
        c.novaTaxa,
        c.novoPrazo
      );

      c.troco =
        valorNovoEmprestimo && c.saldoQuitacao
          ? Math.floor((valorNovoEmprestimo - c.saldoQuitacao) * 100) / 100
          : undefined;
    }
    return c;
  };

  const onChange = (newValue: ContratoInss) => {
    dispatch({
      type: "changeField",
      payload: {
        data: emprestimoList?.map((contract, i) => {
          if (i === index) {
            return calculateFields(newValue);
          }
          return contract;
        }),
        fieldName: "contratosEmprestimosInss",
      },
    });
  };

  const onOpen = () => {
    dispatch({
      type: "changeField",
      payload: {
        data: emprestimoList?.map((contract, i) => {
          if (i === index) {
            contract.simular = true;
          }
          return contract;
        }),
        fieldName: "contratosEmprestimosInss",
      },
    });
    setIsInsideDOM(true);
    isOpen = true;
  };

  const onClose = () => {
    dispatch({
      type: "changeField",
      payload: {
        data: emprestimoList?.map((contract, i) => {
          if (i === index) {
            contract.simular = false;
          }
          return contract;
        }),
        fieldName: "contratosEmprestimosInss",
      },
    });
    isOpen = false;
  };

  const onToggle = () => {
    if (isOpen) onClose();
    else onOpen();
  };

  const openAnimation = () => {
    const maxAnimationTiming = 200;
    const container = containerRef.current;
    const content = contentRef.current;
    if (container && content) {
      const currentProgress = Number(getComputedStyle(container).opacity);
      const duration = (1 - currentProgress) * maxAnimationTiming;
      container.style.overflow = `hidden`;
      AnimateElement(
        duration,
        [currentProgress, 1],
        (progress) => {
          if (container) {
            const maxHeight = content.clientHeight;
            container.style.opacity = `${progress}`;
            container.style.height = `${progress * maxHeight}px`;
          }
        },
        () => {
          if (isOpen && container) container.style.overflow = `visible`;
        }
      );
    }
  };

  const closeAnimation = () => {
    const content = contentRef.current;
    const container = containerRef.current;
    if (content && container) {
      const currentProgress = Number(getComputedStyle(container).opacity);
      const duration = currentProgress * 200;
      container.style.overflow = `hidden`;
      AnimateElement(
        duration,
        [1, 0],
        (progress) => {
          if (content && container) {
            const maxHeight = content.clientHeight;
            container.style.opacity = `${progress}`;
            container.style.height = `${progress * maxHeight}px`;
          }
        },
        () => (!isOpen ? setIsInsideDOM(false) : null)
      );
    }
  };

  useEffect(() => {
    if (isOpen) openAnimation();
    else closeAnimation();
  }, [isOpen]);

  const parseTagText = new Map([
    ["API_BANCO_CARTAO", "CARTÃO ATUALIZADO"],
    ["DATAFAST", "REFIN BANCO"],
    ["CONSULTA OFFLINE", ""],
    ["EXTRATO_ONLINE", "EXTR. ONLINE"],
    ["IN100", "IN100"],
  ]);

  const bankDesc = banks.find(
    (curr) => Number(curr.value) === Number(emprestimo.codBanco)
  )?.name;

  return (
    <Flex
      key={emprestimo.contrato}
      flexDir="column"
      border="1px solid #E8EAED"
      bg="#F5F7F9"
      p="10px 15px"
      borderRadius="5px"
      _hover={{ bg: "gray.100" }}
      transition="0.2s"
      cursor="pointer"
      onClick={onToggle}
    >
      <Flex mb="12px" alignItems="center" justifyContent="space-between">
        <Center>
          <Checkbox
            isChecked={emprestimo.simular}
            onChange={onToggle}
            mr="5px"
          />
          <Text fontWeight="bold">Simular</Text>
        </Center>
        {emprestimo.origem ? (
          <Center
            p="2px 5px"
            fontSize="11"
            borderRadius="5px"
            bg={colorsMap.get(emprestimo.origem)}
            color="#fff"
            ml="auto"
          >
            {parseTagText.get(emprestimo.origem)}
          </Center>
        ) : null}
      </Flex>
      <Grid
        templateColumns={telaAtendimento ? "1fr 1fr 1fr 1fr" : "1fr 1fr"}
        gap="10px"
      >
        <Text>
          <b>Banco:</b> <br />
          {emprestimo.codBanco ? (
            <Text as="span" whiteSpace="nowrap">
              {`${emprestimo.codBanco}${bankDesc ? " - " + bankDesc : ""}`}
            </Text>
          ) : null}
        </Text>
        <Text>
          <b>Contrato:</b> <br />
          <Text as="span" whiteSpace="nowrap">
            {typeof emprestimo.contrato === "string"
              ? emprestimo.contrato.length > 25
                ? emprestimo.contrato.slice(0, 25) + "..."
                : emprestimo.contrato
              : null}
          </Text>
        </Text>
        <Text>
          <b>Valor Parcelas:</b>
          <br />
          <Text as="span" whiteSpace="nowrap">
            R$ {emprestimo.valorParcela}
          </Text>
        </Text>
        <Text>
          <b>Taxa:</b>
          <br />
          <Text as="span" whiteSpace="nowrap">
            {emprestimo.taxa}
          </Text>
        </Text>
        <Text>
          <b>Quantidade Parcelas:</b>
          <br />
          <Text as="span" whiteSpace="nowrap">
            {emprestimo.quantidadeParcelas}
          </Text>
        </Text>
        <Text>
          <b>Parcelas em Aberto:</b>
          <br />
          <Text as="span" whiteSpace="nowrap">
            {emprestimo.quantidadeParcelasEmAberto}
          </Text>
        </Text>
        <Text>
          <b>Saldo Dev. Aprox.:</b>
          <br />
          {emprestimo.saldoQuitacao ? (
            <Text as="span" whiteSpace="nowrap">
              R${" "}
              {typeof emprestimo.saldoQuitacao === "number"
                ? emprestimo.saldoQuitacao.toFixed(2)
                : emprestimo.saldoQuitacao}
            </Text>
          ) : null}
        </Text>
        <Text>
          <b>Valor Empréstimo:</b>
          <br />
          <Text as="span">
            {emprestimo.valorEmprestado ? (
              <Text as="span" whiteSpace="nowrap">
                R${" "}
                {typeof emprestimo.valorEmprestado === "number"
                  ? emprestimo.valorEmprestado.toFixed(2)
                  : emprestimo.valorEmprestado}{" "}
              </Text>
            ) : null}
          </Text>
        </Text>
        <Text>
          <b>Início Desconto:</b>
          <br />
          <Text as="span">{emprestimo.competenciaInicioDesconto}</Text>
        </Text>
        <Text>
          <b>Fim Desconto:</b>
          <br />
          <Text as="span">{emprestimo.competenciaFimDesconto}</Text>
        </Text>
        <Text>
          <b>Data Averbação:</b>
          <br />
          <Text as="span">{emprestimo.dataInicioContrato}</Text>
        </Text>
        <Text>
          <b>Situação:</b>
          <br />
          <Text
            as="span"
            p="4px"
            borderRadius="5px"
            color={emprestimo.situacao === "Ativo" ? "#00cc00" : ""}
          >
            {emprestimo.situacao}
          </Text>
        </Text>
      </Grid>
      <Flex
        ref={containerRef}
        flexDir="column"
        w="100%"
        opacity="0"
        overflow="hidden"
        height="0"
      >
        {isInsideDOM ? (
          <Grid
            pt="30px"
            templateColumns="repeat(4, 1fr)"
            gap="15px"
            w="100%"
            ref={contentRef}
            onClick={(e) => e.stopPropagation()}
          >
            <Box gridArea="auto / span 2">
              <Flex alignItems="center">
                <Text mb="8px" fontWeight="bold">
                  Tipo Operação:
                </Text>
              </Flex>
              <Dropdown
                onChange={(value) =>
                  onChange({ ...emprestimo, tipoOperacao: value })
                }
                options={dropdownTipoOperacaoREFIN}
                value={emprestimo.tipoOperacao}
                w="100%"
                variant="outline-custom"
                _disabled={{ pointerEvents: "none", opacity: 0.8 }}
              />
            </Box>
            <Box gridArea="auto / span 2">
              <Flex alignItems="center">
                <Text mb="8px" fontWeight="bold">
                  Banco (Nova Operação):
                </Text>
              </Flex>
              <Dropdown
                onChange={(value) =>
                  onChange({ ...emprestimo, codBancoNovaOperacao: value })
                }
                options={dropdownBancosNovaOperacao}
                value={emprestimo.codBancoNovaOperacao}
                w="100%"
                variant="outline-custom"
                isDisabled={isDisabledCodBancoNovaOperacao}
                _disabled={{ pointerEvents: "none", opacity: 0.8 }}
              />
            </Box>
            <Box>
              <Flex alignItems="center">
                <Text mb="8px" fontWeight="bold">
                  Taxa (Nova Operação):
                </Text>
              </Flex>
              <Input
                type="number"
                value={emprestimo.novaTaxa ?? ""}
                onChange={({ target }) =>
                  onChange({
                    ...emprestimo,
                    novaTaxa: target.value
                      ? Number(onlyNumberValidMask(target.value).raw)
                      : undefined,
                  })
                }
                variant="outline-custom"
                _disabled={{ pointerEvents: "none", opacity: 0.8 }}
              />
            </Box>

            <Box>
              <Flex alignItems="center">
                <Text mb="8px" fontWeight="bold">
                  Prazo (Nova Operação):
                </Text>
              </Flex>
              <Input
                onChange={({ target }) =>
                  onChange({
                    ...emprestimo,
                    novoPrazo: target.value ? Number(target.value) : undefined,
                  })
                }
                value={emprestimo.novoPrazo ?? ""}
                variant="outline-custom"
                type="number"
                isDisabled={isDisabledNovoPrazo}
                _disabled={{ pointerEvents: "none", opacity: 0.8 }}
              />
            </Box>
            <Box>
              <Flex alignItems="center">
                <Text mb="8px" fontWeight="bold">
                  Parcela (Nova Operação):
                </Text>
              </Flex>
              <Input
                type="number"
                value={emprestimo.novaParcela ?? ""}
                variant="outline-custom"
                isDisabled={true}
                _disabled={{ pointerEvents: "none", opacity: 0.8 }}
              />
            </Box>
            <Box>
              <Flex alignItems="center">
                <Text mb="8px" fontWeight="bold">
                  Redução Parcela:
                </Text>
              </Flex>
              <Input
                value={emprestimo.valorReducao ?? ""}
                variant="outline-custom"
                isDisabled={true}
                _disabled={{ pointerEvents: "none", opacity: 0.8 }}
              />
            </Box>
            <Box>
              <Flex alignItems="center">
                <Text mb="8px" fontWeight="bold">
                  Troco (Valor liberado):
                </Text>
              </Flex>
              <Input
                value={emprestimo.troco ?? ""}
                type="number"
                variant="outline-custom"
                isDisabled={true}
                _disabled={{ pointerEvents: "none", opacity: 0.8 }}
              />
            </Box>
            <Box>
              <Flex alignItems="center">
                <Text mb="8px" fontWeight="bold">
                  ADE:
                </Text>
              </Flex>
              <Input
                onChange={({ target }) =>
                  onChange({ ...emprestimo, ade: target.value })
                }
                value={emprestimo.ade ?? ""}
                variant="outline-custom"
                _disabled={{ pointerEvents: "none", opacity: 0.8 }}
              />
            </Box>
            <Box gridArea="auto / span 2">
              <Flex alignItems="center">
                <Text mb="8px" fontWeight="bold">
                  Link Fomalização:
                </Text>
              </Flex>
              <Input
                onChange={({ target }) =>
                  onChange({ ...emprestimo, linkFormalizacao: target.value })
                }
                value={emprestimo.linkFormalizacao ?? ""}
                variant="outline-custom"
                _disabled={{ pointerEvents: "none", opacity: 0.8 }}
              />
            </Box>
            <Box gridArea="auto / span 4">
              <Flex alignItems="center">
                <Text mb="8px" fontWeight="bold">
                  Observação:
                </Text>
              </Flex>
              <Input
                onChange={({ target }) =>
                  onChange({
                    ...emprestimo,
                    observacaoDigitacao: target.value,
                  })
                }
                value={emprestimo.observacaoDigitacao ?? ""}
                variant="outline-custom"
                _disabled={{ pointerEvents: "none", opacity: 0.8 }}
                as="textarea"
                height="60px"
                minH="60px"
              />
            </Box>
          </Grid>
        ) : null}
      </Flex>
    </Flex>
  );
}

export const dropdownTipoOperacaoREFIN = [
  { name: "REFIN TROCO", value: "REFIN_TROCO" },
  { name: "REFIN REDUCAO PARCELA", value: "REFIN_REDUCAO_PARCELA" },
  { name: "PORT REDUCAO PARCELA", value: "PORT_REDUCAO_PARCELA" },
  { name: "PORT REFIN REDUCAO PARCELA", value: "PORT_REFIN_REDUCAO_PARCELA" },
  { name: "PORT REFIN TROCO", value: "PORT_REFIN_TROCO" },
];

const dropdownBancosNovaOperacao = [
  { name: "12 - Banco Inbursa", value: 12 },
  { name: "29 - Banco Itaú Consignado", value: 29 },
  { name: "33 - Banco Santander", value: 33 },
  { name: "41 - Banrisul", value: 41 },
  { name: "69 - Banco Crefisa", value: 69 },
  { name: "104 - Caixa Econ. Federal", value: 104 },
  { name: "121 - Banco Agibank", value: 121 },
  { name: "169 - Banco Olé", value: 169 },
  { name: "237 - Banco Bradesco", value: 237 },
  { name: "254 - Paraná Banco", value: 254 },
  { name: "290 - PagBank", value: 290 },
  { name: "318 - Banco BMG", value: 318 },
  { name: "341 - Banco Itaú", value: 341 },
  { name: "394 - Banco Bradesco Financiamentos", value: 394 },
  { name: "422 - Banco Safra", value: 422 },
  { name: "623 - Banco PAN", value: 623 },
  { name: "626 - Banco C6", value: 626 },
  { name: "707 - Banco Daycoval", value: 707 },
  { name: "739 - Banco Cetelem", value: 739 },
  { name: "752 - Banco Cetelem", value: 752 },
  { name: "935 - Facta Financeira", value: 935 },
];
