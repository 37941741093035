import { fieldValidation } from "utils/field-validation";
import { ObjectSchema, objectSchema } from "utils/object-methods";
import {
  DigitacaoINSSProps,
  PostDigitacaoINSSProps,
  SimulacaoDataBMGProps,
  SimulacaoDataC6Props,
  SimulacaoDataFACTAProps,
  SimulacaoDataICREDProps,
  SimulacaoDataPANProps,
  SimulacaoDataPresencaProps,
  SimulacaoDataProps,
  TabelaProps,
  UsuarioBanco,
} from "./types";
import { Attendance } from "components/atendimentos-components/atendimento-form/types";
import { AttendanceActionsTypes } from "contexts/attendance-context/actions-reducer";
import { Dispatch, SetStateAction } from "react";
import { openModalError } from "components/modal-error";
import { Box, Text } from "@chakra-ui/react";
import { SimulacaoResultProps } from "..";
import { maskPhoneFormat } from "utils/string-formats";
import { Toast } from "components/toast";

export const setAttendanceError = (
  key: keyof Attendance,
  dispatch: Dispatch<AttendanceActionsTypes>
) => {
  dispatch({
    type: "setInvalidField",
    payload: { fieldName: key, action: "append" },
  });
};

export function validateSimulacaoFields(
  body:
    | SimulacaoDataFACTAProps
    | SimulacaoDataC6Props
    | SimulacaoDataBMGProps
    | SimulacaoDataICREDProps
    | SimulacaoDataPANProps,
  dispatch: Dispatch<AttendanceActionsTypes>,
  isColomboUser: boolean
  // onClose: () => void
) {
  const banco = body.banco;
  const attendanceFieldErrors = new Map<keyof Attendance, { name: string }>([]);

  const schemaFACTA: ObjectSchema<SimulacaoDataFACTAProps> = {
    banco: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    tipoOperacao: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    cpf: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      if (!isValid) {
        attendanceFieldErrors.set("cpf", { name: "CPF" });
      }
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    birthDay: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      if (!isValid) {
        attendanceFieldErrors.set("dataNascimento", { name: "Nascimento" });
      }
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    loginCertificado: (value) => {
      if (isColomboUser) return { valid: true }; // Colombo nao usa
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    vendedor: (value) => {
      if (isColomboUser) return { valid: true }; // Colombo nao usa
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    loginVendedor: (value) => {
      if (isColomboUser) return { valid: true }; // Colombo nao usa
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    term: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    codigoTabela: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    tabela: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    valueInstallment: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    valorRenda: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      if (!isValid) {
        attendanceFieldErrors.set("valorSalario", { name: "Valor Salário" });
      }
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    cpfRepresentanteLegal: (value) => {
      return { valid: true };
    },
    nomeRepresentanteLegal: (value) => {
      return { valid: true };
    },
  };

  const schemaBMG: ObjectSchema<SimulacaoDataBMGProps> = {
    banco: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    cpf: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      if (!isValid) {
        attendanceFieldErrors.set("cpf", { name: "CPF" });
      }
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    benefitNumber: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      if (!isValid) {
        attendanceFieldErrors.set("nb", { name: "Número Benefício" });
      }
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    valueInstallment: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    birthDay: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      if (!isValid) {
        attendanceFieldErrors.set("dataNascimento", { name: "Nascimento" });
      }
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    codeTable: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    term: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    margin: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    numberInstallments: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
  };
  const schemaC6: ObjectSchema<SimulacaoDataC6Props> = {
    banco: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    benefitNumber: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      if (!isValid) {
        attendanceFieldErrors.set("nb", { name: "Número Benefício" });
      }
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    cpf: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      if (!isValid) {
        attendanceFieldErrors.set("cpf", { name: "CPF" });
      }
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    birthDay: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      if (!isValid) {
        attendanceFieldErrors.set("dataNascimento", { name: "Nascimento" });
      }
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    incomeAmount: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      if (!isValid) {
        attendanceFieldErrors.set("valorSalario", { name: "Valor Salário" });
      }
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    simulationType: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    formalizationSubtype: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    productTypeCode: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    valueInstallment: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    term: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    username: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    password: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    tableCode: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    cpfCertifiedUser: (value, fullObject) => {
      const { isValid } = fieldValidation({ value }).required();
      if (fullObject.username && !isValid) {
        Toast({
          title: "CPF do usuário digitador selecionado não cadastrado",
          duration: 6000,
          status: "error",
        });
        return { valid: isValid, message: "CPF do usuário não cadastrado" };
      } else {
        return { valid: isValid, message: "Preencha o campo corretamente" };
      }
    },
  };

  const schemaICRED: ObjectSchema<SimulacaoDataICREDProps> = {
    phoneDDD1: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      if (!isValid) {
        attendanceFieldErrors.set("telefoneResidencial", {
          name: "Telefone 1 (Dados Cadastrais => Contato)",
        });
      }
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    phoneNumber1: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      if (!isValid) {
        attendanceFieldErrors.set("telefoneResidencial", {
          name: "Telefone 1 (Dados Cadastrais => Contato)",
        });
      }
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    banco: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    cpf: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      if (!isValid) {
        attendanceFieldErrors.set("cpf", { name: "CPF" });
      }
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    benefitNumber: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      if (!isValid) {
        attendanceFieldErrors.set("nb", { name: "Número Benefício" });
      }
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    valueInstallment: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    birthDay: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      if (!isValid) {
        attendanceFieldErrors.set("dataNascimento", {
          name: "Nascimento (Dados Cadastrais => Informações Pessoais)",
        });
      }
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    codeTable: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    term: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    margin: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    numberInstallments: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
  };
  const schemaPRESENCA: ObjectSchema<SimulacaoDataPresencaProps> = {
    banco: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    cpf: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      if (!isValid) {
        attendanceFieldErrors.set("cpf", { name: "CPF" });
      }
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    benefitNumber: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      if (!isValid) {
        attendanceFieldErrors.set("nb", { name: "Número Benefício" });
      }
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    birthDay: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      if (!isValid) {
        attendanceFieldErrors.set("dataNascimento", {
          name: "Nascimento (Dados Cadastrais => Informações Pessoais)",
        });
      }
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    term: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    valueInstallment: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    codeTable: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    phoneDDD1: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      if (!isValid) {
        attendanceFieldErrors.set("telefoneResidencial", {
          name: "Telefone 1 (Dados Cadastrais => Contato)",
        });
      }
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    phoneNumber1: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      if (!isValid) {
        attendanceFieldErrors.set("telefoneResidencial", {
          name: "Telefone 1 (Dados Cadastrais => Contato)",
        });
      }
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    specie: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      if (!isValid) {
        attendanceFieldErrors.set("especieNB", {
          name: "Espécie (Dados Cadastrais => Benefício)",
        });
      }
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    benefitState: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      if (!isValid) {
        attendanceFieldErrors.set("ufNB", {
          name: "UF do benefício (Dados Cadastrais => Benefício)",
        });
      }
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    bankAgency: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      if (!isValid) {
        attendanceFieldErrors.set("agenciaPagamento", {
          name: "Agência pagamento (Dados Cadastrais => Informações Bancárias)",
        });
      }
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    bankNumber: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      if (!isValid) {
        attendanceFieldErrors.set("contaPagamento", {
          name: "Conta pagamento (Dados Cadastrais => Informações Bancárias)",
        });
      }
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
  };

  const schemaPAN: ObjectSchema<SimulacaoDataPANProps> = {
    cpf: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      if (!isValid) {
        attendanceFieldErrors.set("cpf", { name: "CPF" });
      }
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    benefitNumber: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      if (!isValid) {
        attendanceFieldErrors.set("nb", { name: "Número Benefício" });
      }
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    banco: (value) => {
      const isValid = value === "PAN";
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    valueInstallment: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    birthDay: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      if (!isValid) {
        attendanceFieldErrors.set("dataNascimento", {
          name: "Nascimento (Dados Cadastrais => Informações Pessoais)",
        });
      }
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    codeTable: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    income: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      if (!isValid) {
        attendanceFieldErrors.set("valorSalario", { name: "Valor Salário" });
      }
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    term: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
  };

  const schema = new Map([
    ["BMG", schemaBMG],
    ["FACTA", schemaFACTA],
    ["C6", schemaC6],
    ["ICRED", schemaICRED],
    ["PRESENCA", schemaPRESENCA],
    ["PAN", schemaPAN],
  ]).get(banco!);

  if (!schema) return { isValid: false, errors: [] };

  const result = objectSchema(schema, body);

  if (attendanceFieldErrors.size) {
    revisarCamposComErrors(attendanceFieldErrors, dispatch);
  }

  return result;
}

export function revisarCamposComErrors(
  map: Map<keyof Attendance, { name: string }>,
  dispatch: Dispatch<AttendanceActionsTypes>
) {
  const fields = Array.from(map.entries());
  // onClose();
  openModalError({
    title: "Revisar campos do atendimento",
    message: (
      <Box>
        <Text fontWeight="bold" mb="8px">
          Revise os campos:{" "}
        </Text>

        {fields.map(([key, { name }]) => {
          setAttendanceError(key, dispatch);
          return <Text color="red.500">{name}</Text>;
        })}
      </Box>
    ),
  });
}

export const dropdownPrazo = [
  { name: "12", value: "12" },
  { name: "24", value: "24" },
  { name: "36", value: "36" },
  { name: "48", value: "48" },
  { name: "60", value: "60" },
  { name: "72", value: "72" },
  { name: "84", value: "84" },
  { name: "96", value: "96" },
];

export function getSimulacaoBodyType(
  attendance: Attendance,
  simulacao: SimulacaoDataProps,
  selectedUsuariobanco: UsuarioBanco | undefined,
  selectedTabela: Partial<TabelaProps> | undefined,
  setErrors: Dispatch<SetStateAction<{ [k: string]: string }>>,
  isColomboUser: boolean
) {
  if (!simulacao.banco) {
    Toast({ title: "Escolha um banco", status: "error" });
    setErrors((errors) => ({ ...errors, banco: "Escolha um usuário" }));
    return {};
  }
  if (!selectedTabela) {
    Toast({ title: "Escolha uma tabela", status: "error" });
    setErrors((errors) => ({ ...errors, tabela: "Escolha uma tabela" }));
    return {};
  }
  if (
    !isColomboUser &&
    !["ICRED", "BMG", "PRESENCA", "PAN"].includes(simulacao.banco!) &&
    !selectedUsuariobanco
  ) {
    Toast({ title: "Escolha um usuário", status: "error" });
    setErrors((errors) => ({ ...errors, usuarioBanco: "Escolha um usuário" }));
    return {};
  }

  const formatC6Data = (data: string) => {
    if (typeof data === "string") {
      data = data.split("/").reverse().join("-");
    }
    return data;
  };

  if (simulacao.banco === "FACTA") {
    const colomboBody = {
      banco: "FACTA",
      tipoOperacao:
        attendance.cpfRepresentanteLegal !== null &&
        attendance.nomeRepresentanteLegal !== null
          ? "35"
          : "13",

      cpf: attendance.cpf!,
      birthDay: attendance.dataNascimento!,
      loginCertificado: null!, // Colombo nao usa
      vendedor: null, // Colombo nao usa
      loginVendedor: null, // Colombo nao usa
      term: simulacao.term,
      tabela: selectedTabela.codigo,
      codigoTabela: selectedTabela.codigo,
      valueInstallment: simulacao.valueInstallment,
      valorRenda: attendance.valorSalario!,
      cpfRepresentanteLegal: attendance.cpfRepresentanteLegal,
      nomeRepresentanteLegal: attendance.nomeRepresentanteLegal,
    };
    const body: SimulacaoDataFACTAProps = {
      banco: "FACTA",
      tipoOperacao:
        attendance.cpfRepresentanteLegal !== null &&
        attendance.nomeRepresentanteLegal !== null
          ? "35"
          : "13",
      cpf: attendance.cpf!,
      birthDay: attendance.dataNascimento!,
      loginCertificado: selectedUsuariobanco?.username!,
      vendedor: selectedUsuariobanco?.username,
      loginVendedor: selectedUsuariobanco?.username,
      term: simulacao.term,
      tabela: selectedTabela.codigo,
      codigoTabela: selectedTabela.codigo,
      valueInstallment: simulacao.valueInstallment,
      valorRenda: attendance.valorSalario!,
      cpfRepresentanteLegal: attendance.cpfRepresentanteLegal,
      nomeRepresentanteLegal: attendance.nomeRepresentanteLegal,
    };
    return isColomboUser ? colomboBody : body;
  } else if (simulacao.banco === "BMG") {
    const body: SimulacaoDataBMGProps = {
      banco: "BMG",
      cpf: attendance.cpf!,
      benefitNumber: attendance.nb,
      valueInstallment: simulacao.valueInstallment,
      birthDay: attendance.dataNascimento!,
      codeTable: selectedTabela.codigo,
      term: Number(simulacao.term),
      margin: simulacao.valueInstallment,
      numberInstallments: Number(simulacao.term),
    };
    return body;
  } else if (simulacao.banco === "C6") {
    const body: SimulacaoDataC6Props = {
      banco: "C6",
      benefitNumber: attendance.nb,
      cpf: attendance.cpf!,
      birthDay: formatC6Data(attendance.dataNascimento!),
      incomeAmount: attendance.valorSalario!,
      simulationType: "POR_VALOR_PARCELA",
      formalizationSubtype: "DIGITAL_WEB",
      productTypeCode: "0007",
      valueInstallment: simulacao.valueInstallment,
      term: simulacao.term,
      username: selectedUsuariobanco!.username,
      password: selectedUsuariobanco!.password,
      cpfCertifiedUser: selectedUsuariobanco!.cpfUsuarioCertificado,
      tableCode: selectedTabela.codigo,
    };
    return body;
  } else if (simulacao.banco === "ICRED") {
    const body: SimulacaoDataICREDProps = {
      banco: "ICRED",
      cpf: attendance.cpf!,
      benefitNumber: attendance.nb,
      valueInstallment: simulacao.valueInstallment,
      birthDay: attendance.dataNascimento!,
      codeTable: selectedTabela.codigo,
      term: Number(simulacao.term),
      margin: simulacao.valueInstallment,
      numberInstallments: Number(simulacao.term),
      phoneDDD1: phoneSeparate({
        completedNumber: attendance.telefoneResidencial || "",
      }).ddd,
      phoneNumber1: phoneSeparate({
        completedNumber: attendance.telefoneResidencial || "",
      }).number,
    };

    return body;
  } else if (simulacao.banco === "PRESENCA") {
    const body: SimulacaoDataPresencaProps = {
      banco: "PRESENCA",
      cpf: attendance.cpf!,
      benefitNumber: attendance.nb,
      birthDay: attendance.dataNascimento!,
      term: Number(simulacao.term),
      valueInstallment: simulacao.valueInstallment!,
      codeTable: selectedTabela.codigo!,
      phoneDDD1: phoneSeparate({
        completedNumber: attendance.telefoneResidencial || "",
      }).ddd,
      phoneNumber1: phoneSeparate({
        completedNumber: attendance.telefoneResidencial || "",
      }).number,
      specie: attendance.especieNB ? `${attendance.especieNB}` : "",
      benefitState: attendance.ufNB!,
      bankAgency: attendance.agenciaPagamento!,
      bankNumber: attendance.contaPagamento!,
    };
    return body;
  } else if (simulacao.banco === "PAN") {
    const body: SimulacaoDataPANProps = {
      cpf: attendance.cpf!,
      benefitNumber: attendance.nb!,
      banco: "PAN",
      valueInstallment: simulacao.valueInstallment!,
      birthDay: attendance.dataNascimento!,
      codeTable: selectedTabela.codigo!,
      income: attendance.valorSalario!,
      term: simulacao.term!,
    };
    return body;
  }

  Toast({ title: "Não há body para o banco selecionado", status: "error" });
  return {};
}

export const formatTipoContaPagamento = (value: string) =>
  value === "CONTA_CORRENTE" ? "CORRENTE" : "POUPANCA";

export const bankTypeOptions = [
  { name: "Conta Corrente", value: "CORRENTE" },
  { name: "Conta Poupança", value: "POUPANCA" },
];

export function copyAttendanceDataToDigitacaoINSS(
  simulacao: SimulacaoResultProps,
  attendance: Attendance,
  digitacaoFields: DigitacaoINSSProps
) {
  const formatSexo = (value: string) => (value === "MASCULINO" ? "M" : "F");

  const result: DigitacaoINSSProps = {
    banco: simulacao.banco,
    taxIdentifier: attendance.cpf!,
    cpf: attendance.cpf!,
    name: attendance.nome,
    gender: formatSexo(attendance.sexo!),
    birthDay: attendance.dataNascimento!,
    documentNumber: attendance.documento!?.replaceAll?.(" ", ""),
    documentEmissionState: attendance.ufEmissaoDoc! || attendance.uf,
    documentIssuer: attendance.orgaoEmissorDoc! || "SSP",
    documentDate: attendance.dataEmissaoDoc!,
    birthState: attendance.ufNaturalidade! || attendance.uf,
    birthCity: attendance.naturalidade! || attendance.cidade,
    phoneNumber1: phoneSeparate({
      completedNumber: attendance.telefoneResidencial!,
    }).number,
    phoneDDD1: phoneSeparate({
      completedNumber: attendance.telefoneResidencial!,
    }).ddd,
    incomeValue: attendance.valorSalario!,
    addressZipCode1: attendance.cep!,
    addressStreet1: attendance.endereco!,
    addressNumber1: attendance.numero!,
    addressNeigh1: attendance.bairro!,
    addressStateCode1: attendance.uf!,
    addressCity1: attendance.cidade!,
    mothersName: attendance.nomeMae!,
    bankAgency: attendance.agenciaPagamento?.replaceAll?.(" ", ""),
    bankAccountNumber: attendance.contaPagamento?.replaceAll?.(" ", ""),
    benefitNumber: Number(attendance.nb!?.replaceAll?.(" ", "")),
    specie: `${attendance.especieNB!}`,
    benefitState: attendance.ufNB! || attendance.uf,
    bankType: formatTipoContaPagamento(attendance.tipoContaPagamento!),
    // civilStatus: 5,
    bankNumber: attendance.codBancoPagamento!?.replaceAll?.(" ", ""),
    cpfLegalRepresentative: attendance.cpfRepresentanteLegal!,
    nameLegalRepresentative: attendance.nomeRepresentanteLegal!,
    // certifiedLogin: ,
    // phoneDDD1: ,
    // passwordConsig: ,
    // seller: ,
    // sellerLogin: ,
    // tableCode: ,
    // userConsig: ,
    ...digitacaoFields,
  };
  return result;
}

export function phoneSeparate({
  dddPhone,
  phone,
  completedNumber,
}: {
  dddPhone?: string | null;
  phone?: string | null;
  completedNumber?: string;
}) {
  const completedString =
    (dddPhone ?? "") + (phone ?? "") || (completedNumber ?? "");
  const result = maskPhoneFormat(completedString);
  const ddd = result.parts[1];
  const numberPart1 = result.parts[2];
  const numberPart2 = result.parts[3];
  const number = numberPart1 + numberPart2;
  return {
    ddd,
    number,
    phoneAndDDDNumbers: result.onlyNumbers,
    phoneAndDDDFormatedValue: result.formatedValue,
  };
}

export function validateDigitacaoINSS(
  body: DigitacaoINSSProps,
  dispatch: Dispatch<AttendanceActionsTypes>,
  isColomboUser: boolean
) {
  let schema: ObjectSchema<PostDigitacaoINSSProps> = {
    banco: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });
      return { valid: isValid, message: errorMessage };
    },
    taxIdentifier: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });
      if (value == null && body["cpf"]) {
        const value = body["cpf"];
        const { isValid, errorMessage } = fieldValidation({ value }).required({
          message: "Preencha o campo corretamente",
        });
        return { valid: isValid, message: errorMessage, format: value };
      }
      return { valid: isValid, message: errorMessage };
    },
    name: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    gender: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    // civilStatus: (value) => {
    //   const { isValid, errorMessage } = fieldValidation({ value }).required({
    //     message: "Preencha o campo corretamente",
    //   });
    //   return { valid: isValid, message: errorMessage };
    // },
    birthDay: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    documentNumber: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    documentEmissionState: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    documentIssuer: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    documentDate: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    birthState: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    birthCity: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    phoneNumber1: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    incomeValue: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    addressZipCode1: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    addressStreet1: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    addressNumber1: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    addressNeigh1: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    addressStateCode1: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    addressCity1: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    mothersName: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    bankNumber: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    bankAgency: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    bankAccountNumber: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    benefitNumber: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    specie: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });
      if (value == null && body["speciesNb"]) {
        const value = body["speciesNb"];
        const { isValid, errorMessage } = fieldValidation({ value }).required({
          message: "Preencha o campo corretamente",
        });
        return {
          valid: isValid,
          message: errorMessage,
          format: value,
        };
      }

      return { valid: isValid, message: errorMessage };
    },
    organCode: (value) => {
      if (body.banco !== "PAN") return { valid: true };

      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    benefitState: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    bankType: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    cpfLegalRepresentative: (value) => {
      return { valid: true };
    },
    nameLegalRepresentative: (value) => {
      return { valid: true };
    },
    phoneDDD1: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });
      return { valid: isValid, message: errorMessage };
    },
    //
    // USUARIO BANCO
    //
    userConsig: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });
      if (isColomboUser) return { valid: true };
      return { valid: isValid, message: errorMessage };
    },
    sellerCpf: (value) => {
      if (body.banco !== "PAN") return { valid: true };

      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    passwordConsig: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });
      if (isColomboUser) return { valid: true };
      return { valid: isValid, message: errorMessage };
    },
    // seller: (value) => {
    //   const { isValid, errorMessage } = fieldValidation({ value }).required({
    //     message: "Preencha o campo corretamente",
    //   });
    //   return { valid: isValid, message: errorMessage };
    // },
    // sellerLogin: (value) => {
    //   const { isValid, errorMessage } = fieldValidation({ value }).required({
    //     message: "Preencha o campo corretamente",
    //   });
    //   return { valid: isValid, message: errorMessage };
    // },
    // cpfCertifiedUser: (value) => {
    //   const { isValid, errorMessage } = fieldValidation({ value }).required({
    //     message: "Preencha o campo corretamente",
    //   });
    //   return { valid: isValid, message: errorMessage };
    // },
  };

  const result = objectSchema(schema, body);

  return result;
}

// {
//   "bancoSelecionado": "FACTA",
//   "cpf": 25805298880,
//   "nome": "APARECIDA DONIZETE MARTIS",
//   "sexo": "F",
//   "estadoCivil": 5,
//   "dataNascimento": "21/09/1960",
//   "codigoFamiliar": null,
//   "rg": "00286488036",
//   "estadoRg": "SP",
//   "orgaoEmissor": "SSP",
//   "dataExpedicao": "01/10/2022",
//   "estadoNatural": "SP",
//   "cidadeNatural": "Pirapora do Bom Jesus",
//   "nacionalidade": 1,
//   "celular": "11994995909",
//   "renda": 1412,
//   "cep": "06550000",
//   "endereco": "RUA JOAO JOSE PINTO 000980",
//   "numero": "1",
//   "bairro": "IGAVETA",
//   "estado": "SP",
//   "cidade": "Pirapora do Bom Jesus",
//   "nomeMae": "LUZIA MARIA ALVES",
//   "nomePai": "Nao informado",
//   "banco": 1,
//   "agencia": 9596,
//   "conta": "0010244797",
//   "matricula": 1972322173,
//   "tipoCreditoBeneficio": 1,
//   "tipoBeneficio": "21",
//   "estadoBeneficio": "SP",
//   "clienteIletradoImpossibilitado": "N",
//   "valorPatrimonio": 10000,
//   "paisOrigem": 0,
//   "messageErro": null,
//   "tipoConta": "CORRENTE"
// }
