import { Box, Center, Flex, Text } from "@chakra-ui/react";
import { menuArray, MenuItemProps } from "./menu-array";
import { useApplicationContext } from "contexts/ApplicationContext";
import { MouseEventHandler, useEffect, useRef, useState } from "react";
import { checkIsVisible } from "./check-is-visible";
import { useRouter } from "routes/router-control/use-router";
import { openModalError } from "components/modal-error";
import { AnimateElement, fade, scale } from "utils/animations";
import { ChevronRightIcon } from "@chakra-ui/icons";

export function NewMenuItem({ icon, name, href, subItems }: MenuItemProps) {
  let [isOpen, setIsOpen] = useState(false);
  let [isInner, setIsInner] = useState(false);

  const router = useRouter();
  const { user, menuErrorMessage } = useApplicationContext();

  const menuItemRef = useRef<HTMLDivElement>(null);
  const subitemRef = useRef<HTMLDivElement>(null);

  const thisRouter = window.location.pathname;
  const subitemsLinks = subItems?.map(({ href }) => href) || [];
  const isActive = subitemsLinks.includes(thisRouter) || thisRouter === href;
  const border = "1px solid #E8EAED";
  const permitedRoutes = subItems?.filter((curr) => checkIsVisible(curr, user));

  const fixedTransform = "translateX(calc(100%)) translateY(-30px)";

  const maxLengthMenuSize =
    menuArray
      .reduce((acc, { subItems }) => {
        return subItems ? [...acc, ...subItems.map(({ name }) => name)] : acc;
      }, [] as string[])
      .reduce((acc, name) => {
        if (acc < name.length) return name.length;
        return acc;
      }, 0) * 12.18;

  const onClick: MouseEventHandler<HTMLDivElement> = (e) => {
    if (href) {
      if (menuErrorMessage) {
        openModalError(menuErrorMessage);
      } else {
        router.push(href);
      }
    } else if (permitedRoutes?.length) {
      if (menuErrorMessage) {
        openModalError(menuErrorMessage);
      } else {
        router.push(permitedRoutes[0].href);
      }
    }
  };

  const onOpen = () => {
    isOpen = true;
    setIsOpen(true);
    setIsInner(true);
  };
  const onClose = () => {
    isOpen = false;
    setIsOpen(false);
  };

  const onOpenAnimation = () => {
    const target = subitemRef.current;
    if (target) {
      const from = Number(getComputedStyle(target).opacity);
      const to = 1;
      // if (target) target.style.visibility = "visible";
      AnimateElement(100, [from, to], (progress) => {
        fade({ target, progress });
        scale({
          target,
          progress,
          multiTransform: fixedTransform,
        });
      });
    }
  };

  const onCloseAnimation = () => {
    const target = subitemRef.current;
    if (target) {
      const from = Number(getComputedStyle(target).opacity);
      const to = 0;
      AnimateElement(
        100,
        [from, to],
        (progress) => {
          fade({ target, progress });
          scale({
            target,
            progress,
            multiTransform: fixedTransform,
          });
        },
        () => {
          if (!isOpen) setIsInner(false);
        }
      );
    }
  };

  useEffect(() => {
    if (isOpen) onOpenAnimation();
    else onCloseAnimation();
  }, [isOpen]);

  return (
    <Center
      ref={menuItemRef}
      w="90px"
      h="67px"
      borderRadius="10px"
      color={isActive ? "#B21F17" : "#4E5762"}
      _hover={isActive ? undefined : { bg: "#F2F2F2" }}
      flexDir="column"
      bg={isActive ? "#FFF3F3" : "transparent"}
      cursor="pointer"
      pos="relative"
      onClick={onClick}
      opacity={!!menuErrorMessage ? "0.5" : undefined}
      onMouseEnter={(e) => {
        if (permitedRoutes?.length) onOpen();
      }}
      onMouseLeave={(e) => {
        if (permitedRoutes?.length) onClose();
      }}
    >
      <Box mb="8px">{icon}</Box>
      <Text fontWeight="medium" fontSize="12px">
        {name}
      </Text>
      {isInner ? (
        <Box
          ref={subitemRef}
          pl="4px"
          top="0px"
          right="0px"
          pos="absolute"
          transform={fixedTransform}
          opacity={0}
        >
          <Flex
            width={`${maxLengthMenuSize}px`}
            bg="#fff"
            p="10px 5px"
            flexDir="column"
            border={border}
            borderRadius="4px"
            color={"#4E5762"}
          >
            <Text
              mb="10px"
              px="8px"
              pr="15px"
              fontSize="18px"
              fontWeight="semibold"
            >
              {name}
            </Text>

            {permitedRoutes?.map(({ name, href }, i, arr) => {
              const isActive = href === thisRouter;
              const isLast = i + 1 === arr.length;
              return (
                <Flex
                  as="a"
                  p="4px 8px"
                  alignItems="center"
                  w="100%"
                  bg={isActive ? "#FFF3F3" : "transparent"}
                  borderRadius="4px"
                  mb={isLast ? "" : "5px"}
                  color={isActive ? "#B21F17" : "#4E5762"}
                  _hover={isActive ? undefined : { bg: "#F2F2F2" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!e.metaKey) {
                      e.preventDefault();
                      router.push(href);
                    }
                  }}
                  href={href}
                >
                  <ChevronRightIcon
                    w="17px"
                    h="17px"
                    display="block"
                    mr="5px"
                  />
                  <Text whiteSpace="nowrap" fontSize="14px" fontWeight="medium">
                    {name}
                  </Text>
                </Flex>
              );
            })}
          </Flex>
        </Box>
      ) : null}
    </Center>
  );
}
