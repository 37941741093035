import { Grid } from "@chakra-ui/react";
import { Accordion } from "../../accordion";
import AttendanceInput from "../../attendance-field/attendance-input";
import {
  AtendimentoBeneficioProps,
  Attendance,
} from "components/atendimentos-components/atendimento-form/types";
import { Dispatch, SetStateAction, useCallback } from "react";
import { useAttendanceContext } from "contexts/attendance-context";
import AttendanceDropdown from "../../attendance-field/attendance-dropdown";
import { calcBirthday, onlyNumberValidMask } from "utils/string-formats";
import {
  UFsBrasil,
  dropdownOrgaosEmissoresDocDigitacaoFGTSFacta,
  maskDateFormat,
} from "components/atendimentos-components/atendimento-form/step-form/tabs-components/digitacao-fields/parts/const";
import {
  dropdownEspecies,
  dropdownEstadoCivil,
  dropdownSexoAtendimento,
  dropdownSimNao,
  dropdownSituacaoBeneficio,
  dropdownTipoContaPagamento,
  dropdownTipoPagamento,
} from "components/atendimentos-components/atendimento-form/fields-data";
import { AttendanceTabKey } from "../../attendance-data-form";

export function DadosCadastraisAtendimentoFormPart({
  isLoading,
  setIsLoading,
  telaAtendimento,
}: {
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  onChangeTab: (tabKey: AttendanceTabKey) => void;
  telaAtendimento: boolean;
}) {
  const { formControl, dispatch } = useAttendanceContext();
  const formValues = formControl.values;

  const onChangeInput = useCallback(
    (fieldName: keyof Attendance, subKey?: keyof AtendimentoBeneficioProps) =>
      (value: string) => {
        let objectPart = (formValues[fieldName] as any) || {};
        if (subKey) objectPart[subKey] = value;
        dispatch({
          type: "changeField",
          payload: { fieldName, data: subKey ? objectPart : value },
        });
        dispatch({
          type: "setInvalidField",
          payload: { fieldName, action: "clear" },
        });
      },
    [dispatch, formValues]
  );

  const onChangeDropdown = useCallback(
    (fieldName: keyof Attendance) => (newValue: any) => {
      dispatch({
        type: "changeField",
        payload: { fieldName, data: newValue },
      });
      dispatch({
        type: "setInvalidField",
        payload: { fieldName, action: "clear" },
      });
    },
    []
  );

  const especieName = dropdownEspecies.find(
    (crr) => crr.value === formValues.especieNB
  )?.name;
  const limitDescEspecie = telaAtendimento ? 1000 : 35;
  const especieDesc =
    especieName?.length! > limitDescEspecie
      ? especieName?.slice(0, limitDescEspecie).trim() + "..."
      : especieName;
  //
  const limitDescOrgaoEmissorDoc = telaAtendimento ? 1000 : 15;
  const orgaoEmissorDocName = dropdownOrgaosEmissoresDocDigitacaoFGTSFacta.find(
    (crr) => crr.value === formValues.orgaoEmissorDoc
  )?.name;
  const orgaoEmissorDocDesc =
    orgaoEmissorDocName?.length! > limitDescOrgaoEmissorDoc
      ? orgaoEmissorDocName?.slice(0, limitDescOrgaoEmissorDoc).trim() + "..."
      : orgaoEmissorDocName;

  return (
    <>
      <Accordion title="Informações pessoais" containerProps={{ mb: "20px" }}>
        <Grid templateColumns="1fr 1fr" columnGap="20px" rowGap="40px">
          <AttendanceInput
            title="Nome"
            // placeholder="Digite o nome"
            onChange={onChangeInput("nome")}
            fieldName="nome"
            value={formValues.nome}
          />
          <AttendanceInput
            title="Nome mãe"
            // placeholder="Digite o nome da mãe"
            onChange={onChangeInput("nomeMae")}
            fieldName="nomeMae"
            value={formValues.nomeMae}
          />
          <AttendanceInput
            title="Nome pai"
            // placeholder="Digite o nome do pai"
            onChange={onChangeInput("nomePai")}
            fieldName="nomePai"
            value={formValues.nomePai}
          />
          <AttendanceInput
            title="CPF"
            // placeholder="Digite o cpf"
            onChange={onChangeInput("cpf")}
            fieldName="cpf"
            value={formValues.cpf}
          />
          <AttendanceInput
            title="Nascimento"
            // placeholder="Digite a data do nascimento"
            onChange={onChangeInput("dataNascimento")}
            fieldName="dataNascimento"
            value={formValues.dataNascimento}
            mask={maskDateFormat}
          />
          <AttendanceInput
            title="Idade"
            // placeholder="Digite a idade"
            fieldName="dataNascimento"
            value={
              formValues.dataNascimento
                ? calcBirthday(formValues.dataNascimento)
                : ""
            }
          />
          <AttendanceDropdown
            title="Sexo"
            onChange={onChangeDropdown("sexo")}
            fieldName="sexo"
            options={dropdownSexoAtendimento}
            value={formValues.sexo}
          />
          <AttendanceDropdown
            title="Estado civil"
            onChange={onChangeDropdown("estadoCivil")}
            fieldName="estadoCivil"
            options={dropdownEstadoCivil}
            value={formValues.estadoCivil}
          />
          <AttendanceDropdown
            title="UF naturalidade"
            onChange={onChangeDropdown("ufNaturalidade")}
            fieldName="ufNaturalidade"
            value={formValues.ufNaturalidade}
            options={UFsBrasil}
          />
          <AttendanceInput
            title="Naturalidade"
            // placeholder="Digite a naturalidade"
            onChange={onChangeInput("naturalidade")}
            fieldName="naturalidade"
            value={formValues.naturalidade}
          />
          <AttendanceInput
            title="Documento"
            // placeholder="Digite o RG"
            onChange={onChangeInput("documento")}
            fieldName="documento"
            value={formValues.documento}
          />
          <AttendanceDropdown
            title="Orgão emissor documento"
            onChange={onChangeDropdown("orgaoEmissorDoc")}
            fieldName="orgaoEmissorDoc"
            value={formValues.orgaoEmissorDoc}
            options={dropdownOrgaosEmissoresDocDigitacaoFGTSFacta}
            dropdownProps={{ children: orgaoEmissorDocDesc }}
          />
          <AttendanceDropdown
            title="UF emissão documento"
            onChange={onChangeDropdown("ufEmissaoDoc")}
            fieldName="ufEmissaoDoc"
            value={formValues.ufEmissaoDoc}
            options={UFsBrasil}
          />
          <AttendanceInput
            title="Data emissão documento"
            onChange={onChangeInput("dataEmissaoDoc")}
            fieldName="dataEmissaoDoc"
            value={formValues.dataEmissaoDoc}
            mask={maskDateFormat}
          />
        </Grid>
      </Accordion>
      <Accordion
        isVisible={() => formValues.convenio !== "ANTECIPACAO_FGTS"}
        title="Benefício"
        containerProps={{ mb: "20px" }}
      >
        <Grid
          templateColumns={telaAtendimento ? "1fr 1fr" : "1fr"}
          columnGap="20px"
          rowGap="40px"
        >
          <AttendanceInput
            title="Número do benefício"
            // placeholder="Digite o número do benefício"
            onChange={onChangeInput("nb")}
            fieldName="nb"
            value={formValues.nb}
            inputProps={{ type: "number" }}
          />
          <AttendanceDropdown
            title="Espécie do benefício"
            options={dropdownEspecies}
            onChange={onChangeDropdown("especieNB")}
            fieldName="especieNB"
            value={formValues.especieNB}
            isVisible={() => formValues.convenio !== "GOV"}
            dropdownProps={{
              children: especieDesc,
            }}
          />
          <AttendanceInput
            title="Lotação"
            onChange={onChangeInput("atendimentoBeneficio", "lotacao")}
            fieldName="atendimentoBeneficio.lotacao"
            value={formValues.atendimentoBeneficio?.lotacao}
            isVisible={() => formValues.convenio === "GOV"}
          />
          <AttendanceInput
            title="Tipo de vínculo"
            onChange={onChangeInput("atendimentoBeneficio", "tipoVinculo")}
            fieldName="atendimentoBeneficio.tipoDeVinculo"
            value={formValues.atendimentoBeneficio?.tipoVinculo}
            isVisible={() => formValues.convenio === "GOV"}
          />
          <AttendanceInput
            title="Orgão"
            onChange={onChangeInput("atendimentoBeneficio", "orgao")}
            fieldName="atendimentoBeneficio.orgao"
            value={formValues.atendimentoBeneficio?.orgao}
            isVisible={() => formValues.convenio === "GOV"}
          />
          <AttendanceDropdown
            title="UF do benefício"
            options={UFsBrasil}
            onChange={onChangeDropdown("ufNB")}
            fieldName="ufNB"
            value={formValues.ufNB}
          />
          <AttendanceDropdown
            title="Situação do benefício"
            onChange={onChangeDropdown("situacaoNB")}
            fieldName="situacaoNB"
            options={dropdownSituacaoBeneficio}
            value={formValues.situacaoNB}
          />
          <AttendanceInput
            title="DDB"
            // placeholder="Digite o ddb"
            onChange={onChangeInput("ddb")}
            fieldName="ddb"
            value={formValues.ddb}
          />
          <AttendanceInput
            title="Valor salário (MR)"
            onChange={onChangeDropdown("valorSalario")}
            fieldName="valorSalario"
            value={formValues.valorSalario}
            inputProps={{ type: "number" }}
            mask={onlyNumberValidMask}
            // placeholder="Digite o valor salário"
          />
          <AttendanceDropdown
            title="Pensão alimentícia"
            onChange={onChangeDropdown("pensaoAlimenticia")}
            fieldName="pensaoAlimenticia"
            options={dropdownSimNao}
            value={formValues.pensaoAlimenticia}
            isVisible={() => formValues.convenio !== "ANTECIPACAO_FGTS"}
          />
          <AttendanceDropdown
            title="Possui representante"
            onChange={onChangeDropdown("possuiRepresentante")}
            fieldName="possuiRepresentante"
            options={dropdownSimNao}
            value={formValues.possuiRepresentante}
            isVisible={() => formValues.convenio !== "ANTECIPACAO_FGTS"}
          />
          <AttendanceInput
            title="CPF representante"
            onChange={onChangeInput("cpfRepresentanteLegal")}
            fieldName="cpfRepresentanteLegal"
            value={formValues.cpfRepresentanteLegal}
            isVisible={() =>
              !!formValues.possuiRepresentante &&
              formValues.convenio !== "ANTECIPACAO_FGTS"
            }
          />
          <AttendanceInput
            title="Nome representante"
            onChange={onChangeInput("nomeRepresentanteLegal")}
            fieldName="nomeRepresentanteLegal"
            value={formValues.nomeRepresentanteLegal}
            isVisible={() => !!formValues.possuiRepresentante}
          />
          <AttendanceDropdown
            title="Bloqueado empréstimo"
            onChange={onChangeDropdown("bloqueadoEmprestimo")}
            fieldName="bloqueadoEmprestimo"
            options={dropdownSimNao}
            value={formValues.bloqueadoEmprestimo}
            isVisible={() => formValues.convenio !== "ANTECIPACAO_FGTS"}
          />
          <AttendanceDropdown
            title="Elegível empréstimo"
            onChange={onChangeDropdown("elegivelEmprestimo")}
            fieldName="elegivelEmprestimo"
            options={dropdownSimNao}
            value={formValues.elegivelEmprestimo}
            isVisible={() => formValues.convenio !== "ANTECIPACAO_FGTS"}
          />
        </Grid>
      </Accordion>
      <Accordion title="Contato" containerProps={{ mb: "20px" }}>
        <Grid templateColumns="1fr 1fr" columnGap="20px" rowGap="40px">
          <AttendanceInput
            title="Telefone 1"
            // placeholder="Digite o telefone residencial"
            onChange={onChangeInput("telefoneResidencial")}
            fieldName="telefoneResidencial"
            value={formValues.telefoneResidencial}
            inputProps={{ type: "number" }}
          />
          <AttendanceInput
            title="Telefone 2"
            // placeholder="Digite o telefone comercial"
            onChange={onChangeInput("telefoneComercial")}
            fieldName="telefoneComercial"
            value={formValues.telefoneComercial}
            inputProps={{ type: "number" }}
          />
          <AttendanceInput
            title="Telefone 3"
            // placeholder="Digite data do nascimento"
            onChange={onChangeInput("telefoneCelular")}
            fieldName="telefoneCelular"
            value={formValues.telefoneCelular}
            inputProps={{ type: "number" }}
          />
        </Grid>
      </Accordion>
      <Accordion title="Endereço" containerProps={{ mb: "20px" }}>
        <Grid templateColumns="1fr 1fr" columnGap="20px" rowGap="40px">
          <AttendanceInput
            title="CEP"
            value={formValues.cep}
            // placeholder="Digite o CEP"
            onChange={onChangeInput("cep")}
            fieldName="cep"
          />
          <AttendanceDropdown
            title="UF"
            options={UFsBrasil}
            onChange={onChangeDropdown("uf")}
            fieldName="uf"
            value={formValues.uf}
          />
          <AttendanceInput
            title="Cidade"
            // placeholder="Digite a cidade"
            onChange={onChangeDropdown("cidade")}
            fieldName="cidade"
            value={formValues.cidade}
          />
          <AttendanceInput
            title="Bairro"
            // placeholder="Digite o bairro"
            onChange={onChangeDropdown("bairro")}
            fieldName="bairro"
            value={formValues.bairro}
          />
          <AttendanceInput
            title="Endereço"
            // placeholder="Digite o endereço"
            onChange={onChangeDropdown("endereco")}
            fieldName="endereco"
            value={formValues.endereco}
          />
          <AttendanceInput
            title="Número"
            // placeholder="Digite o número"
            onChange={onChangeDropdown("numero")}
            fieldName="numero"
            value={formValues.numero}
            inputProps={{ type: "number" }}
          />{" "}
          <AttendanceInput
            title="Complemento"
            // placeholder="Digite o endereço"
            onChange={onChangeDropdown("complemento")}
            fieldName="complemento"
            value={formValues.complemento}
          />
        </Grid>
      </Accordion>
      <Accordion title="Informações bancárias" containerProps={{ mb: "20px" }}>
        <Grid
          templateColumns={telaAtendimento ? "1fr 1fr" : "1fr"}
          columnGap="20px"
          rowGap="40px"
        >
          <AttendanceDropdown
            title="Tipo pagamento"
            onChange={onChangeDropdown("tipoPagamento")}
            fieldName="tipoPagamento"
            value={formValues.tipoPagamento}
            options={dropdownTipoPagamento}
          />
          <AttendanceInput
            title="Código banco pagamento"
            placeholder="Digite o código banco pagamento"
            onChange={onChangeInput("codBancoPagamento")}
            fieldName="codBancoPagamento"
            inputProps={{ type: "number" }}
            mask={onlyNumberValidMask}
            value={formValues.codBancoPagamento}
          />
          <AttendanceDropdown
            title="Tipo conta pagamento"
            onChange={onChangeDropdown("tipoContaPagamento")}
            fieldName="tipoContaPagamento"
            value={formValues.tipoContaPagamento}
            options={dropdownTipoContaPagamento}
          />
          <Grid templateColumns="5fr 3fr" gap="10px">
            <AttendanceInput
              title="Agência pagamento"
              placeholder="Agência pagamento"
              onChange={onChangeInput("agenciaPagamento")}
              fieldName="agenciaPagamento"
              value={formValues.agenciaPagamento}
            />
            <AttendanceInput
              title="DV (agência)"
              placeholder="Dígito verif."
              onChange={onChangeInput("dvAgenciaPagamento")}
              fieldName="dvAgenciaPagamento"
              inputProps={{ type: "number" }}
              mask={onlyNumberValidMask}
              value={formValues.dvAgenciaPagamento}
            />
          </Grid>
          <Grid templateColumns="5fr 3fr" gap="10px">
            <AttendanceInput
              title="Conta pagamento"
              placeholder="Digite conta pagamento"
              onChange={onChangeInput("contaPagamento")}
              fieldName="contaPagamento"
              value={formValues.contaPagamento}
            />
            <AttendanceInput
              title="DV (conta)"
              placeholder="Dígito verif."
              onChange={onChangeInput("dvContaPagamento")}
              fieldName="dvContaPagamento"
              inputProps={{ type: "number" }}
              mask={onlyNumberValidMask}
              value={formValues.dvContaPagamento}
            />
          </Grid>
        </Grid>
      </Accordion>
    </>
  );
}
