import { Center, Flex, Grid, Text } from "@chakra-ui/react";
import {
  ContratoRCCInss,
  ContratoRMCInss,
} from "components/atendimentos-components/atendimento-form/types";
import { colorsMap } from "components/atendimentos-components/pagina-atendimento/attendance-field/attendance-input";
import { banks } from "components/mailing-components/mailing-modal-filter/mailing-filter/consts";
import { useAttendanceContext } from "contexts/attendance-context";
import { bancosSimulacaoSaque, SimulacaoSaque } from "./simulacao-saque";
import { parseCodBancoToEnumBanco } from "components/atendimentos-components/atendimento-form/contracts-table/consts";
import { AttendanceTabKey } from "components/atendimentos-components/pagina-atendimento/attendance-data-form";

type Emprestimo = ContratoRCCInss & ContratoRMCInss;

export function CardEmprestimoCartao({
  emprestimo,
  field,
  telaAtendimento,
  onChangeTab,
}: {
  emprestimo: Emprestimo;
  field: "contratosRCCInss" | "contratosRMCInss";
  telaAtendimento: boolean;
  onChangeTab: (tabKey: AttendanceTabKey) => void;
}) {
  const { formControl, dispatch } = useAttendanceContext();
  const contratosList = formControl.values[field] || [];
  const {
    contratosRMCInss: contratosCartaoRMCInss,
    contratosRCCInss: contratosCartaoRCCInss,
  } = formControl.values;

  const parseTagText = new Map([
    ["API_BANCO_CARTAO", "CARTÃO ATUALIZADO"],
    ["DATAFAST", "REFIN BANCO"],
    ["CONSULTA OFFLINE", ""],
    ["EXTRATO_ONLINE", "EXTR. ONLINE"],
    ["IN100", "IN100"],
  ]);

  const setEmprestimoCartao = (
    emprestimo: ContratoRCCInss | ContratoRMCInss
  ) => {
    dispatch({
      type: "changeField",
      payload: {
        fieldName: field,
        data: contratosList?.map((currEmprestimo) => {
          if (currEmprestimo.id === emprestimo.id) return emprestimo;
          return currEmprestimo;
        }),
      },
    });
  };

  const bankDesc = banks.find(
    (curr) => Number(curr.value) === Number(emprestimo.codBanco)
  )?.name;

  return (
    <Flex
      key={emprestimo.contrato}
      flexDir="column"
      border="1px solid #E8EAED"
      bg="#F5F7F9"
      p="10px 15px"
      borderRadius="5px"
    >
      {emprestimo.origem ? (
        <Flex w="100%" mb="12px">
          <Center
            p="2px 5px"
            fontSize="11"
            borderRadius="5px"
            bg={colorsMap.get(emprestimo.origem)}
            color="#fff"
            ml="auto"
            mb="8px"
          >
            {parseTagText.get(emprestimo.origem)}
          </Center>{" "}
        </Flex>
      ) : null}

      <Grid
        templateColumns={telaAtendimento ? "1fr 1fr 1fr 1fr" : "1fr 1fr"}
        gap="10px"
      >
        <Text>
          <b>Banco:</b>
          <br />
          {emprestimo.codBanco ? (
            <Text as="span" whiteSpace="nowrap">
              {`${emprestimo.codBanco}${bankDesc ? " - " + bankDesc : ""}`}
            </Text>
          ) : null}
        </Text>
        <Text>
          <b>Contrato:</b> <br />
          {emprestimo.contrato}
        </Text>
        <Text>
          <b>Limite cartão:</b> <br />
          R$ {emprestimo.limiteCartao ? emprestimo.limiteCartao : "--"}
        </Text>
        <Text>
          <b>Início Contrato:</b> <br />
          {emprestimo.dataInicioContrato}
        </Text>
        <Text>
          <b>Valor Reservado:</b> <br />
          <Text as="span"> R$ {emprestimo.valorReservado ?? "--"}</Text>
        </Text>
        <Text>
          <b>Valor Saque Máximo:</b> <br />
          <Text as="span"> R$ {emprestimo.valorSaqueMaximo ?? "--"}</Text>
        </Text>
        <Text>
          <b>Valor Saque Minimo:</b> <br />
          <Text as="span"> R$ {emprestimo.valorSaqueMinimo ?? "--"}</Text>
        </Text>
        <Text>
          <b>Situação:</b> <br />
          <Text
            as="span"
            p="4px"
            borderRadius="5px"
            color={emprestimo.situacao === "Ativo" ? "#00cc00" : ""}
          >
            {emprestimo.situacao}
          </Text>
        </Text>
        {field === "contratosRMCInss" && Number(emprestimo.codBanco) === 318 ? (
          <>
            <Text>
              <b>Observação:</b> <br />
              {emprestimo.observacao}
            </Text>
            <Text>
              <b>Mensagem Impedimento:</b> <br />
              <Text as="span">{emprestimo.mensagemImpedimento}</Text>
            </Text>
            <Text>
              <b>Modalidade Saque:</b> <br />
              <Text as="span">{emprestimo.modalidadeSaque}</Text>
            </Text>
            <Text>
              <b>Número Adesão:</b> <br />
              <Text as="span">{emprestimo.numeroAdesao}</Text>
            </Text>
            <Text>
              <b>Número Cartão:</b> <br />
              <Text as="span">{emprestimo.numeroCartao}</Text>
            </Text>
            <Text>
              <b>Número Conta Interna:</b> <br />
              <Text as="span">{emprestimo.numeroContaInterna}</Text>
            </Text>
          </>
        ) : null}
      </Grid>

      {bancosSimulacaoSaque.find(
        ({ value }) =>
          parseCodBancoToEnumBanco(
            field === "contratosRMCInss"
              ? contratosCartaoRMCInss?.[0].codBanco
              : contratosCartaoRCCInss?.[0].codBanco
          ) === value
      ) ? (
        <>
          <Text fontSize="16px" fontWeight="semibold" mt="20px" mb="15px">
            Simulação Saque Complementar
          </Text>

          <SimulacaoSaque
            telaAtendimento={telaAtendimento}
            tipoSaque={field === "contratosRMCInss" ? "SAQUE_RMC" : "SAQUE_RCC"}
            onChangeTab={onChangeTab}
          />
        </>
      ) : null}
    </Flex>
  );
}
