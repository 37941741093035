import { Box, Button, Flex, Grid, useBreakpointValue } from "@chakra-ui/react";
import { DropdownField } from "components/dropdown-field";
import { InputField } from "components/input-field";
import { Toast } from "components/toast";
import { useEffect, useState } from "react";
import { dropdownPrazo } from "../simulacao-fields/utils";
import { useAttendanceContext } from "contexts/attendance-context";
import { TabelaProps } from "../simulacao-fields/types";
import { ModalSimulacaoApi } from "../simulacao-fields/requests";
import apiDigitacao from "api/api-digitacao";
import { currencyMaskFormat } from "utils/string-formats";
import {
  getBodySimulacaoCartao,
  parseSimulacaoCartaoPOST,
  parseSimulacaoCartaoGET,
  SimulacaoCartaoGETResponse,
  SimulacaoCartaoPOSTResponse,
  SimulacaoCartaoPOSTBody,
} from "./utils";
import AttendanceInput from "components/atendimentos-components/pagina-atendimento/attendance-field/attendance-input";
import { openModalDigitacaoCartao } from "../modal-digitacao-cartao";

export interface SimulacaoCartaoProps {
  banco?: string;
  numberInstallments?: string;
  codeTable?: string;
  valueInstallment?: number;
  valorLimiteCartao?: number;
  valorSaquePrevisto?: number;
  observacao?: string;
  status?: SimulacaoCartaoGETResponse["status"];
  prevSimulacao?: SimulacaoCartaoPOSTBody;
}

export function SimulacaoCartao({
  telaAtendimento,
  tipoCartao,
}: {
  telaAtendimento: boolean;
  tipoCartao: "RCC" | "RMC";
}) {
  let [simulacaoCartao, setSimulacaoCartao] = useState<SimulacaoCartaoProps>(
    {}
  );
  const [errors, setErrors] = useState<{ [k: string]: string }>({});
  const [tabelasList, setTabelasList] = useState<
    { name: string; value: string; completedTable: TabelaProps }[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSimulacao, setIsLoadingSimulacao] = useState(false);
  const { formControl, dispatch } = useAttendanceContext();
  const attendance = formControl.values;

  const selectedTabela = tabelasList.find((curr) => {
    return [simulacaoCartao.codeTable].includes(curr.completedTable.codigo);
  })?.completedTable;

  const modalApi = ModalSimulacaoApi();

  const onChangeSimulacaoCartao = (
    k: keyof SimulacaoCartaoProps,
    value: any
  ) => {
    simulacaoCartao = { ...simulacaoCartao, [k]: value };
    setSimulacaoCartao(simulacaoCartao);
    setErrors((errors) => {
      delete errors[k];
      return { ...errors };
    });
  };

  const simulacaoPOST = async (body: SimulacaoCartaoPOSTBody) => {
    return await apiDigitacao.post<SimulacaoCartaoPOSTResponse>(
      `/api/simulacoes/inss/atendimento/${attendance.id}/${tipoCartao}/novo/simular`,
      body
    );
  };

  const simulacaoGET = async () => {
    return await apiDigitacao.get<SimulacaoCartaoGETResponse>(
      `/api/simulacoes/inss/atendimento/${attendance.id}/${tipoCartao}/novo/consultar`
    );
  };

  const handleFinalDigitacao = (data: SimulacaoCartaoPOSTResponse) => {
    if (data.status === "FINISHED") {
      Toast({ title: "Simulação concluída", status: "success" });
    } else if (data.status === "PENDENT") {
      Toast({ title: "Simulação em andamento", status: "info" });
    } else if (data.status === "SYSTEM_ERROR") {
      Toast({
        title: "Erro ao simular",
        description: data.observacao,
        status: "error",
      });
    }
  };

  const handleSubmitSimulacao = async () => {
    const { isValid, body, errors } = getBodySimulacaoCartao(
      simulacaoCartao,
      attendance,
      dispatch,
      tipoCartao
    );

    if (!isValid) {
      setErrors(errors);
      console.log("Errors => ", errors);
      return;
    }

    setIsLoadingSimulacao(true);
    try {
      const { data } = await simulacaoPOST(body);

      setSimulacaoCartao(parseSimulacaoCartaoPOST(data));

      handleFinalDigitacao(data);
    } catch (e: any) {
      const errorMessage = e?.response?.data?.msgInterface;
      if (errorMessage !== "Simulação não encontrada") {
        Toast({ title: errorMessage, status: "error" });
      }
    } finally {
      setIsLoadingSimulacao(false);
    }
  };

  const getSimulacaoResult = async () => {
    setIsLoadingSimulacao(true);
    try {
      const { data } = await simulacaoGET();

      setSimulacaoCartao(parseSimulacaoCartaoGET(data));
    } catch (e: any) {
      const errorMessage = e?.response?.data?.msgInterface;

      if (errorMessage !== "Simulação não encontrada") {
        Toast({
          title: "Erro ao carregar simulações anteriores",
          status: "error",
        });
      }
    } finally {
      setIsLoadingSimulacao(false);
    }
  };

  useEffect(() => {
    getSimulacaoResult();
  }, []);

  useEffect(() => {
    if (simulacaoCartao.banco) {
      modalApi.getTabelasDigitacao(
        simulacaoCartao.banco,
        attendance.convenio!,
        setTabelasList,
        setIsLoading
      );
    }
  }, [simulacaoCartao.banco]);

  const dinamicLimit = useBreakpointValue({ base: 30, "2xl": 40 });

  const limitDesc = telaAtendimento ? 85 : dinamicLimit!;

  const tabelaDesc =
    selectedTabela?.descricao?.length! > limitDesc
      ? selectedTabela?.descricao?.slice(0, limitDesc) + "..."
      : selectedTabela?.descricao;

  return (
    <Grid templateColumns="1fr 1fr" columnGap="20px" rowGap="40px">
      <Box>
        <InputField
          title="Valor parcela"
          inputProps={{ variant: "outline-custom" }}
          value={currencyMaskFormat(simulacaoCartao.valueInstallment).value}
          onChange={(e) => {
            onChangeSimulacaoCartao(
              "valueInstallment",
              currencyMaskFormat(e.target.value).raw
            );
            setErrors((errors) => {
              delete errors["margin"];
              return { ...errors };
            });
          }}
          errorMessage={errors["valueInstallment"] || errors["margin"]}
        />
      </Box>
      <Box>
        <DropdownField
          title="Banco"
          dropdownProps={{ variant: "outline-custom", w: "100%" }}
          value={simulacaoCartao.banco}
          onChange={(value) => {
            onChangeSimulacaoCartao("banco", value);
            onChangeSimulacaoCartao("codeTable", undefined);
            onChangeSimulacaoCartao("valorLimiteCartao", undefined);
            onChangeSimulacaoCartao("observacao", undefined);
          }}
          options={bancosSimulacaoCartao}
          errorMessage={errors["banco"]}
        />
      </Box>
      <Box gridArea="auto / span 2">
        <DropdownField
          title="Prazo"
          dropdownProps={{ variant: "outline-custom", w: "100%" }}
          value={simulacaoCartao.numberInstallments}
          onChange={(value) => {
            onChangeSimulacaoCartao("numberInstallments", value);
            onChangeSimulacaoCartao("valorLimiteCartao", undefined);
            onChangeSimulacaoCartao("observacao", undefined);
          }}
          options={dropdownPrazo.map(({ name, value }) => ({
            name,
            value: Number(value),
          }))}
          errorMessage={errors["numberInstallments"]}
        />
      </Box>
      <Box gridArea="auto / span 2">
        <DropdownField
          title="Tabelas"
          dropdownProps={{
            w: "100%",
            variant: "outline-custom",
            children: tabelaDesc,
          }}
          value={simulacaoCartao.codeTable}
          onChange={(value) => {
            onChangeSimulacaoCartao("codeTable", value);
            onChangeSimulacaoCartao("valorLimiteCartao", undefined);
            onChangeSimulacaoCartao("valorSaquePrevisto", undefined);
            onChangeSimulacaoCartao("observacao", undefined);
          }}
          options={tabelasList}
          errorMessage={errors["codeTable"]}
          isLoading={isLoading}
        />
      </Box>

      <Box>
        <AttendanceInput
          title="Valor limite cartão"
          inputProps={{ variant: "outline-custom" }}
          value={currencyMaskFormat(simulacaoCartao.valorLimiteCartao).value}
          errorMessage={errors["valorLimiteCartao"]}
          customTag={simulacaoCartao.valorLimiteCartao ? "SIMULADO" : undefined}
          isReadyOnly
        />
      </Box>

      <Box>
        <AttendanceInput
          title="Saque previsto"
          inputProps={{ variant: "outline-custom" }}
          value={currencyMaskFormat(simulacaoCartao.valorSaquePrevisto).value}
          errorMessage={errors["valorSaquePrevisto"]}
          customTag={
            simulacaoCartao.valorSaquePrevisto ? "SIMULADO" : undefined
          }
          isReadyOnly
        />
      </Box>

      <Box gridArea="auto / span 2">
        <AttendanceInput
          title="Observação"
          inputProps={{
            color: "red.500",
            as: "textarea",
            height: "60px",
            minH: "60px",
            resize: "none",
            variant: "outline-custom",
          }}
          value={simulacaoCartao.observacao}
          customTag={simulacaoCartao.observacao ? "SIMULADO" : undefined}
          isReadyOnly
        />
      </Box>

      <Box />
      <Flex justifyContent="flex-end">
        <Button
          mr="8px"
          onClick={() => {
            if (formControl.pendingChanges) {
              return Toast({ title: "Salve as alterações do atendimento" });
            }

            openModalDigitacaoCartao({
              simulacao: simulacaoCartao,
              modalConfig: { allDisabled: false },
              digitacaoFields: { operacao: tipoCartao },
            });
          }}
          isDisabled={simulacaoCartao.status !== "FINISHED"}
        >
          Digitar
        </Button>{" "}
        <Button
          mr="8px"
          onClick={() => {
            if (simulacaoCartao.status === "PENDENT") {
              getSimulacaoResult();
            } else {
              handleSubmitSimulacao();
            }
          }}
          isLoading={isLoadingSimulacao}
        >
          {simulacaoCartao.status === "PENDENT" ? "Atualizar" : "Simular"}
        </Button>{" "}
      </Flex>
    </Grid>
  );
}

const bancosSimulacaoCartao = [
  { name: "Bmg", value: "BMG" },
  // { name: "Master", value: "MASTER" },
  { name: "Pan", value: "PAN" },
];
