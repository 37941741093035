import { Box, Button, Center, Flex, Spinner } from "@chakra-ui/react";
import { Tabs, Tab } from "components/tabs";
import { useEffect } from "react";
import { DadosCadastraisAtendimentoFormPart } from "./parts/dados-cadastrais";
import { ContratosAtendimentoFormPart } from "./parts/contratos";
import { MargensAtendimentoFormPart } from "./parts/margens";
import { useAttendanceContext } from "contexts/attendance-context";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { SimulacoesAtendimentoFormPart } from "./parts/simulacoes/simulacoes";
import { use3cPlusContext } from "contexts/Context3cPlus";
import { useApplicationContext } from "contexts/ApplicationContext";
import { ModalTabulacao3cPlus } from "components/atendimentos-components/modal-tabulacao-3cplus";
import { DigitacaoAtendimentoFormPart } from "./parts/digitacao";
import { ChatIcon } from "components/vectors/chat-icon";
import { ModalVerMsgSMS, openModalVerMsgSMS } from "./modal-ver-msg-sms";
import { Attendance } from "components/atendimentos-components/atendimento-form/types";
import { DigitadosAtendimentoPart } from "./parts/digitacao/digitados-part";
import { SimulacoesColomboAtendimentoFormPart } from "./parts/simulacoes/simulacoes-colombo";
import { ModalVerMsgUra, openModalVerMsgUra } from "./modal-ver-msg-ura";
import { SeletorAbasAtendimento } from "./menu-abas-atendimento";
import { AttendanceTabKey } from "../attendance-data-form";
import { calcTelaChatsHeight } from "components/atendimentos-components/whatsapp-attendances";
import { headerTelaChatsHeight } from "../attendance-data-form/header-data-form";
import { updateAtendimento } from "./parts/utils";

export const attTabsHeight = "57px";
const marginTop = 16;

export const PaginedForm = ({
  setIsDisabledBackButton,
  telaAtendimento,
  activeTab,
  setActiveTab,
  tabs,
}: {
  setIsDisabledBackButton: React.Dispatch<React.SetStateAction<boolean>>;
  telaAtendimento: boolean;
  setActiveTab: React.Dispatch<React.SetStateAction<AttendanceTabKey>>;
  activeTab: AttendanceTabKey;
  tabs: Tab[];
}) => {
  const { formControl, dispatch, setTransparentLoading, transparentLoading } =
    useAttendanceContext();
  const { controls3cPlus } = use3cPlusContext();
  const formValues = formControl.values;
  const { user, setMenuErrorMessage } = useApplicationContext();

  useEffect(() => {
    if (controls3cPlus.inCall) {
      setMenuErrorMessage({
        message: "É necessário desligar a ligação e efetuar a tabulação.",
      });
    }
  }, [controls3cPlus.inCall]);

  const onChangeTab = (tabKey: AttendanceTabKey) => {
    setActiveTab(tabKey);
  };

  useEffect(() => {
    if (formControl.values.id && !telaAtendimento) {
      onChangeTab("");
    }
  }, [formControl.values.id]);

  return (
    <>
      <Flex w="100%" flexDir={"column"}>
        {transparentLoading ? (
          <Center
            inset="0"
            pos="absolute"
            zIndex="1"
            borderRadius="5px 0px 0px 5px"
            backdropFilter="blur(8px)"
            children={<Spinner />}
          />
        ) : null}
        {telaAtendimento ? (
          <Tabs
            tabs={tabs}
            activeTab={activeTab || ""}
            onChangeTab={onChangeTab}
            styles={{
              mt: `${marginTop}px`,
              h: attTabsHeight,
              maxW: telaAtendimento
                ? `calc(100vw - 30px - 30px - 100px - 400px)`
                : `calc(calc(calc(100vw - 100px) / 3))`,
              overflow: "auto",
            }}
          />
        ) : activeTab === "" ? (
          <SeletorAbasAtendimento onChangeTab={onChangeTab} tabs={tabs} />
        ) : null}

        <Flex
          flexDir="column"
          p={telaAtendimento ? "25px" : activeTab ? "15px" : "0px 15px"}
          w="100%"
          minH={
            telaAtendimento
              ? `calc(100% - ${marginTop}px - ${attTabsHeight})`
              : activeTab
              ? calcTelaChatsHeight(`- ${headerTelaChatsHeight}`)
              : undefined
          }
        >
          {activeTab === "dados-cadastrais" ? (
            <DadosCadastraisAtendimentoFormPart
              isLoading={transparentLoading}
              setIsLoading={setTransparentLoading}
              onChangeTab={onChangeTab}
              telaAtendimento={telaAtendimento}
            />
          ) : activeTab === "margens" ? (
            <MargensAtendimentoFormPart
              isLoading={transparentLoading}
              setIsLoading={setTransparentLoading}
              onChangeTab={onChangeTab}
              telaAtendimento={telaAtendimento}
            />
          ) : activeTab === "contratos" ? (
            <ContratosAtendimentoFormPart
              isLoading={transparentLoading}
              setIsLoading={setTransparentLoading}
              activeTab={activeTab}
              onChangeTab={onChangeTab}
              telaAtendimento={telaAtendimento}
            />
          ) : activeTab === "simulacao-fgts" ? (
            <SimulacoesAtendimentoFormPart
              onChangeTab={onChangeTab}
              telaAtendimento={telaAtendimento}
            />
          ) : activeTab === "simulacao-fgts-colombo" ? (
            <SimulacoesColomboAtendimentoFormPart
              telaAtendimento={telaAtendimento}
              onChangeTab={onChangeTab}
            />
          ) : activeTab === "digitacao" ? (
            <DigitacaoAtendimentoFormPart
              isLoading={transparentLoading}
              setIsLoading={setTransparentLoading}
              onChangeTab={onChangeTab}
            />
          ) : activeTab === "digitados" ? (
            <DigitadosAtendimentoPart
              isLoading={transparentLoading}
              setIsLoading={setTransparentLoading}
              onChangeTab={onChangeTab}
              telaAtendimento={telaAtendimento}
            />
          ) : null}

          <Box
            display={telaAtendimento ? "flex" : "grid"}
            w="100%"
            mt="auto"
            pt={telaAtendimento ? "20px" : "10px"}
            pb={telaAtendimento ? "" : "10px"}
            justifyContent="space-between"
            gap={telaAtendimento ? undefined : "8px"}
            gridTemplateColumns={telaAtendimento ? undefined : "1fr"}
            pos={telaAtendimento ? undefined : "sticky"}
            bottom={telaAtendimento ? undefined : "0px"}
            bg={telaAtendimento ? undefined : "#fff"}
          >
            <Flex
              alignItems="center"
              gap="10px"
              mr={telaAtendimento ? "8px" : undefined}
              w={telaAtendimento ? undefined : "100%"}
            >
              <Button
                size={telaAtendimento ? "sm" : "xs"}
                w={telaAtendimento ? undefined : "100%"}
                onClick={() => {
                  openModalVerMsgSMS(formValues as Attendance);
                }}
                leftIcon={<ChatIcon color="white" />}
              >
                Ver Respostas SMS
              </Button>
              {user.userData.customerId === 8273 ? (
                <Button
                  size={telaAtendimento ? "sm" : "xs"}
                  onClick={() => {
                    openModalVerMsgUra(formValues as Attendance);
                  }}
                  leftIcon={<ChatIcon color="white" />}
                >
                  Ver Respostas URA
                </Button>
              ) : null}
            </Flex>
            <Box
              alignItems="center"
              display={
                telaAtendimento
                  ? "flex"
                  : formControl.pendingChanges
                  ? "grid"
                  : "none"
              }
              gap={telaAtendimento ? undefined : "8px"}
              w={telaAtendimento ? undefined : "100%"}
              gridTemplateColumns={
                telaAtendimento ? undefined : { base: "1fr", xl: "1fr 1fr" }
              }
              // flexWrap={telaAtendimento ? undefined : "wrap"}
            >
              {formControl.pendingChanges ? (
                <Button
                  ml={telaAtendimento ? "8px" : undefined}
                  leftIcon={<CheckIcon />}
                  onClick={() =>
                    updateAtendimento({
                      setLoading: setTransparentLoading,
                      formControl,
                      dispatch,
                    })
                  }
                  isLoading={transparentLoading}
                  loadingText="Salvando"
                  size={telaAtendimento ? "sm" : "xs"}
                  w={telaAtendimento ? undefined : "100%"}
                >
                  Salvar alterações
                </Button>
              ) : null}
              {formControl.pendingChanges ? (
                <Button
                  ml={telaAtendimento ? "8px" : ""}
                  leftIcon={<CloseIcon w="12px" h="12px" />}
                  onClick={() => dispatch({ type: "discardChanges" })}
                  disabled={transparentLoading}
                  variant="outline"
                  size={telaAtendimento ? "sm" : "xs"}
                  w={telaAtendimento ? "" : "100%"}
                >
                  Descartar alterações
                </Button>
              ) : null}
            </Box>
          </Box>
        </Flex>
        <ModalVerMsgSMS />
        <ModalVerMsgUra />
        <ModalTabulacao3cPlus
          setIsDisabledBackButton={setIsDisabledBackButton}
        />
      </Flex>
    </>
  );
};
