import { Center, Flex, Grid, Spinner } from "@chakra-ui/react";
import { useAttendanceContext } from "contexts/attendance-context";
import { useEffect, useState } from "react";
import { HeaderDataForm } from "./header-data-form";
import { PaginedForm } from "../pagined-form";
import api from "api/api";
import ChatWindow from "components/chat-window";
import { useRouter } from "routes/router-control/use-router";
import {
  consultaFGTS,
  mapConsultaFGTS,
} from "components/atendimentos-components/atendimento-form/functions/consulta";
import { Attendance } from "components/atendimentos-components/atendimento-form/types";
import { isColomboUser } from "utils/is-colombo-user";
import { useApplicationContext } from "contexts/ApplicationContext";
import { PersonIcon } from "components/vectors/menu-icons/person-icon";
import { DocumentIcon3 } from "components/vectors/document-icon";
import { ChartIcon } from "components/vectors/chart-icon";
import { Tab } from "components/tabs";
import { KeyboardIcon } from "components/vectors/keyboard-icon";
import { currentAttendanceForm } from "contexts/attendance-context/actions-reducer";
import { calcTelaChatsHeight } from "components/atendimentos-components/whatsapp-attendances";

export type AttendanceTabKey =
  | "dados-cadastrais"
  | "dados-bancarios"
  | "margens"
  | "contratos"
  | "simulacao-fgts"
  | "simulacao-fgts-colombo"
  | "digitacao"
  | "digitados"
  | "";

export const AttendanceDataForm = ({
  attendanceId,
  init,
  setIsDisabledBackButton,
  telaAtendimento,
}: {
  attendanceId?: string | number | null;
  init: boolean;
  setIsDisabledBackButton: React.Dispatch<React.SetStateAction<boolean>>;
  telaAtendimento: boolean;
}) => {
  const [activeTab, setActiveTab] = useState<AttendanceTabKey>(
    telaAtendimento ? "dados-cadastrais" : ""
  );
  const [isLoading, setIsLoading] = useState(true);
  const { formControl, dispatch, setConsultandoFGTS, setReConsultandoFGTS } =
    useAttendanceContext();
  const { user } = useApplicationContext();
  const formValues = formControl.values;
  const router = useRouter();

  useEffect(() => {
    return () => {
      dispatch({ type: "setInvalidField", payload: { action: "clear" } });
    };
  }, []);

  const tabs: Tab<AttendanceTabKey>[] = [
    {
      name: "Dados cadastrais",
      icon: <PersonIcon width="18px" height="18px" />,
      key: "dados-cadastrais",
      titleProps: { whiteSpace: "nowrap" },
    },
    {
      name: "Margens",
      icon: <ChartIcon />,
      key: "margens",
      isVisible: () => formValues.convenio !== "ANTECIPACAO_FGTS",
    },
    {
      name: "Contratos",
      icon: <DocumentIcon3 />,
      key: "contratos",
      isVisible: () => formValues.convenio !== "ANTECIPACAO_FGTS",
    },
    {
      name: "Simulação FGTS",
      icon: <ChartIcon />,
      key: "simulacao-fgts-colombo",
      isVisible: () =>
        ["ANTECIPACAO_FGTS"].includes(formValues.convenio!) &&
        isColomboUser(user),
    },
    {
      name: "Simulação FGTS",
      icon: <ChartIcon />,
      key: "simulacao-fgts",
      isVisible: () =>
        ["ANTECIPACAO_FGTS"].includes(formValues.convenio!) &&
        !isColomboUser(user),
    },
    {
      name: "Digitação",
      icon: <KeyboardIcon />,
      key: "digitacao",
      isVisible: () =>
        isColomboUser(user) && formValues.convenio === "ANTECIPACAO_FGTS",
    },
    {
      name: "Digitados",
      icon: <KeyboardIcon />,
      key: "digitados",
      isVisible: () =>
        (["ANTECIPACAO_FGTS", "INSS"].includes(formValues.convenio!) &&
          !isColomboUser(user)) ||
        (formValues.convenio === "INSS" && isColomboUser(user)),
    },
  ];

  const getCompletedData = async () => {
    const destination = init
      ? `/atendimentos/${attendanceId}/kanban/start`
      : `/atendimentos/${attendanceId}/kanban/open`;
    setIsLoading(true);
    try {
      const { data } = await api.get(destination);
      dispatch({ type: "initAttendance", payload: data });
      if (isColomboUser(user)) {
        if (data.atendimentoFactaFGTS?.statusSaldo === "PENDING" && data.cpf) {
          consultaFGTS({
            loading: setConsultandoFGTS,
            verificar: () =>
              api.get(`/facta/fgts/consultar-solicitacao-saldo/${data.id}`),
            isEndLoop: (response) => {
              return (
                response.data?.statusSaldo !== "PENDING" ||
                currentAttendanceForm.values.id !== response.data.id
              );
            },
            onFinish: (response) => {
              const formValues = data as Attendance;
              mapConsultaFGTS(response?.data, dispatch, formValues);
            },
          });
        }
        if (
          data.atendimentoFactaFGTS?.statusRecalculo === "PENDING" &&
          data.cpf
        ) {
          consultaFGTS({
            loading: setReConsultandoFGTS,
            verificar: () =>
              api.get(`/facta/fgts/consultar-solicitacao-recalculo/${data.id}`),
            errorMessage: "Erro ao recalcular",
            isEndLoop: (response) =>
              response.data?.statusRecalculo !== "PENDING" ||
              currentAttendanceForm.values.id !== response.data.id,
            onFinish: (response) => {
              const formValues = data as Attendance;
              mapConsultaFGTS(response?.data, dispatch, formValues);
            },
          });
        }
      }
    } catch (e) {
      if (telaAtendimento) router.push("/atendimentos");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCompletedData();
  }, [attendanceId]);

  return (
    <Flex
      flexDir="column"
      w="100%"
      bg="#fff"
      borderRadius="5px"
      minH={
        telaAtendimento
          ? "calc(100vh - 30px - 80px - 36px - 30px - 30px)"
          : calcTelaChatsHeight()
      }
      pos="relative"
    >
      {isLoading ? (
        <Center inset="0" pos="absolute">
          <Spinner />
        </Center>
      ) : (
        <Grid
          templateColumns={{
            base: telaAtendimento ? "calc(100% - 400px) 400px" : "1fr",
            "2xl": telaAtendimento ? "calc(100% - 500px) 500px" : "1fr",
          }}
          h="100%"
          w="100%"
          minH={
            telaAtendimento
              ? "calc(100vh - 30px - 80px - 36px - 30px - 30px)"
              : calcTelaChatsHeight()
          }
        >
          <Grid
            pos="relative"
            maxW={`calc(100vw - 30px - 30px - 100px - 400px)`}
            overflow="auto"
            gridTemplateRows={`auto minmax(0px, 100%)`}
          >
            <HeaderDataForm
              isLoading={isLoading}
              telaAtendimento={telaAtendimento}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              tabs={tabs}
            />
            <PaginedForm
              telaAtendimento={telaAtendimento}
              setIsDisabledBackButton={setIsDisabledBackButton}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              tabs={tabs}
            />
          </Grid>
          {telaAtendimento ? (
            <Flex
              flexDir="column"
              justifyContent="flex-start"
              borderLeft="1px solid #E8EAED"
              p="25px"
            >
              <ChatWindow />
            </Flex>
          ) : null}
        </Grid>
      )}
    </Flex>
  );
};
