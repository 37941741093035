import { Button, Center, Flex, Grid, Text } from "@chakra-ui/react";
import { CustomModal } from "components/custom-modal";
import { useEffect, useState } from "react";
import { makeEvent, useEventListener } from "services/events";
import { UsuarioBanco } from "../simulacao-fields/types";
import { CloseIcon, CopyIcon, Search2Icon } from "@chakra-ui/icons";
import { useAttendanceContext } from "contexts/attendance-context";
import { Attendance } from "components/atendimentos-components/atendimento-form/types";
import { InputField } from "components/input-field";
import {
  currencyMaskFormat,
  maskCEPFormat,
  maskCPFFormat,
} from "utils/string-formats";
import { DropdownField } from "components/dropdown-field";
import {
  CEPProps,
  getCEPData,
  maskDateFormat,
  UFsBrasil,
} from "components/atendimentos-components/atendimento-form/step-form/tabs-components/digitacao-fields/parts/const";
import { ButtonWithLoading } from "components/mailing-components/mailing-table/icon-button-with-loading";
import { Toast } from "components/toast";
import {
  dropdownEspecies,
  dropdownSimNao,
} from "components/atendimentos-components/atendimento-form/fields-data";
import { phoneSeparate } from "../simulacao-fields/utils";
import apiDigitacao from "api/api-digitacao";
import { ModalSimulacaoApi } from "../simulacao-fields/requests";
import { AttendanceTabKey } from "components/atendimentos-components/pagina-atendimento/attendance-data-form";
import { DigitadoRow } from "../../digitacao/digitados-part";

import {
  copyAttendanceDataToDigitacaoCartao,
  DigitacaoCartaoDataProps,
  DigitacaoCartaoProps,
  DigitadosGETDigitacao,
  parseGETDigitacaoCartao,
  setCpfDataSimulacaoCartao,
  tipoContaDigitacaoCartaoOptions,
  validateDigitacaoCartao,
} from "./utils";
import { SimulacaoCartaoProps } from "../simulacao-cartao";

interface ModalDigitacaoParams {
  simulacao: SimulacaoCartaoProps;
  digitacaoFields?: DigitacaoCartaoProps;
  modalConfig: ModalConfigProps;
}

interface ModalConfigProps {
  allDisabled: boolean;
  idDigitacao?: number;
  onUpdate?: (row: DigitadoRow) => void;
}

const initialValueDigitacaoFields: DigitacaoCartaoProps = {
  data: { wantToWithdraw: true },
};

export function ModalDigitacaoCartao({
  onChangeTab,
}: {
  onChangeTab?: (tabKey: AttendanceTabKey) => void;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [simulacao, setSimulacao] = useState<SimulacaoCartaoProps>({});
  const [digitacaoFields, setDigitacaoFields] = useState<DigitacaoCartaoProps>(
    initialValueDigitacaoFields
  );
  const [modalConfig, setModalConfig] = useState<Partial<ModalConfigProps>>({
    allDisabled: false,
  });
  const [errors, setErrors] = useState<{ [k: string]: string }>({});
  // const [, setTabelasList] = useState<
  //   { name: string; value: string; completedTable: TabelaProps }[]
  // >([]);
  const [usuariosBancos, setUsuariosBancos] = useState<
    { name: string; value: string; completedUser: UsuarioBanco }[]
  >([]);
  // const [, setLoadingTabelas] = useState(false);
  const [loadingUsuarios, setLoadingUsuarios] = useState(false);
  const [seguroOptions, setSeguroOptions] = useState<
    {
      name: string;
      value: string;
      optionValue: { secureCode: string; secureType: string };
    }[]
  >([]);
  const [isLoadingSeguros, setIsLoadingSeguros] = useState(false);
  const { formControl } = useAttendanceContext();
  const attendance = formControl.values as Attendance;
  const modalApi = ModalSimulacaoApi();
  const { allDisabled } = modalConfig;
  // const { user } = useApplicationContext();

  const isVisibleUsuarioSelect = [
    "C6",
    "FACTA",
    "BMG",
    "PAN",
    "PRESENCA",
  ].includes(simulacao.banco || "");

  const onOpen = (props: ModalDigitacaoParams) => {
    setIsOpen(true);
    setSimulacao(props.simulacao);
    const idDigitacao = props.modalConfig?.idDigitacao;

    if (props.digitacaoFields) {
      onChangeDigitacao({
        key: null,
        value: { ...initialValueDigitacaoFields, ...props.digitacaoFields },
      });
    }

    if (props.modalConfig) setModalConfig(props.modalConfig);

    if (idDigitacao) loadDigitacaoData(idDigitacao!);
  };

  const onClose = () => {
    setIsOpen(false);
    setErrors({});
    setSimulacao({});
    setDigitacaoFields(initialValueDigitacaoFields);
  };

  const loadDigitacaoData = async (idDigitacao: number) => {
    const url = `/api/digitacoes/inss/formulario?digitacao_id=${idDigitacao}`;
    setIsLoading(true);
    try {
      const { data } = await apiDigitacao.get<DigitadosGETDigitacao>(url);
      setDigitacaoFields((prev) => ({
        ...prev,
        ...parseGETDigitacaoCartao(data),
      }));
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  const onChangeDigitacao = ({
    key,
    value,
  }: {
    key:
      | Exclude<keyof DigitacaoCartaoProps, "data">
      | keyof DigitacaoCartaoDataProps
      | null;
    value: any;
  }) => {
    if (key) {
      if (key === "banco" || key === "operacao") {
        setDigitacaoFields((prevFields) => ({ ...prevFields, [key]: value }));
      } else {
        setDigitacaoFields((prevFields) => {
          const data = prevFields.data || {};
          return { ...prevFields, data: { ...data, [key]: value } };
        });
      }

      setErrors((errors) => {
        delete errors[key];
        return { ...errors };
      });
    } else {
      setDigitacaoFields(value);
      setErrors({});
    }
  };

  const loadCEPData = async (cep: string) => {
    const { error, errorMessage, ...cpfData } = await getCEPData(cep);
    if (error) {
      Toast({ title: errorMessage, status: "error" });
    } else {
      setCpfDataSimulacaoCartao(cpfData as CEPProps, onChangeDigitacao);
    }
  };

  const getSeguroOptions = async () => {
    const operacao = { RCC: "CARTAO_NOVO_RCC", RMC: "CARTAO_NOVO_RMC" }[
      digitacaoFields.operacao!
    ];

    setIsLoadingSeguros(true);
    try {
      const { data } = await apiDigitacao.get<
        { name: string; secureType: string; secureCode: string }[]
      >(`api/digitacoes/inss/bmg/seguro`, { params: { operacao } });

      setSeguroOptions(
        data.map(({ name, secureCode, secureType }) => {
          return {
            name,
            value: secureCode,
            optionValue: { secureCode, secureType },
          };
        })
      );
    } catch (e) {
      Toast({ title: "Erro ao obter opções" });
    } finally {
      setIsLoadingSeguros(false);
    }
  };

  const handleDigitar = async () => {
    if (formControl.pendingChanges) {
      return Toast({ title: "Salve as alterações do atendimento" });
    }

    const { isValid, errors, body } = validateDigitacaoCartao(digitacaoFields);

    console.log("Errors =>", errors);
    console.log("Body =>", body);

    if (!isValid) {
      setErrors(errors);
      Toast({ title: "Revise os campos", status: "error" });
      return;
    }

    setIsLoading(true);
    try {
      const { data } = await apiDigitacao.post(
        `/api/digitacoes/inss/atendimento/${attendance.id}/${digitacaoFields.operacao}/novo/digitar`,
        body
      );
      onClose();
      Toast({
        title:
          simulacao.banco === "BMG"
            ? "Digitação em andamento"
            : "Digitação concluída",
        description: `Veja resultados na aba "Digitados"`,
        status: "success",
        duration: 6000,
      });
      onChangeTab?.("digitados");
    } catch (e: any) {
      const errorMessage =
        e?.response?.data?.msgInterface || e?.response?.data?.message;
      Toast({
        title: "Erro ao digitar",
        description: errorMessage,
        status: "error",
        duration: 60000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (simulacao.banco === "BMG") {
      getSeguroOptions();
    }
    if (simulacao.banco && !allDisabled) {
      if (isVisibleUsuarioSelect) {
        modalApi.getUsuariosBancos(
          simulacao.banco,
          setUsuariosBancos,
          setLoadingUsuarios
        );
      }

      // modalApi.getTabelasDigitacao(
      //   simulacao.banco,
      //   attendance.convenio!,
      //   setTabelasList,
      //   setLoadingTabelas
      // );
    }
  }, [simulacao.banco]);

  useEventListener("openModalDigitacaoCartao", onOpen);

  const modalFooter = (
    <Flex justifyContent="space-between" w="100%" alignItems="center">
      <Flex alignItems="center" mr="auto">
        <DropdownField
          title="Com Saque"
          titleProps={{ mr: "10px", mb: "0px" }}
          onChange={(value) => {
            onChangeDigitacao({ key: "wantToWithdraw", value });
          }}
          value={digitacaoFields.data?.wantToWithdraw}
          options={dropdownSimNao}
          isDisabled={allDisabled}
        />
      </Flex>
      {isVisibleUsuarioSelect ? (
        <Center>
          <Text mr="12px">Usuário digitador banco: </Text>
          <DropdownField
            onChange={(value, option) => {
              const usuarioBanco: UsuarioBanco = option.completedUser;
              if (simulacao.banco === "BMG") {
                const userConsig = {
                  loginConsig: usuarioBanco.username,
                  passConsig: usuarioBanco.password,
                };
                onChangeDigitacao({ key: "userConsig", value: userConsig });
              } else {
                onChangeDigitacao({
                  key: "sellerCode",
                  value: usuarioBanco.username,
                });
                onChangeDigitacao({
                  key: "sellerCpf",
                  value: usuarioBanco.cpfUsuarioCertificado,
                });
              }
            }}
            dropdownProps={{
              w: "300px",
              children: allDisabled
                ? digitacaoFields.data?.sellerCode ??
                  digitacaoFields.data?.sellerCpf ??
                  digitacaoFields.data?.userConsig?.loginConsig ??
                  undefined
                : undefined,
            }}
            value={
              digitacaoFields.data?.sellerCode ||
              digitacaoFields.data?.userConsig?.loginConsig
            }
            options={usuariosBancos || []}
            errorMessage={
              errors["userConsig"] ||
              errors["passwordConsig"] ||
              errors["sellerCpf"]
            }
            isDisabled={isLoading || allDisabled}
            isLoading={loadingUsuarios}
          />
        </Center>
      ) : null}

      <Button
        isDisabled={allDisabled}
        onClick={() => handleDigitar()}
        isLoading={isLoading}
        mx="10px"
      >
        {"Digitar"}
      </Button>

      <Button
        variant="outline"
        onClick={onClose}
        leftIcon={<CloseIcon w="12px" h="12px" />}
      >
        {allDisabled ? "Fechar" : "Cancelar"}
      </Button>
    </Flex>
  );

  return (
    <CustomModal
      modalTitle={`Digitação Cartão ${digitacaoFields.operacao}`}
      isOpen={isOpen}
      onClose={onClose}
      modalFooter={modalFooter}
      size="6xl"
      scroll="inside"
      isLoading={isLoading}
    >
      <Grid
        templateColumns="1fr"
        gap="16px"
        border="1px solid var(--chakra-colors-custom-gray)"
        bg="var(--chakra-colors-gray-100)"
        p="20px 10px 10px 10px"
        borderRadius="4px"
      >
        <Flex pos="relative" mb="10px">
          <Center w="100%">
            <Text fontSize="20px" fontWeight="medium">
              Formulário {simulacao.banco}
            </Text>
          </Center>
          <Button
            pos="absolute"
            right="0px"
            top="-5px"
            w="261px"
            leftIcon={<CopyIcon />}
            size="sm"
            onClick={() => {
              setDigitacaoFields({
                ...copyAttendanceDataToDigitacaoCartao(
                  simulacao,
                  attendance,
                  digitacaoFields
                ),
              });
            }}
            isDisabled={allDisabled}
          >
            Copiar dados do atendimento
          </Button>
        </Flex>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="CPF"
            onChange={(e) =>
              onChangeDigitacao({
                key: "cpf",
                value: maskCPFFormat(e.target.value).raw,
              })
            }
            inputProps={{ bg: "#fff" }}
            value={maskCPFFormat(digitacaoFields.data?.cpf).value || ""}
            errorMessage={errors["cpf"]}
          />
        </Grid>

        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="Nome"
            onChange={(e) =>
              onChangeDigitacao({ key: "name", value: e.target.value })
            }
            inputProps={{ bg: "#fff" }}
            value={digitacaoFields.data?.name || ""}
            errorMessage={errors["name"]}
          />
        </Grid>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <DropdownField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            dropdownProps={{ w: "100%", bg: "#fff" }}
            title="Sexo"
            onChange={(value) =>
              onChangeDigitacao({ key: "gender", value: value })
            }
            value={digitacaoFields.data?.gender || ""}
            errorMessage={errors["gender"]}
            options={[
              { name: "Feminino", value: "F" },
              { name: "Masculino", value: "M" },
            ]}
          />
        </Grid>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="Data Nascimento"
            onChange={(e) =>
              onChangeDigitacao({
                key: "birthday",
                value: maskDateFormat(e.target.value).raw,
              })
            }
            inputProps={{ bg: "#fff" }}
            value={maskDateFormat(digitacaoFields.data?.birthday).value || ""}
            errorMessage={errors["birthday"]}
          />
        </Grid>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="RG"
            onChange={(e) => {
              onChangeDigitacao({
                key: "documentNumber",
                value: e.target.value,
              });
            }}
            inputProps={{ bg: "#fff" }}
            value={digitacaoFields.data?.documentNumber || ""}
            errorMessage={errors["documentNumber"]}
          />
        </Grid>
        {/* <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <DropdownField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            dropdownProps={{ w: "100%", bg: "#fff" }}
            title="Estado Civil"
            onChange={(value) =>
              onChangeDigitacao({ key: "civilStatus", value: value })
            }
            value={digitacaoFields.data?.["civilStatus"]}
            errorMessage={errors["civilStatus"]}
            options={statusCivilOptions}
          />
        </Grid> */}

        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <DropdownField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            dropdownProps={{ w: "100%", bg: "#fff" }}
            title="UF Emissão Documento"
            onChange={(value) =>
              onChangeDigitacao({ key: "documentEmissionState", value: value })
            }
            value={digitacaoFields.data?.documentEmissionState || ""}
            errorMessage={errors["documentEmissionState"]}
            options={UFsBrasil}
          />
        </Grid>
        {/* <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <DropdownField
            title="Pessoa Analfabeta"
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            dropdownProps={{ w: "100%", bg: "#fff" }}
            onChange={(value) =>
              onChangeDigitacao({ key: "illiterate", value: value })
            }
            value={digitacaoFields.data?.illiterate || ""}
            errorMessage={errors["illiterate"]}
            options={[
              { name: "Sim", value: "SIM" },
              { name: "Não", value: "NÃO" },
            ]}
          />
        </Grid> */}
        {/* <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <DropdownField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            dropdownProps={{ w: "100%", bg: "#fff" }}
            title="Orgão Emissor Documento"
            onChange={(value) =>
              onChangeDigitacao({ key: "documentIssuer", value: value })
            }
            value={digitacaoFields.data?.["documentIssuer"] || ""}
            errorMessage={errors["documentIssuer"]}
            options={dropdownOrgaosEmissoresDocDigitacaoFGTSFacta}
          />
        </Grid> */}
        {/* <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="Data expedição doc."
            onChange={(e) =>
              onChangeDigitacao({
                key: "documentDate",
                value: maskDateFormat(e.target.value).raw,
              })
            }
            inputProps={{ bg: "#fff" }}
            value={
              maskDateFormat(digitacaoFields.data?.documentDate).value || ""
            }
            errorMessage={errors["documentDate"]}
          />
        </Grid> */}
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <DropdownField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            dropdownProps={{ w: "100%", bg: "#fff" }}
            title="Estado Nascimento"
            onChange={(value) =>
              onChangeDigitacao({ key: "birthState", value: value })
            }
            value={digitacaoFields.data?.birthState || ""}
            errorMessage={errors["birthState"]}
            options={UFsBrasil}
          />
        </Grid>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="Cidade Nascimento"
            onChange={(e) =>
              onChangeDigitacao({ key: "birthCity", value: e.target.value })
            }
            inputProps={{ bg: "#fff" }}
            value={digitacaoFields.data?.birthCity || ""}
            errorMessage={errors["birthCity"]}
          />
        </Grid>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="Telefone"
            onChange={(e) => {
              const { ddd, number } = phoneSeparate({
                completedNumber: e.target.value,
              });
              onChangeDigitacao({ key: "phoneNumber1", value: number });
              onChangeDigitacao({ key: "phoneDDD1", value: ddd });
            }}
            inputProps={{ bg: "#fff" }}
            value={
              phoneSeparate({
                dddPhone: digitacaoFields.data?.phoneDDD1,
                phone: digitacaoFields.data?.phoneNumber1,
              }).phoneAndDDDFormatedValue || ""
            }
            errorMessage={errors["phoneDDD1"]}
          />
        </Grid>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="Valor Salário"
            onChange={(e) => {
              onChangeDigitacao({
                key: "incomeValue",
                value: currencyMaskFormat(e.target.value).raw,
              });
            }}
            inputProps={{ bg: "#fff" }}
            value={
              currencyMaskFormat(digitacaoFields.data?.incomeValue).value || ""
            }
            errorMessage={errors["incomeValue"]}
          />
        </Grid>
        {/* <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="Valor Patrimonial"
            onChange={(e) => {
              onChangeDigitacao({
                key: "equityValue",
                value: currencyMaskFormat(e.target.value).raw,
              });
            }}
            inputProps={{ bg: "#fff" }}
            value={
              currencyMaskFormat(digitacaoFields.data?.equityValue).value || ""
            }
            errorMessage={errors["equityValue"]}
          />
        </Grid> */}
        <Grid templateColumns="220px auto 140px" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="CEP"
            onChange={(e) =>
              onChangeDigitacao({
                key: "addressZipCode1",
                value: maskCEPFormat(e.target.value).raw,
              })
            }
            inputProps={{
              bg: "#fff",
              onKeyDown: (e) => {
                if (e.key === "Enter") {
                  loadCEPData(e.currentTarget.value.replace(/\D/g, ""));
                }
              },
            }}
            value={
              maskCEPFormat(digitacaoFields.data?.addressZipCode1).value || ""
            }
            errorMessage={errors["addressZipCode1"]}
          />
          <ButtonWithLoading
            isDisabled={
              (digitacaoFields.data?.["addressZipCode1"]?.length ?? 0) < 8 ||
              allDisabled
            }
            onClick={async () =>
              loadCEPData(digitacaoFields.data?.addressZipCode1!)
            }
            rightIcon={<Search2Icon />}
          >
            Buscar CEP
          </ButtonWithLoading>
        </Grid>

        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="Estado"
            onChange={(e) =>
              onChangeDigitacao({
                key: "addressStateCode1",
                value: e.target.value,
              })
            }
            inputProps={{ bg: "#fff" }}
            value={digitacaoFields.data?.addressStateCode1 || ""}
            errorMessage={errors["addressStateCode1"]}
          />
        </Grid>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="Cidade"
            onChange={(e) =>
              onChangeDigitacao({ key: "addressCity1", value: e.target.value })
            }
            inputProps={{ bg: "#fff" }}
            value={digitacaoFields.data?.addressCity1 || ""}
            errorMessage={errors["addressCity1"]}
          />
        </Grid>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="Bairro"
            onChange={(e) =>
              onChangeDigitacao({ key: "addressNeigh1", value: e.target.value })
            }
            inputProps={{ bg: "#fff" }}
            value={digitacaoFields.data?.addressNeigh1 || ""}
            errorMessage={errors["addressNeigh1"]}
          />
        </Grid>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="Endereço"
            onChange={(e) =>
              onChangeDigitacao({
                key: "addressStreet1",
                value: e.target.value.slice(0, 35),
              })
            }
            inputProps={{ bg: "#fff" }}
            value={digitacaoFields.data?.addressStreet1 || ""}
            errorMessage={errors["addressStreet1"]}
          />
        </Grid>
        {/* <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="Complemento endereço"
            onChange={(e) =>
              onChangeDigitacao({
                key: "addressComplement1",
                value: e.target.value,
              })
            }
            inputProps={{ bg: "#fff" }}
            value={digitacaoFields.data?.["addressComplement1"] || ""}
            errorMessage={errors["addressComplement1"]}
          />
        </Grid> */}
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="Número"
            onChange={(e) =>
              onChangeDigitacao({
                key: "addressNumber1",
                value: e.target.value,
              })
            }
            inputProps={{ bg: "#fff" }}
            value={digitacaoFields.data?.addressNumber1 || ""}
            errorMessage={errors["addressNumber1"]}
          />
        </Grid>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="Nome Mãe"
            onChange={(e) =>
              onChangeDigitacao({ key: "mothersName", value: e.target.value })
            }
            inputProps={{ bg: "#fff" }}
            value={digitacaoFields.data?.mothersName || ""}
            errorMessage={errors["mothersName"]}
          />
        </Grid>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="Banco"
            onChange={(e) => {
              onChangeDigitacao({
                key: "bankNumber",
                value: e.target.value.replace(/\D/g, ""),
              });
            }}
            inputProps={{
              bg: "#fff",
              placeholder: "Código banco",
              _placeholder: { opacity: 0.5 },
            }}
            value={digitacaoFields.data?.bankNumber || ""}
            errorMessage={errors["bankNumber"]}
          />
        </Grid>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <DropdownField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            dropdownProps={{
              w: "100%",
              bg: "#fff",
              ...(allDisabled
                ? {
                    children:
                      tipoContaDigitacaoCartaoOptions.find(
                        (crr) =>
                          crr.value === digitacaoFields.data?.["bankType"]
                      )?.name ?? digitacaoFields.data?.["bankType"],
                  }
                : {}),
            }}
            title="Tipo Conta"
            onChange={(value) =>
              onChangeDigitacao({ key: "bankType", value: value })
            }
            value={digitacaoFields.data?.bankType || ""}
            errorMessage={errors["bankType"]}
            options={tipoContaDigitacaoCartaoOptions}
          />
        </Grid>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="Agência"
            onChange={(e) =>
              onChangeDigitacao({
                key: "bankAgency",
                value: e.target.value.replace(/\D/g, ""),
              })
            }
            inputProps={{ bg: "#fff" }}
            value={digitacaoFields.data?.bankAgency || ""}
            errorMessage={errors["bankAgency"]}
          />
        </Grid>
        <Grid
          templateColumns="220px auto 67px 15%"
          alignItems="center"
          gap="20px"
        >
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="Conta"
            onChange={(e) =>
              onChangeDigitacao({
                key: "bankAccountNumber",
                value: e.target.value,
              })
            }
            inputProps={{ bg: "#fff" }}
            value={digitacaoFields.data?.bankAccountNumber}
            errorMessage={errors["bankAccountNumber"]}
          />
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="Díg. Verif."
            onChange={(e) =>
              onChangeDigitacao({
                key: "bankDigit",
                value: e.target.value,
              })
            }
            inputProps={{ bg: "#fff" }}
            value={digitacaoFields.data?.bankDigit}
            errorMessage={errors["bankDigit"]}
          />
        </Grid>
        {/* <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="Conta"
            onChange={(e) =>
              onChangeDigitacao({
                key: "bankAccountNumber",
                value: e.target.value.replace(/\D/g, ""),
              })
            }
            inputProps={{ bg: "#fff" }}
            value={digitacaoFields.data?.["bankAccountNumber"] || ""}
            errorMessage={errors["bankAccountNumber"]}
          />
        </Grid>
         */}
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="Número Benefício"
            onChange={(e) =>
              onChangeDigitacao({
                key: "benefitNumber",
                value: e.target.value.replace(/\D/g, ""),
              })
            }
            inputProps={{ bg: "#fff" }}
            value={digitacaoFields.data?.benefitNumber || ""}
            errorMessage={errors["benefitNumber"]}
          />
        </Grid>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <DropdownField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            dropdownProps={{ w: "100%", bg: "#fff" }}
            title="Espécie Benefício"
            onChange={(value) =>
              onChangeDigitacao({ key: "specie", value: value })
            }
            value={Number(digitacaoFields.data?.specie) || ""}
            errorMessage={errors["specie"]}
            options={dropdownEspecies}
          />
        </Grid>
        {/* {simulacao.banco === "PAN" ? (
          <Grid templateColumns="220px auto" alignItems="center" gap="20px">
            <DropdownField
              isDisabled={allDisabled}
              titleProps={{ textAlign: "end", mb: "0px" }}
              dropdownProps={{ w: "100%", bg: "#fff" }}
              title="Orgão"
              onChange={(value) =>
                onChangeDigitacao({ key: "organCode", value })
              }
              value={digitacaoFields.data?.["organCode"] || ""}
              errorMessage={errors["organCode"]}
              options={organCodeOptions}
            />
          </Grid>
        ) : null} */}
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <DropdownField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            dropdownProps={{ w: "100%", bg: "#fff" }}
            title="Estado Benefício"
            onChange={(value) =>
              onChangeDigitacao({ key: "benefitState", value: value })
            }
            value={digitacaoFields.data?.benefitState || ""}
            errorMessage={errors["benefitState"]}
            options={UFsBrasil}
          />
        </Grid>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <DropdownField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="Seguro"
            onChange={(value, option) => {
              onChangeDigitacao({
                key: "secureCode",
                value: option.optionValue.secureCode,
              });
              onChangeDigitacao({
                key: "secureType",
                value: option.optionValue.secureType,
              });
            }}
            dropdownProps={{ w: "100%", bg: "#fff" }}
            options={seguroOptions}
            value={digitacaoFields.data?.secureCode || ""}
            errorMessage={errors["secureCode"] || errors["secureType"]}
            isLoading={isLoadingSeguros}
          />
        </Grid>

        {/* <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <DropdownField
            title="Tabelas"
            titleProps={{ textAlign: "end", mb: "0px" }}
            dropdownProps={{
              w: "100%",
              bg: "#fff",
              ...(allDisabled || isRedigitar
                ? { children: digitacaoFields.data?.cod }
                : {}),
            }}
            // onChange={(value, option) => {
            //   onChangeDigitacao({ key: "tableCode", value: option.value });
            // }}
            value={
              simulacao.simulacao?.codeTable ||
              simulacao.simulacao?.tabela ||
              simulacao.simulacao?.tableCode ||
              ""
            }
            options={tabelasList}
            isLoading={loadingTabelas}
            errorMessage={errors["tableCode"]}
            isDisabled={true}
          />
        </Grid> */}
      </Grid>
    </CustomModal>
  );
}

export const openModalDigitacaoCartao = (props: ModalDigitacaoParams) =>
  makeEvent("openModalDigitacaoCartao", props);
