import { Toast } from "components/toast";
import api from "api/api";
import { RefObject } from "react";

export const downloadMailing = async (
  id: number,
  name: string,
  downloadRef: RefObject<HTMLAnchorElement | null>
) => {
  try {
    let { data } = await api.get(`/mailings/${id}/download`, {
      responseType: "blob",
    });
    const text = await data.text();
    const file = new File([text], "mailing.csv", {
      type: "text/plain",
    });

    const download = downloadRef.current;
    if (download) {
      const fileUrl = URL.createObjectURL(file);
      download.href = fileUrl;
      download.download = `${name}.csv`;
      download.click();
    }
  } catch (e) {
    console.log(e);
    Toast({
      title: "Ocorreu um erro ao baixar mailing",
    });
  }
};
