import { parseCodBancoToEnumBanco } from "components/atendimentos-components/atendimento-form/contracts-table/consts";
import { factaCitiesArray } from "components/atendimentos-components/atendimento-form/step-form/tabs-components/digitacao-fields/parts/cities-array";
import { CEPProps } from "components/atendimentos-components/atendimento-form/step-form/tabs-components/digitacao-fields/parts/const";
import { Attendance } from "components/atendimentos-components/atendimento-form/types";
import { fieldValidation } from "utils/field-validation";
import { normalizeText } from "utils/filter-array-by";
import { ObjectSchema, objectSchema } from "utils/object-methods";
import { SimulacaoCartaoProps } from "../simulacao-cartao";
import { phoneSeparate } from "../simulacao-fields/utils";
import { formatTipoContaPagamento } from "../../contratos/modal-digitacao-saque/utils";

export function validateDigitacaoCartao(body: DigitacaoCartaoProps) {
  let schema: ObjectSchema<Omit<DigitacaoCartaoProps, "data">> = {
    banco: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });
      return { valid: isValid, message: errorMessage };
    },
    operacao: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value })
        .required({
          message: "Preencha o campo corretamente",
        })
        .custom({
          validate: (value) => value === "RMC" || value === "RCC",
          message: "Preencha o campo corretamente",
        });
      return { valid: isValid, message: errorMessage };
    },
  };

  let schemaData: ObjectSchema<DigitacaoCartaoDataProps> = {
    cpf: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });
      if (value == null && body.data?.cpf) {
        const value = body.data.cpf;
        const { isValid, errorMessage } = fieldValidation({ value }).required({
          message: "Preencha o campo corretamente",
        });
        return { valid: isValid, message: errorMessage, format: value };
      }
      return { valid: isValid, message: errorMessage };
    },
    name: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    gender: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    birthday: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    documentNumber: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    documentEmissionState: (value) => {
      return { valid: true, format: body.data?.addressStateCode1 };
    },
    birthState: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });
      return { valid: isValid, message: errorMessage };
    },
    birthCity: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    phoneNumber1: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    incomeValue: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    addressZipCode1: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    addressStreet1: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    addressNumber1: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    addressNeigh1: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    addressStateCode1: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    addressCity1: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    mothersName: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    bankNumber: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    bankAgency: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    bankAccountNumber: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    benefitNumber: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    specie: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    benefitState: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    bankType: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    phoneDDD1: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });
      return { valid: isValid, message: errorMessage };
    },
    bankDigit: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });
      return { valid: isValid, message: errorMessage };
    },

    sellerCode: (value) => {
      if (body.banco === "PAN") {
        const { isValid, errorMessage } = fieldValidation({ value }).required({
          message: "Preencha o campo corretamente",
        });
        return { valid: isValid, message: errorMessage };
      } else return { valid: true };
    },
    wantToWithdraw: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });
      return { valid: isValid, message: errorMessage };
    },
    sellerCpf: (value) => {
      if (body.banco === "PAN") {
        const { isValid, errorMessage } = fieldValidation({ value }).required({
          message: "Preencha o campo corretamente",
        });

        return { valid: isValid, message: errorMessage };
      } else return { valid: true };
    },
    userConsig: (value) => {
      if (body.banco !== "BMG") return { valid: true };
      const { isValid, errorMessage } = fieldValidation({ value })
        .required({
          message: "Preencha o campo corretamente",
        })
        .custom({
          validate(data) {
            const login = fieldValidation({
              value: data?.loginConsig,
            }).required();
            const pass = fieldValidation({
              value: data?.passConsig,
            }).required();
            return login.isValid || pass.isValid;
          },
          message: `Usuário inválido`,
        });

      return { valid: isValid, message: errorMessage };
    },
    secureCode: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    secureType: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
  };

  schema = {
    ...schema,
    data: schemaData,
  } as ObjectSchema<DigitacaoCartaoProps>;

  const result = objectSchema(
    schema as ObjectSchema<DigitacaoCartaoProps>,
    body
  );

  return result;
}

export function copyAttendanceDataToDigitacaoCartao(
  simulacao: SimulacaoCartaoProps,
  attendance: Attendance,
  digitacaoFields: DigitacaoCartaoProps
) {
  const formatSexo = (value: string) => (value === "MASCULINO" ? "M" : "F");

  const { data: _, ...removedData } = digitacaoFields;

  const result: DigitacaoCartaoProps = {
    banco: simulacao.banco,
    data: {
      cpf: attendance.cpf!,
      name: attendance.nome,
      gender: formatSexo(attendance.sexo!),
      birthday: attendance.dataNascimento!,
      documentNumber: attendance.documento!?.trim(),
      documentEmissionState: attendance.ufEmissaoDoc! || attendance.uf,
      birthState: attendance.ufNaturalidade! || attendance.uf,
      birthCity: attendance.naturalidade! || attendance.cidade,
      phoneNumber1: phoneSeparate({
        completedNumber: attendance.telefoneResidencial!,
      }).number,
      phoneDDD1: phoneSeparate({
        completedNumber: attendance.telefoneResidencial!,
      }).ddd,
      incomeValue: attendance.valorSalario!,
      addressZipCode1: attendance.cep!,
      addressStreet1: attendance.endereco!,
      addressNumber1: attendance.numero!,
      addressNeigh1: attendance.bairro!,
      addressStateCode1: attendance.uf!,
      addressCity1: attendance.cidade!,
      mothersName: attendance.nomeMae!,
      bankAgency: attendance.agenciaPagamento?.replaceAll?.(" ", ""),
      bankAccountNumber: attendance.contaPagamento?.replaceAll?.(" ", ""),
      benefitNumber: attendance.nb!?.replaceAll?.(" ", ""),
      specie: `${attendance.especieNB!}`,
      benefitState: attendance.ufNB! || attendance.uf,
      bankType: formatTipoContaPagamento(attendance.tipoContaPagamento!),
      bankNumber: formatCodBancoPagamento(attendance.codBancoPagamento),
      bankDigit: formatCodBancoPagamento(attendance.dvContaPagamento),

      ...digitacaoFields.data,
    },
    ...removedData,
  };

  return result;
}

const formatCodBancoPagamento = (value: string | number | null) => {
  return typeof value === "string"
    ? value.trim()
    : typeof value === "number"
    ? `${value}`
    : undefined;
};

export const setCpfDataSimulacaoCartao = (
  cep: CEPProps,
  onChangeDigitacao: ({
    key,
    value,
  }: {
    key:
      | Exclude<keyof DigitacaoCartaoProps, "data">
      | keyof DigitacaoCartaoDataProps;
    value: any;
  }) => void
) => {
  onChangeDigitacao({ key: "addressStreet1", value: cep.logradouro });
  onChangeDigitacao({ key: "addressNeigh1", value: cep.bairro });

  const possiveisCidades = factaCitiesArray.filter((crr) => {
    return (
      crr.value.uf === cep.uf &&
      normalizeText(crr.value.cityName).includes(normalizeText(cep.localidade!))
    );
  });
  if (possiveisCidades.length === 1) {
    onChangeDigitacao({
      key: "addressCity1",
      value: possiveisCidades[0].value.cityName,
    });
    onChangeDigitacao({
      key: "addressStateCode1",
      value: possiveisCidades[0].value.uf,
    });
  }
};

export function parseGETDigitacaoCartao(
  body: DigitadosGETDigitacao
): DigitacaoCartaoProps {
  return {
    banco: parseCodBancoToEnumBanco(body.bankNumber),
    data: {
      addressCity1: body.addressCity1,
      // addressComplement1: body.addressComplement1,
      addressNeigh1: body.addressNeigh1,
      addressNumber1: body.addressNumber1,
      addressStateCode1: body.addressStateCode1,
      addressStreet1: body.addressStreet1,
      addressZipCode1: body.addressZipCode1,
      bankAccountNumber: body.bankAccountNumber,
      bankAgency: body.bankAgency,
      bankDigit: body.bankDigit,
      bankNumber: body.bankNumber,
      bankType: body.bankType,
      benefitNumber: body.benefitNumber,
      benefitState: body.benefitState,
      birthCity: body.birthCity,
      birthday: body.birthDay,
      birthState: body.birthState,
      // civilStatus: body.civilStatus,
      cpf: body.cpf,
      // documentDate: body.documentDate,
      documentEmissionState: body.documentEmissionState,
      // documentIssuer: body.documentIssuer,
      documentNumber: body.documentNumber,
      // equityValue: body.equityValue,
      gender: body.gender,
      // illiterate: body.illiterate,
      incomeValue: body.incomeValue,
      mothersName: body.mothersName,
      name: body.name,
      // nationality: body.nationality,
      // organCode: body.organCode,
      // originCountry: body.originCountry,
      phoneDDD1: body.phoneDDD1,
      phoneNumber1: body.phoneNumber1,
      sellerCode: body.sellerCode,
      sellerCpf: body.sellerCpf,
      specie: body.speciesNb,
      wantToWithdraw: body.wantToWithdraw,
    },
  };
}

export const tipoContaDigitacaoCartaoOptions = [
  {
    name: "Conta Poupança",
    value: "CONTA_POUPANCA_INDIVIDUAL",
  },
  { name: "Conta Corrente", value: "CONTA_CORRENTE_INDIVIDUAL" },
];

export interface DigitacaoCartaoProps {
  banco?: string;
  operacao?: "RMC" | "RCC";
  data?: DigitacaoCartaoDataProps;
}

export interface DigitacaoCartaoDataProps {
  cpf?: string;
  name?: string;
  gender?: "F" | "M";
  documentNumber?: string;
  documentEmissionState?: string;
  // documentIssuer?: string;
  // documentDate?: string;
  birthState?: string;
  birthCity?: string;
  birthday?: string;
  // nationality?: string;
  // originCountry?: string;
  phoneDDD1?: string;
  phoneNumber1?: string;
  incomeValue?: number;
  addressZipCode1?: string;
  addressStreet1?: string;
  addressNumber1?: string;
  addressNeigh1?: string;
  addressStateCode1?: string;
  addressCity1?: string;
  // addressComplement1?: string;
  mothersName?: string;
  // civilStatus?: string;
  bankNumber?: string;
  bankAgency?: string;
  bankAccountNumber?: string;
  bankDigit?: string;
  bankType?: string;
  benefitNumber?: string;
  specie?: string;
  benefitState?: string;
  // illiterate?: string;
  // equityValue?: string | number;
  sellerCode?: string;
  sellerCpf?: string;
  // magneticCard?: string;
  // organCode?: string;
  wantToWithdraw?: boolean;
  userConsig?: { loginConsig: string; passConsig: string };
  secureCode?: string;
  secureType?: string;
}

export interface DigitadosGETDigitacao {
  id?: number;
  cpf?: string;
  name?: string;
  birthDay?: string;
  gender?: "F" | "M";
  civilStatus?: "SOLTEIRO" | "CASADO";
  email?: string;
  cpfPartner?: string;
  namePartner?: string;
  birthDayPartner?: string;
  nationality?: "BRASILEIRO";
  originCountry?: string;
  documentNumber?: string;
  documentEmissionState?: string;
  documentIssuer?: string;
  documentDate?: string;
  mothersName?: string;
  fathersName?: string;
  birthCity?: string;
  birthState?: string;
  incomeValue?: number;
  equityValue?: number;
  illiterate?: "SIM" | "NÃO";
  phoneDDD1?: string;
  phoneNumber1?: string;
  addressStreet1?: string;
  addressNumber1?: string;
  addressComplement1?: string;
  addressNeigh1?: string;
  addressCity1?: string;
  addressStateCode1?: string;
  addressZipCode1?: string;
  bankAgency?: string;
  bankNumber?: string;
  bankAccountNumber?: string;
  bankDigit?: string;
  bankType?: string;
  sellerCode?: string;
  cpfLegalRepresentative?: string;
  nameLegalRepresentative?: string;
  speciesNb?: string;
  nb?: string;
  ufNb?: string;
  tableCode?: string;
  term?: number;
  installmentValue?: string;
  registration?: string;
  sendType?: string;
  formalizationType?: string;
  loginConsig?: string;
  passConsig?: string;
  benefitState?: string;
  educationLevel?: string;
  benefitNumber?: string;
  documentType?: string;
  certifiedLogin?: string;
  sellerLogin?: string;
  sellerCpf?: string;
  organCode?: string;
  wantToWithdraw?: boolean;
  politicalPerson?: "SIM" | "NÃO";
}
