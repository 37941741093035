import "react-app-polyfill/ie11";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ChakraProvider, createStandaloneToast } from "@chakra-ui/react";
import theme from "chakra/theme";
import { ModalConfirm } from "components/modal-confirm-new";
import { ModalError } from "components/modal-error";
import { ModalInfo } from "components/modal-info";

const { ToastContainer } = createStandaloneToast();

const container = document.getElementById("root");

const root = ReactDOM.createRoot(container);

root.render(
  <ChakraProvider theme={theme}>
    <ModalConfirm />
    <ModalError />
    <ModalInfo />
    <App />
    <ToastContainer />
  </ChakraProvider>
);
