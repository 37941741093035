import { Dispatch, SetStateAction } from "react";
import { DigitacaoAntecipacaoFGTSColombo } from "./digitacao-fgts-colombo";
import { AttendanceTabKey } from "components/atendimentos-components/pagina-atendimento/attendance-data-form";

export function DigitacaoAtendimentoFormPart({
  isLoading,
  setIsLoading,
  onChangeTab,
}: {
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  onChangeTab: (tabKey: AttendanceTabKey) => void;
}) {
  return (
    <>
      <DigitacaoAntecipacaoFGTSColombo />
    </>
  );
}
