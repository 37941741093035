import { Box, Flex, Grid, useBreakpointValue } from "@chakra-ui/react";
import apiDigitacao from "api/api-digitacao";
import { useAttendanceContext } from "contexts/attendance-context";
import { useEffect, useState } from "react";
import { ModalSimulacaoApi } from "./requests";
import { DropdownField } from "components/dropdown-field";
import { bancosDigitacaoMailingPipeline } from "components/mailing-components/mailing-options-modal/parts/digitacao-filter";
import {
  dropdownPrazo,
  getSimulacaoBodyType,
  validateSimulacaoFields,
} from "./utils";
import { SimulacaoDataProps, TabelaProps, UsuarioBanco } from "./types";
import {
  Attendance,
  AttendanceMargens,
} from "components/atendimentos-components/atendimento-form/types";
import { InputField } from "components/input-field";
import { Toast } from "components/toast";
import {
  currencyMaskFormat,
  onKeyDownOmitNegativeNumbers,
  onlyNumberValidMask,
} from "utils/string-formats";
import AttendanceInput from "components/atendimentos-components/pagina-atendimento/attendance-field/attendance-input";
import { openModalDigitacaoINSS } from "../modal-digitacao-inss";
import { isColomboUser } from "utils/is-colombo-user";
import { useApplicationContext } from "contexts/ApplicationContext";
import { ButtonWithLoading } from "components/mailing-components/mailing-table/icon-button-with-loading";
import { SimulacaoResultProps } from "..";

export function SimulacaoFields({
  onChangeInput,
  telaAtendimento,
}: {
  onChangeInput: (
    fieldName: keyof Attendance,
    subKey?: keyof AttendanceMargens
  ) => (value: string) => void;
  telaAtendimento: boolean;
}) {
  const [simulacaoResult, setSimulacaoResult] = useState<SimulacaoResultProps>(
    {}
  );
  let [simulacao, setSimulacao] = useState<SimulacaoDataProps>({});
  const [loadingSimulacao, setLoadingSimulacao] = useState(false);
  const [usuariosBancos, setUsuariosBancos] = useState<
    { name: string; value: string; completedUser: UsuarioBanco }[]
  >([]);
  // const [selectedUsuariobanco, setSelectedUsuarioBanco] = useState<
  //   Partial<UsuarioBanco>
  // >({});
  const [tabelasList, setTabelasList] = useState<
    { name: string; value: string; completedTable: TabelaProps }[]
  >([]);
  const [loadingDigitacao, setLoadingDigitacao] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<{ [k: string]: string }>({});
  const { formControl, dispatch } = useAttendanceContext();
  const { user } = useApplicationContext();
  const attendance = formControl.values as Attendance;
  const isVisibleUsuarioSelect =
    ["FACTA", "C6"].includes(simulacao.banco!) && !isColomboUser(user);

  const selectedTabela = tabelasList.find((curr) => {
    return [
      simulacao.codeTable,
      simulacao.tableCode,
      simulacao.tabela,
    ].includes(curr.completedTable.codigo);
  })?.completedTable;
  const selectedUsuariobanco = usuariosBancos.find((curr) => {
    return [
      simulacao.loginVendedor,
      simulacao.vendedor,
      simulacao.username,
    ].includes(curr.completedUser.username);
  })?.completedUser;

  const modalApi = ModalSimulacaoApi();
  const allDisabled = simulacaoResult?.status === "PENDENT";

  const onChangeSimulacao = (key: keyof SimulacaoDataProps, value: any) => {
    simulacao = { ...simulacao, [key]: value };
    setSimulacao(simulacao);
    setErrors((errors) => {
      delete errors[key];
      return { ...errors };
    });
  };

  const handleSimular = async () => {
    const body = getSimulacaoBodyType(
      attendance,
      simulacao,
      selectedUsuariobanco,
      selectedTabela,
      setErrors,
      isColomboUser(user)
    );

    if (!Object.keys(body).length) return;

    const { isValid, errors } = validateSimulacaoFields(
      body!,
      dispatch,
      isColomboUser(user)
    );

    if (!isValid) {
      setErrors(errors);
      console.log("Errors => ", errors);
      return;
    }

    setLoadingSimulacao(true);
    try {
      const { data } = await apiDigitacao.post(
        `api/simulacoes/inss/atendimento/${attendance.id}/simular`,
        body
      );
      if (["C6", "FACTA"].includes(simulacao.banco!)) {
        setSimulacaoResult(data);
      } else {
        setSimulacaoResult({
          ...simulacaoResult,
          simulacao: simulacao,
          banco: simulacao.banco,
          status: "PENDENT",
          observacao: data.observacao,
          valorLiberado: null,
        });
      }

      if (data.status === "FINISHED") {
        Toast({ title: "Simulação concluída", status: "success" });
      } else if (data.status === "PENDENT") {
        Toast({ title: "Simulação em andamento", status: "info" });
      } else if (data.status === "SYSTEM_ERROR") {
        Toast({
          title: "Erro ao simular",
          description: data.observacao,
          status: "error",
        });
      }
    } catch (e: any) {
      const errorMessage = e?.response?.data?.msgInterface;
      Toast({
        title: "Erro ao simular",
        description: errorMessage,
        duration: 6000,
        status: "error",
      });
    } finally {
      setLoadingSimulacao(false);
    }
  };

  useEffect(() => {
    if (simulacao.banco && !isColomboUser(user)) {
      if (isVisibleUsuarioSelect) {
        modalApi.getUsuariosBancos(
          simulacao.banco,
          setUsuariosBancos,
          setLoading
        );
      }

      modalApi.getTabelasDigitacao(
        simulacao.banco,
        attendance.convenio!,
        setTabelasList
      );
    }
  }, [simulacao.banco]);

  useEffect(() => {
    if (isColomboUser(user) && simulacao.banco && simulacao.term) {
      modalApi.getTabelasDigitacaoColombo(
        attendance.convenio,
        simulacao.banco,
        simulacao.term,
        setTabelasList,
        setLoading
      );
    }
  }, [simulacao.banco, simulacao.term]);

  useEffect(() => {
    if (simulacaoResult) {
      if (isColomboUser(user) && !simulacao.banco) {
        onChangeSimulacao("banco", "FACTA");
      } else {
        onChangeSimulacao("banco", simulacaoResult.banco);
      }

      if (simulacaoResult.simulacao?.term) {
        onChangeSimulacao("term", `${simulacaoResult.simulacao?.term}`);
      }
      if (simulacaoResult.simulacao?.valueInstallment) {
        onChangeSimulacao(
          "valueInstallment",
          simulacaoResult.simulacao?.valueInstallment
        );
      }
      if (simulacaoResult.simulacao?.username)
        onChangeSimulacao("username", simulacaoResult.simulacao?.username);
      if (simulacaoResult.simulacao?.vendedor)
        onChangeSimulacao("vendedor", simulacaoResult.simulacao?.vendedor);
      if (simulacaoResult.simulacao?.loginVendedor) {
        onChangeSimulacao("loginVendedor", simulacaoResult.simulacao?.vendedor);
      }
      if (simulacaoResult.simulacao?.tabela)
        onChangeSimulacao("tabela", simulacaoResult.simulacao?.tabela);
      if (simulacaoResult.simulacao?.tableCode)
        onChangeSimulacao("tableCode", simulacaoResult.simulacao?.tableCode);
      if (simulacaoResult.simulacao?.codeTable)
        onChangeSimulacao("codeTable", simulacaoResult.simulacao?.codeTable);
      if ((simulacaoResult.simulacao as any)?.codigoTabela) {
        onChangeSimulacao(
          "codeTable",
          (simulacaoResult.simulacao as any)?.codigoTabela
        );
      }
    }
  }, [simulacaoResult]);

  const clearTabela = () => {
    onChangeSimulacao("tabela", undefined);
    onChangeSimulacao("tableCode", undefined);
    onChangeSimulacao("codeTable", undefined);
  };

  const clearValorLiberado = () => {
    setSimulacaoResult((simulacaoResult) => ({
      ...simulacaoResult,
      simulacao: simulacao,
      banco: simulacao.banco,
      valorLiberado: null,
    }));
  };

  const clearObservacao = () => {
    setSimulacaoResult((simulacaoResult) => ({
      ...simulacaoResult,
      simulacao: simulacao,
      banco: simulacao.banco,
      observacao: null,
    }));
  };

  const clearUsuarioBanco = () => {
    onChangeSimulacao("loginVendedor", undefined);
    onChangeSimulacao("vendedor", undefined);
    onChangeSimulacao("username", undefined);
  };

  useEffect(() => {
    getSimulacaoResult();
  }, []);

  const getSimulacaoResult = async ({
    pending,
  }: { pending?: boolean } = {}) => {
    setLoadingSimulacao(true);
    try {
      const { data } = await apiDigitacao.get(
        `api/simulacoes/inss/atendimento/${attendance.id}/consultar`
      );
      setSimulacaoResult(data);
    } catch (e) {
      // Toast({title: ""})
    } finally {
      setLoadingSimulacao(false);
    }
  };

  const dinamicLimit = useBreakpointValue({ base: 30, "2xl": 40 });

  const limitDesc = telaAtendimento ? 85 : dinamicLimit!;

  const tabelaDesc =
    selectedTabela?.descricao?.length! > limitDesc
      ? selectedTabela?.descricao?.slice(0, limitDesc) + "..."
      : selectedTabela?.descricao;

  const isDisabledDigitar = !["FACTA", "C6", "BMG", "PAN", "PRESENCA"].includes(
    simulacao.banco!
  );
  const isDisabledSimular = !["FACTA", "C6", "BMG", "PAN", "PRESENCA"].includes(
    simulacao.banco!
  );

  return (
    <Flex flexDir="column" gridArea="auto / span 2">
      <Grid templateColumns={"1fr 1fr"} columnGap="20px" rowGap="40px">
        <AttendanceInput
          title="Margem livre (novo)"
          onChange={onChangeInput("margens", "valorMargemLivreNovo")}
          fieldName="margens.valorMargemLivreNovo"
          value={attendance.margens?.valorMargemLivreNovo}
          inputProps={{
            type: "number",
            onKeyDown: onKeyDownOmitNegativeNumbers,
          }}
          source="margens.fonteEmprestimoNovo"
          mask={onlyNumberValidMask}
        />

        <Box>
          <InputField
            title="Valor Parcela"
            onChange={(e) => {
              onChangeSimulacao(
                "valueInstallment",
                currencyMaskFormat(e.target.value).raw
              );
            }}
            value={currencyMaskFormat(simulacao.valueInstallment).value}
            inputProps={{ variant: "outline-custom" }}
            errorMessage={errors["valueInstallment"]}
            isDisabled={allDisabled}
          />
        </Box>
        <Box>
          <DropdownField
            title="Banco"
            onChange={(value) => {
              onChangeSimulacao("banco", value);
              clearTabela();
              clearUsuarioBanco();
              clearValorLiberado();
              clearObservacao();
            }}
            value={simulacao.banco}
            options={
              isColomboUser(user)
                ? colomboBancosSimulacao
                : bancosDigitacaoMailingPipeline
            }
            dropdownProps={{ w: "100%", variant: "outline-custom" }}
            errorMessage={errors["banco"]}
            isDisabled={allDisabled || isColomboUser(user)}
          />
        </Box>
        <Box>
          <DropdownField
            title="Prazo"
            onChange={(value) => {
              onChangeSimulacao("term", value);
              clearValorLiberado();
              clearObservacao();
            }}
            value={simulacao.term}
            options={dropdownPrazo}
            dropdownProps={{ w: "100%", variant: "outline-custom" }}
            errorMessage={errors["term"]}
            // isLoading={loading}
            isDisabled={allDisabled}
          />
        </Box>
        <Box gridArea="auto / span 2">
          <DropdownField
            title="Tabelas"
            onChange={(value, option) => {
              onChangeSimulacao("tabela", option.completedTable.codigo);
              onChangeSimulacao("tableCode", option.completedTable.codigo);
              onChangeSimulacao("codeTable", option.completedTable.codigo);
              setErrors((errors) => {
                delete errors["tabela"];
                delete errors["tableCode"];
                delete errors["codeTable"];
                return { ...errors };
              });
              clearValorLiberado();
              clearObservacao();
            }}
            value={selectedTabela?.codigo}
            options={tabelasList}
            dropdownProps={{
              w: "100%",
              variant: "outline-custom",
              children: tabelaDesc,
            }}
            isLoading={loading}
            errorMessage={
              errors["tabela"] || errors["tableCode"] || errors["codeTable"]
            }
            isDisabled={allDisabled}
          />
        </Box>
        {isVisibleUsuarioSelect ? (
          <Box gridArea="auto / span 2">
            <DropdownField
              title="Usuário digitador banco"
              onChange={(value, option) => {
                const usuario = option.completedUser as UsuarioBanco;
                onChangeSimulacao("loginVendedor", usuario.username);
                onChangeSimulacao("vendedor", usuario.username);
                onChangeSimulacao("username", usuario.username);
                setErrors((errors) => {
                  delete errors["usuarioBanco"];
                  delete errors["cpfCertifiedUser"];
                  return { ...errors };
                });
              }}
              value={selectedUsuariobanco?.username}
              options={usuariosBancos}
              dropdownProps={{ w: "100%", variant: "outline-custom" }}
              isLoading={loading}
              errorMessage={
                errors["usuarioBanco"] || errors["cpfCertifiedUser"]
              }
              isDisabled={allDisabled}
            />
          </Box>
        ) : null}
        <Box gridArea="auto / span 2">
          <AttendanceInput
            title="Valor liberado ao cliente"
            value={simulacaoResult.valorLiberado?.toFixed(2)}
            customTag={simulacaoResult.valorLiberado ? "SIMULADO" : undefined}
          />
        </Box>
        <Box gridArea="auto / span 2">
          <AttendanceInput
            title="Observação"
            customTag={simulacaoResult.observacao ? "SIMULADO" : undefined}
            value={simulacaoResult.observacao}
            inputProps={{
              color: "red.500",
              as: "textarea",
              height: "60px",
              minH: "60px",
              resize: "none",
            }}
          />
        </Box>
      </Grid>

      <Flex justifyContent="flex-end" mt="10px">
        <ButtonWithLoading
          tooltipMessage={
            isDisabledDigitar ? "Serviço disponível para este banco" : undefined
          }
          // size="xs"
          mr="10px"
          // variant="outline"
          onClick={async (e) => {
            if (isDisabledDigitar) return;
            e.stopPropagation();
            // handleDigitar();
            openModalDigitacaoINSS({ simulacao: simulacaoResult });
          }}
          isLoading={loadingDigitacao}
          isDisabled={simulacaoResult.status !== "FINISHED"}
          opacity={isDisabledDigitar ? 0.5 : 1}
        >
          Digitar
        </ButtonWithLoading>

        <ButtonWithLoading
          tooltipMessage={
            isDisabledSimular ? "Serviço disponível para este banco" : undefined
          }
          onClick={async () => {
            if (isDisabledSimular) return;
            if (simulacaoResult?.status === "PENDENT") {
              setLoadingSimulacao(true);
              await getSimulacaoResult({ pending: true });
              setLoadingSimulacao(false);
            } else handleSimular();
          }}
          isLoading={loadingSimulacao}
          opacity={isDisabledSimular ? 0.5 : 1}
        >
          {simulacaoResult?.status === "PENDENT" ? "Atualizar" : "Simular"}
        </ButtonWithLoading>
      </Flex>
    </Flex>
  );
}

const colomboBancosSimulacao = [{ name: "Facta", value: "FACTA" }];
