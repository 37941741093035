import { Grid } from "@chakra-ui/react";
import { AttendancesList } from "./components/attendances-list";
import { WhatsAppChat } from "./components/whats-app-chat";
import { AtendimentoFrame } from "./components/atendimento-frame";
import { useChatsContext } from "./components/chats-context";
import { NovoAttendanceModal } from "../new-attendance-modal";

export const calcTelaChatsHeight = (calc?: string) =>
  `calc(100vh - 55px - 80px${calc ? ` ${calc}` : ``})`;

export function WhatsAppAttendances() {
  const { setAttendance } = useChatsContext();

  return (
    <>
      <Grid templateColumns={"5fr 6fr 6fr"} w="100%" h={calcTelaChatsHeight()}>
        <AttendancesList />
        <WhatsAppChat />
        <AtendimentoFrame />
      </Grid>
      <NovoAttendanceModal setAttendance={setAttendance} />
    </>
  );
}
