import { fieldValidation } from "utils/field-validation";
import { ObjectSchema, objectSchema } from "utils/object-methods";
import { SimulacaoSaqueProps } from "../simulacao-saque";
import { Attendance } from "components/atendimentos-components/atendimento-form/types";
import { phoneSeparate } from "../../margens/simulacao-fields/utils";
import { parseCodBancoToEnumBanco } from "components/atendimentos-components/atendimento-form/contracts-table/consts";
import { DigitadosGETDigitacao } from "../../margens/modal-digitacao-cartao/utils";
import { CEPProps } from "components/atendimentos-components/atendimento-form/step-form/tabs-components/digitacao-fields/parts/const";
import { factaCitiesArray } from "components/atendimentos-components/atendimento-form/step-form/tabs-components/digitacao-fields/parts/cities-array";
import { normalizeText } from "utils/filter-array-by";

export function validateDigitacaoSaque(body: DigitacaoSaqueProps) {
  let schema: ObjectSchema<Omit<DigitacaoSaqueProps, "data">> = {
    banco: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });
      return { valid: isValid, message: errorMessage };
    },
    tipoSaque: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value })
        .required({
          message: "Preencha o campo corretamente",
        })
        .custom({
          validate: (value) => value === "SAQUE_RMC" || value === "SAQUE_RCC",
          message: "Preencha o campo corretamente",
        });
      return { valid: isValid, message: errorMessage };
    },
  };

  let schemaData: ObjectSchema<DigitacaoSaqueDataProps> = {
    cpf: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });
      if (value == null && body.data?.cpf) {
        const value = body.data.cpf;
        const { isValid, errorMessage } = fieldValidation({ value }).required({
          message: "Preencha o campo corretamente",
        });
        return { valid: isValid, message: errorMessage, format: value };
      }
      return { valid: isValid, message: errorMessage };
    },
    name: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    gender: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    birthDay: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    documentNumber: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    documentEmissionState: (value) => {
      return { valid: true, format: body.data?.addressStateCode1 };
    },
    // documentDate: (value) => {
    //   return { valid: true, format: "01/01/2024" };
    // },
    birthState: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },

    income: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    addressZipCode1: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    addressStreet1: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    addressNumber1: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    addressNeigh1: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    addressStateCode1: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    addressCity1: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    mothersName: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    bankNumber: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    agencyNumber: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    accountNumber: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },

    specie: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    // organCode: (value) => {
    //   if (body.banco !== "PAN") return { valid: true };

    //   const { isValid } = fieldValidation({ value }).required();
    //   return { valid: isValid, message: "Preencha o campo corretamente" };
    // },
    benefitState: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    bankType: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    cellPhoneDDD: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });
      return { valid: isValid, message: errorMessage };
    },

    bankDigit: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });
      return { valid: isValid, message: errorMessage };
    },
    // civilStatus: (value) => {
    //   return { valid: true, format: "Viuvo" };
    // },
    // nationality: (value) => {
    //   return { valid: true, format: "BRASILEIRO" };
    // },
    sellerCode: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });
      return { valid: isValid, message: errorMessage };
    },
    cellPhoneNumber: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });
      return { valid: isValid, message: errorMessage };
    },
    // politicalPerson: (value) => {
    //   return { valid: true, format: "NAO" };
    // },

    //
    // USUARIO BANCO
    //
  };

  schema = {
    ...schema,
    data: schemaData,
  } as ObjectSchema<DigitacaoSaqueProps>;

  const result = objectSchema(
    schema as ObjectSchema<DigitacaoSaqueProps>,
    body
  );

  return result;
}

export function copyAttendanceDataToDigitacaoSaque(
  simulacao: SimulacaoSaqueProps,
  attendance: Attendance,
  digitacaoFields: DigitacaoSaqueProps
) {
  const formatSexo = (value: string) => (value === "MASCULINO" ? "M" : "F");

  const { data: _, ...removedData } = digitacaoFields;

  const result: DigitacaoSaqueProps = {
    banco: simulacao.banco,
    data: {
      cpf: attendance.cpf!,
      name: attendance.nome,
      gender: formatSexo(attendance.sexo!),
      birthDay: attendance.dataNascimento!,
      documentNumber: attendance.documento!?.replaceAll?.(" ", ""),
      documentEmissionState: attendance.ufEmissaoDoc! || attendance.uf,
      // documentDate: attendance.dataEmissaoDoc!,
      birthState: attendance.ufNaturalidade! || attendance.uf,
      cellPhoneNumber: phoneSeparate({
        completedNumber: attendance.telefoneResidencial!,
      }).number,
      cellPhoneDDD: phoneSeparate({
        completedNumber: attendance.telefoneResidencial!,
      }).ddd,
      income: attendance.valorSalario!,
      addressZipCode1: attendance.cep!,
      addressStreet1: attendance.endereco!,
      addressNumber1: attendance.numero!,
      addressNeigh1: attendance.bairro!,
      addressStateCode1: attendance.uf!,
      addressCity1: attendance.cidade!,
      mothersName: attendance.nomeMae!,
      agencyNumber: attendance.agenciaPagamento?.replaceAll?.(" ", ""),
      accountNumber: attendance.contaPagamento?.replaceAll?.(" ", ""),
      specie: `${attendance.especieNB!}`,
      benefitState: attendance.ufNB! || attendance.uf,
      bankType: formatTipoContaPagamento(attendance.tipoContaPagamento!),
      bankNumber: formatCodBancoPagamento(attendance.codBancoPagamento),
      bankDigit: formatCodBancoPagamento(attendance.dvContaPagamento),
      ...digitacaoFields.data,
    },
    ...removedData,
  };

  return result;
}

const formatCodBancoPagamento = (value: string | number | null) => {
  return typeof value === "string"
    ? value.trim()
    : typeof value === "number"
    ? `${value}`
    : undefined;
};

export const formatTipoContaPagamento = (value: string) =>
  value === "CONTA_CORRENTE"
    ? "CONTA_CORRENTE_INDIVIDUAL"
    : value === "POUPANCA"
    ? "CONTA_POUPANCA_INDIVIDUAL"
    : undefined;

export function parseGETDigitacaoSaque(
  body: DigitadosGETDigitacao
): DigitacaoSaqueProps {
  return {
    banco: parseCodBancoToEnumBanco(body.bankNumber),
    data: {
      addressCity1: body.addressCity1,
      addressNeigh1: body.addressNeigh1,
      addressNumber1: body.addressNumber1,
      addressStateCode1: body.addressStateCode1,
      addressStreet1: body.addressStreet1,
      addressZipCode1: body.addressZipCode1,
      accountNumber: body.bankAccountNumber,
      agencyNumber: body.bankAgency,
      bankDigit: body.bankDigit,
      bankNumber: body.bankNumber,
      benefitState: body.benefitState,
      birthDay: body.birthDay,
      birthState: body.birthState,
      // civilStatus: body.civilStatus,
      cpf: body.cpf,
      // documentDate: body.documentDate,
      documentEmissionState: body.documentEmissionState,
      documentNumber: body.documentNumber,
      gender: body.gender,
      income: body.incomeValue,
      mothersName: body.mothersName,
      name: body.name,
      // nationality: body.nationality,
      // organCode: body.organCode,
      cellPhoneDDD: body.phoneDDD1,
      cellPhoneNumber: body.phoneNumber1,
      sellerCode: body.sellerCode,
      specie: body.speciesNb,
      bankType: body.bankType as DigitacaoSaqueDataProps["bankType"],
      // politicalPerson: body.politicalPerson,
    },
  };
}

export const formatCPFData = (
  cep: CEPProps
): CEPProps & { cidade?: string; uf?: string } => {
  //   onChangeDigitacao({ key: "addressStreet1", value: cep.logradouro });
  //   onChangeDigitacao({ key: "addressNeigh1", value: cep.bairro });

  const possiveisCidades = factaCitiesArray.filter((crr) => {
    return (
      crr.value.uf === cep.uf &&
      normalizeText(crr.value.cityName).includes(normalizeText(cep.localidade!))
    );
  });
  if (possiveisCidades.length === 1) {
    return {
      ...cep,
      cidade: possiveisCidades[0].value.cityName,
      uf: possiveisCidades[0].value.uf,
    };
  }
  return { ...cep };
};

export interface DigitacaoSaqueProps {
  banco?: string;
  tipoSaque?: "SAQUE_RMC" | "SAQUE_RCC";
  data?: DigitacaoSaqueDataProps;
}

export interface DigitacaoSaqueDataProps {
  cpf?: string;
  agencyNumber?: string;
  bankNumber?: string;
  accountNumber?: string;
  bankDigit?: string;
  bankType?: "CONTA_CORRENTE_INDIVIDUAL" | "CONTA_POUPANCA_INDIVIDUAL";
  cellPhoneDDD?: string;
  cellPhoneNumber?: string;
  name?: string;
  birthDay?: string;
  gender?: "F" | "M";
  // civilStatus?: string;
  documentNumber?: string;
  documentEmissionState?: string;
  // documentDate?: string;
  birthState?: string;
  // nationality?: string;
  addressZipCode1?: string;
  addressStreet1?: string;
  addressNumber1?: string;
  addressNeigh1?: string;
  addressCity1?: string;
  addressStateCode1?: string;
  mothersName?: string;
  specie?: string;
  benefitState?: string;
  // organCode?: string;
  // politicalPerson?: "SIM" | "NÃO";
  income?: number;
  sellerCode?: string;
}

// {
//     "banco": "PAN",
//     "tipoSaque": "SAQUE_RMC",
//     "data": {
//         "cpf": "83886346315",
//         "valueWithdrawal": 195.28,
//         "agencyNumber": "1234",
//         "bankNumber": "237",
//         "accountNumber": "13600251",
//         "bankDigit": "7",
//         "bankType": "CONTA_CORRENTE_INDIVIDUAL",
//         "cellPhoneDDD": "11",
//         "cellPhoneNumber": "999999999",
//         "insurance": true,
//         "name": "MARIA ERLIR DA SILVA COSTA",
//         "birthDay": "20/11/1967",
//         "gender": "F",
//         "civilStatus": "SOLTEIRO",
//         "documentNumber": "12345678",
//         "documentEmissionState": "MA",
//         "documentDate": "01/01/2023",
//         "birthState": "MA",
//         "nationality": "BRASILEIRO",
//         "email": "teste@gmail.com",
//         "addressZipCode1": "59584000",
//         "addressStreet1": "RUA NOSSA SENHORA",
//         "addressNumber1": "2870",
//         "addressNeigh1": "CAJA",
//         "addressCity1": "TOUROS",
//         "addressStateCode1": "RN",
//         "mothersName": "BENEDITA DA SILVA COSTA",
//         "specie": "41",
//         "benefitState": "MA",
//         "organCode": "000501",
//         "politicalPerson": "NÃO",
//         "releaseMediumCode": 177,
//         "income": 1518.00,
//         "sellerCode": "05079593960_007528"
//     }
// }
