import { Box, Flex, Text } from "@chakra-ui/react";
import apiConsulta from "api/api-consulta";
import { Dropdown } from "components/dropdown";
import { useState } from "react";
import { mailingConveniosOptions } from "./consts";
import { Toast } from "components/toast";

export function ConveniosMailing({
  convenio,
  setConvenio,
  selectedOption,
  setSelected,
}: {
  convenio: string;
  setConvenio: React.Dispatch<React.SetStateAction<string>>;
  selectedOption: string;
  setSelected: React.Dispatch<React.SetStateAction<string>>;
}) {
  const [options, setOptions] = useState([]);

  const [loading, setLoading] = useState(false);

  const isPrefOrGov = (value: string) => ["pref", "gov"].includes(value);

  const getOptions = async (value: string) => {
    setLoading(true);
    try {
      const { data } = await apiConsulta.get(
        `/consultas/convenios?orgao=${value}`
      );
      setOptions(
        data.map((item: { id: string; text: string }) => ({
          name: item.text,
          value: item.id,
        }))
      );
    } catch (e: any) {
      Toast({
        title: "Erro ao carregar opções",
        status: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box mr="8px">
      <Text mb="8px">Convênio:</Text>
      <Flex alignItems="center">
        <Dropdown
          options={mailingConveniosOptions.filter(
            (convenio) => convenio.value !== "FGTS"
          )}
          value={convenio}
          mr={isPrefOrGov(convenio) ? "8px" : ""}
          onChange={(value) => {
            if (isPrefOrGov(value)) getOptions(value);
            setConvenio(value);
            setSelected("");
          }}
        />
        {isPrefOrGov(convenio) ? (
          <Dropdown
            value={selectedOption}
            onChange={(value) => {
              setSelected(value);
            }}
            options={options}
            loadingText="Carregando Opções..."
            isLoading={loading}
            menuListProps={{ w: "240px" }}
          />
        ) : null}
      </Flex>
    </Box>
  );
}
