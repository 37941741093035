import { Attendance } from "components/atendimentos-components/atendimento-form/types";
import { SimulacaoCartaoProps } from ".";
import { objectSchema, ObjectSchema } from "utils/object-methods";
import { AttendanceActionsTypes } from "contexts/attendance-context/actions-reducer";
import { Dispatch } from "react";
import { fieldValidation } from "utils/field-validation";
import {
  phoneSeparate,
  revisarCamposComErrors,
} from "../simulacao-fields/utils";
import { SimulacaoResultProps } from "..";

export function getBodySimulacaoCartao(
  simulacao: SimulacaoCartaoProps,
  att: Partial<Attendance>,
  dispatch: Dispatch<AttendanceActionsTypes>,
  tipoCartao: "RMC" | "RCC"
) {
  const attendanceFieldErrors = new Map<keyof Attendance, { name: string }>([]);

  const bodyFormat: SimulacaoCartaoPOSTBody = {
    banco: simulacao.banco,
    operacao: tipoCartao,
    data: {
      cpf: att.cpf,
      birthday: att.dataNascimento,
      numberInstallments: simulacao.numberInstallments,
      valueInstallment: simulacao.valueInstallment,
      value: 0,
      benefitNumber: att.nb,
      income: att.valorSalario,
      margin: simulacao.valueInstallment,
      codeTable: simulacao.codeTable,
      phoneDDD1: phoneSeparate({
        completedNumber: att.telefoneResidencial!,
      }).ddd,
      phoneNumber1: phoneSeparate({
        completedNumber: att.telefoneResidencial!,
      }).number,
    },
  };

  const schema: ObjectSchema<SimulacaoCartaoPOSTBody> = {
    banco: (value) => {
      const isValid = !!value;
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    operacao: (value) => {
      const { isValid } = fieldValidation({ value })
        .required()
        .custom({
          validate: (value) => value === "RMC" || value === "RCC",
          message: "Preencha o campo corretamente",
        });
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    data: {
      cpf: (value) => {
        const { isValid } = fieldValidation({ value }).required();
        if (!isValid) {
          attendanceFieldErrors.set("cpf", { name: "CPF" });
        }
        return {
          valid: isValid,
          message: "Preencha o campo corretamente",
        };
      },
      birthday: (value) => {
        const { isValid } = fieldValidation({ value }).required();
        if (!isValid) {
          attendanceFieldErrors.set("dataNascimento", { name: "Nascimento" });
        }
        return {
          valid: isValid,
          message: "Preencha o campo corretamente",
        };
      },
      numberInstallments: (value) => {
        const isValid = !!value;
        return {
          valid: isValid,
          message: "Preencha o campo corretamente",
        };
      },
      valueInstallment: (value) => {
        const isValid = !!value;
        return {
          valid: isValid,
          message: "Preencha o campo corretamente",
        };
      },
      value: (value) => {
        return { valid: true, message: "Preencha o campo corretamente" };
      },
      benefitNumber: (value) => {
        const { isValid } = fieldValidation({ value }).required();
        if (!isValid) {
          attendanceFieldErrors.set("nb", { name: "Número Benefício" });
        }
        return {
          valid: isValid,
          message: "Preencha o campo corretamente",
        };
      },
      income: (value) => {
        const { isValid } = fieldValidation({ value }).required();
        if (!isValid) {
          attendanceFieldErrors.set("valorSalario", { name: "Valor Salário" });
        }
        return {
          valid: isValid,
          message: "Preencha o campo corretamente",
        };
      },
      margin: (value) => {
        const isValid = !!value;
        return {
          valid: isValid,
          message: "Preencha o campo corretamente",
        };
      },
      codeTable: (value) => {
        const isValid = !!value;
        return {
          valid: isValid,
          message: "Preencha o campo corretamente",
        };
      },
      phoneDDD1: (value) => {
        if (bodyFormat.banco !== "BMG") return { valid: true };
        const { isValid } = fieldValidation({ value }).required();
        if (!isValid) {
          attendanceFieldErrors.set("telefoneResidencial", {
            name: "Telefone 1",
          });
        }
        return {
          valid: isValid,
          message: "Preencha o campo corretamente",
        };
      },
      phoneNumber1: (value) => {
        if (bodyFormat.banco !== "BMG") return { valid: true };
        const { isValid } = fieldValidation({ value }).required();
        if (!isValid) {
          attendanceFieldErrors.set("telefoneResidencial", {
            name: "Telefone 1",
          });
        }
        return {
          valid: isValid,
          message: "Preencha o campo corretamente",
        };
      },
    },
  };

  const { body, ...rest } = objectSchema<SimulacaoCartaoPOSTBody>(
    schema,
    bodyFormat
  );

  if (attendanceFieldErrors.size) {
    revisarCamposComErrors(attendanceFieldErrors, dispatch);
  }

  return { body: body as SimulacaoCartaoPOSTBody, ...rest };
}

export function parseSimulacaoCartaoGET(
  data: SimulacaoCartaoGETResponse
): SimulacaoCartaoProps {
  return {
    banco: data.banco,
    observacao: data.observacao,
    numberInstallments: data.simulacaoCartao?.data.numberInstallments,
    codeTable: data.simulacaoCartao?.data.codeTable,
    valorLimiteCartao: data.valorLiberado,
    valueInstallment: data.simulacaoCartao?.data.valueInstallment,
    valorSaquePrevisto: data.valorSaquePrevisto,
    status: data.status,
    prevSimulacao: data.simulacaoCartao,
  };
}

export function parseSimulacaoCartaoPOST(
  data: SimulacaoCartaoPOSTResponse
): SimulacaoCartaoProps {
  return {
    banco: data.simulacaoCartao?.banco,
    observacao: data.observacao,
    numberInstallments: data.simulacaoCartao?.data.numberInstallments,
    codeTable: data.simulacaoCartao?.data.codeTable,
    valorLimiteCartao: data.valorLiberado,
    valueInstallment: data.simulacaoCartao?.data.valueInstallment,
    valorSaquePrevisto: data.valorSaquePrevisto,
    status: data.status,
    prevSimulacao: data.simulacaoCartao,
  };
}

export interface SimulacaoCartaoPOSTResponse {
  valorLiberado?: number;
  observacao?: string;
  status?: SimulacaoCartaoGETResponse["status"];
  banco?: string;
  operacao?: SimulacaoCartaoGETResponse["operacao"];
  valorSaquePrevisto?: number;
  simulacao?: SimulacaoCartaoGETResponse["simulacao"];
  simulacaoCartao?: SimulacaoCartaoPOSTBody;
}

export interface SimulacaoCartaoGETResponse {
  valorLiberado?: number;
  observacao?: string;
  status?: "FINISHED" | "PENDENT";
  banco?: string;
  operacao?: "CARTAO_NOVO_RMC" | "CARTAO_NOVO_RCC";
  valorSaquePrevisto?: number;
  simulacao: null;
  simulacaoCartao?: SimulacaoCartaoPOSTBody;
}

export interface SimulacaoCartaoPOSTBody {
  banco: string | undefined;
  operacao: string;
  data: {
    cpf: string | null | undefined;
    birthday: string | null | undefined;
    numberInstallments: string | undefined;
    valueInstallment: number | undefined;
    value: number;
    benefitNumber?: string;
    income?: number | null;
    margin?: number;
    codeTable: string | undefined;
    phoneDDD1?: string;
    phoneNumber1?: string;
  };
}
