import { Attendance } from "components/atendimentos-components/atendimento-form/types";
import { fieldValidation } from "utils/field-validation";
import { objectSchema, ObjectSchema } from "utils/object-methods";
import { SimulacaoSaqueProps } from ".";
import { Dispatch } from "react";
import { AttendanceActionsTypes } from "contexts/attendance-context/actions-reducer";
import { revisarCamposComErrors } from "../../margens/simulacao-fields/utils";

export function getBodySimulacaoSaque(
  simulacao: SimulacaoSaqueProps,
  att: Partial<Attendance>,
  dispatch: Dispatch<AttendanceActionsTypes>,
  tipoSaque: SimulacaoSaquePOSTBody["operacao"]
) {
  const attendanceFieldErrors = new Map<keyof Attendance, { name: string }>([]);

  const bodyContent: SimulacaoSaquePOSTBody = {
    banco: simulacao.banco,
    operacao: tipoSaque,
    data: {
      cpf: att.cpf as string | undefined,
      birthDay: att.dataNascimento as string | undefined,
      benefitNumber: att.nb,
      income: att.valorSalario as number | undefined,
      valueInstallment: simulacao.valueInstallment,
      numberInstallment: simulacao.numberInstallments,
      codeTable: simulacao.codeTable,
    },
  };

  let schema: ObjectSchema<Omit<SimulacaoSaquePOSTBody, "data">> = {
    banco: (value) => {
      const isValid = !!value;
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    operacao: (value) => {
      const { isValid } = fieldValidation({ value })
        .required()
        .custom({
          validate: (value) => value === "SAQUE_RMC" || value === "SAQUE_RCC",
          message: "Preencha o campo corretamente",
        });
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
  };

  const schemaData: ObjectSchema<SimulacaoSaquePOSTBody["data"]> = {
    cpf: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      if (!isValid) {
        attendanceFieldErrors.set("cpf", { name: "CPF" });
      }
      return {
        valid: isValid,
        message: "Preencha o campo corretamente",
      };
    },
    birthDay: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      if (!isValid) {
        attendanceFieldErrors.set("dataNascimento", { name: "Nascimento" });
      }
      return {
        valid: isValid,
        message: "Preencha o campo corretamente",
      };
    },
    numberInstallment: (value) => {
      const isValid = !!value;
      return {
        valid: isValid,
        message: "Preencha o campo corretamente",
      };
    },
    valueInstallment: (value) => {
      const isValid = !!value;
      return {
        valid: isValid,
        message: "Preencha o campo corretamente",
      };
    },
    benefitNumber: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      if (!isValid) {
        attendanceFieldErrors.set("nb", { name: "Número Benefício" });
      }
      return {
        valid: isValid,
        message: "Preencha o campo corretamente",
      };
    },
    income: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      if (!isValid) {
        attendanceFieldErrors.set("valorSalario", { name: "Valor Salário" });
      }
      return {
        valid: isValid,
        message: "Preencha o campo corretamente",
      };
    },
    codeTable: (value) => {
      const isValid = !!value;
      return {
        valid: isValid,
        message: "Preencha o campo corretamente",
      };
    },
  };

  schema = {
    ...schema,
    data: schemaData,
  } as ObjectSchema<SimulacaoSaquePOSTBody>;

  const { body, ...rest } = objectSchema<SimulacaoSaquePOSTBody>(
    schema as ObjectSchema<SimulacaoSaquePOSTBody>,
    bodyContent
  );

  if (attendanceFieldErrors.size) {
    revisarCamposComErrors(attendanceFieldErrors, dispatch);
  }

  return { body: body as SimulacaoSaquePOSTBody, ...rest };
}

export function parseSimulacaoSaqueGET(
  data: SimulacaoSaqueGETResponse
): SimulacaoSaqueProps {
  return {
    banco: data.banco,
    observacao: data.observacao,
    numberInstallments: data.simulacaoSaque?.data.numberInstallment,
    codeTable: data.simulacaoSaque?.data.codeTable,
    valorLimiteCartao: data.valorLiberado,
    valueInstallment: data.simulacaoSaque?.data.valueInstallment,
    valorSaquePrevisto: data.valorSaquePrevisto,
    status: data.status,
    prevSimulacao: data.simulacaoSaque,
  };
}

export function parseSimulacaoSaquePOST(
  data: SimulacaoSaquePOSTResponse
): SimulacaoSaqueProps {
  return {
    banco: data.simulacaoSaque?.banco,
    observacao: data.observacao,
    numberInstallments: data.simulacaoSaque?.data.numberInstallment,
    codeTable: data.simulacaoSaque?.data.codeTable,
    valorLimiteCartao: data.valorLiberado,
    valueInstallment: data.simulacaoSaque?.data.valueInstallment,
    valorSaquePrevisto: data.valorSaquePrevisto,
    status: data.status,
    prevSimulacao: data.simulacaoSaque,
  };
}

export interface SimulacaoSaquePOSTBody {
  banco: string | undefined;
  operacao: "SAQUE_RCC" | "SAQUE_RMC";
  data: {
    cpf: string | undefined;
    birthDay: string | undefined;
    benefitNumber: string | undefined;
    income: number | undefined;
    valueInstallment: number | undefined;
    numberInstallment: number | undefined;
    codeTable: string | undefined;
  };
}

export interface SimulacaoSaquePOSTResponse {
  valorLiberado: number | undefined;
  observacao: string | undefined;
  status: SimulacaoSaqueGETResponse["status"];
  banco: string;
  operacao: "SAQUE_COMPLEMENTAR_RCC" | "SAQUE_COMPLEMENTAR_RMC";
  valorSaquePrevisto: number | undefined;
  simulacao: null;
  simulacaoCartao: null;
  simulacaoSaque?: SimulacaoSaquePOSTBody;
}

export interface SimulacaoSaqueGETResponse {
  valorLiberado?: number;
  observacao?: string;
  status: "FINISHED" | "PENDENT" | "SYSTEM_ERROR";
  banco?: string;
  operacao?: SimulacaoSaquePOSTResponse["operacao"];
  valorSaquePrevisto?: number;
  simulacao?: null;
  simulacaoCartao?: null;
  simulacaoSaque?: SimulacaoSaquePOSTBody;
}
