import { BoxProps, Flex, InputProps, Text } from "@chakra-ui/react";
import { ChangeEvent } from "react";
import { Template } from "../mailing-template";
import { MailingFilterProps } from "../../types";
import { RadioFilter } from "./radio-filter";
import { InputField } from "components/input-field";

export function InputFilter({
  title,
  description,
  mapKey,
  onChangeFilter,
  operators,
  inputProps,
  filtersValues,
  formatValue,
  invalidFields,
  containerProps,
}: {
  inputProps?: InputProps;
  formatValue?: (value: string) => { value: string; raw: any };
  invalidFields: { [k: string]: string };
  containerProps?: BoxProps;
} & MailingFilterProps) {
  const currFilter = filtersValues.get(mapKey);
  const errorMessage = invalidFields[mapKey];

  const onChangeInput =
    (index: number) =>
    ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
      const value = currentTarget.value;
      const filterValue = currFilter?.value ?? [];
      if (value) {
        const rawValue = formatValue?.(value).raw;
        const parseValue =
          inputProps?.type === "number"
            ? Number(rawValue ?? value)
            : rawValue ?? value;
        filterValue[index] = parseValue;
        onChangeFilter(
          mapKey,
          new Map(
            filtersValues
              .set(mapKey, { ...currFilter!, value: filterValue })
              .entries()
          )
        );
      } else {
        filterValue[index] = undefined;
        onChangeFilter(
          mapKey,
          new Map(
            filtersValues
              .set(mapKey, { ...currFilter!, value: filterValue })
              .entries()
          )
        );
      }
    };

  return (
    <Template
      title={title}
      description={description}
      mapKey={mapKey}
      containerProps={containerProps}
    >
      <RadioFilter
        mapKey={mapKey}
        operators={operators}
        onChangeFilter={onChangeFilter}
        value={currFilter?.op}
      />
      {currFilter?.op === "between" ? (
        <Flex alignItems="center" maxW="500px">
          <InputField
            onChange={onChangeInput(0)}
            value={
              formatValue
                ? formatValue(currFilter?.value?.[0]).value
                : currFilter?.value?.[0]
            }
            errorMessage={errorMessage}
            inputProps={{ ...inputProps }}
          />
          <Text mx="10px">à</Text>
          <InputField
            onChange={onChangeInput(1)}
            value={
              formatValue
                ? formatValue(currFilter?.value?.[1]).value
                : currFilter?.value?.[1]
            }
            errorMessage={errorMessage}
            inputProps={{ ...inputProps }}
          />
        </Flex>
      ) : currFilter?.op === "equal" || currFilter?.op === "in" ? (
        <InputField
          onChange={onChangeInput(0)}
          value={
            formatValue
              ? formatValue(currFilter?.value?.[0]).value
              : currFilter?.value?.[0]
          }
          errorMessage={errorMessage}
          inputProps={{ maxW: "460px", ...inputProps }}
        />
      ) : null}
    </Template>
  );
}
