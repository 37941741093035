import { CloseIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Grid, Link, Text } from "@chakra-ui/react";
import apiDigitacao from "api/api-digitacao";
import { Attendance } from "components/atendimentos-components/atendimento-form/types";
import { CustomModal } from "components/custom-modal";
import DynamicTable from "components/dynamic-table";
import { Column } from "components/dynamic-table/types";
import { useState } from "react";
import { makeEvent, useEventListener } from "services/events";
import { DigitadoRow } from "..";
import { Toast } from "components/toast";
import {
  bgError,
  bgSucess,
  bgWarning,
  StatusFormat,
} from "components/dynamic-table/row-status";

interface StatusDigitacaoRow {
  datetime: string;
  status: string;
  description?: string;
}

interface INSSData {
  banco?: string | null;
  status?: null | "PENDENT" | "FINISHED";
  codeRequestInss?: string | null;
  numeroProposta?: string | null;
  urlFormalizacao?: string | null;
  message?: string | null;
}

interface ModalInitialProps {
  row: DigitadoRow;
  onChangeRow: (k: keyof DigitadoRow | null, v: any) => void;
  statusList?: StatusDigitacaoRow[];
}

export function ModalAcompanharProposta({
  attendance,
}: {
  attendance: Attendance;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let [modalData, setModalData] = useState<Partial<ModalInitialProps>>({});
  const convenio = modalData.row?.convenio;

  const onOpen = async (props: ModalInitialProps) => {
    setIsOpen(true);
    getStatusList(props.row);
    onChange(null, props);
  };

  const onClose = () => {
    setIsOpen(false);
    setModalData({});
  };

  const onChange = (k: keyof ModalInitialProps | null, value: any) => {
    if (k == null) {
      modalData = value;
      setModalData(modalData);
    } else {
      modalData = { ...modalData, [k]: value };
      setModalData(modalData);
    }
  };

  const getStatusList = async (row: DigitadoRow) => {
    const url =
      row.convenio === "INSS"
        ? `/api/digitacoes/inss/consultar-andamento-ade?digitacao_id=${row.id}`
        : row.convenio === "ANTECIPACAO_FGTS"
        ? `/api/digitacoes/fgts/consultar-andamento-ade?digitacao_id=${row.id}`
        : "";

    setIsLoading(true);
    try {
      const { data } = await apiDigitacao.get(url);
      onChange("statusList", data);
      if (data.length) {
        const lastIndex = data.length - 1;
        modalData.onChangeRow?.("lastStatusADE", data[lastIndex].status);
      }
    } catch (e) {
      Toast({
        title: "Erro ao consultar o acompanhamento da proposta",
        description: "Tente novamente",
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEventListener("openModalAcompanharProposta", onOpen);

  const columns: Column[] = [
    {
      name: "Data",
      render: (row: StatusDigitacaoRow) => {
        return row.datetime;
      },
    },
    {
      name: "Status",
      render: (row: StatusDigitacaoRow) => {
        return row.description ? row.description : row.status;
      },
    },
  ];

  const modalFooter = (
    <>
      <Button
        variant="outline"
        leftIcon={<CloseIcon w="12px" h="12px" />}
        onClick={onClose}
      >
        Fechar
      </Button>
    </>
  );

  return (
    <CustomModal
      modalTitle="Acompanhar"
      isOpen={isOpen}
      onClose={onClose}
      modalFooter={modalFooter}
      size={"3xl"}
      isLoading={isLoading}
    >
      <DynamicTable
        columns={columns}
        rows={modalData.statusList || []}
        isLoading={isLoading}
      />
    </CustomModal>
  );
}

export const openModalAcompanharProposta = (props: ModalInitialProps) =>
  makeEvent("openModalAcompanharProposta", props);

const parseCodigo = new Map([
  ["SUCCESS", { desc: "Sucesso", color: bgSucess }],
  ["SYSTEM_ERROR", { desc: "Erro", color: bgError }],
]);
const parseStatus = new Map([
  ["PENDENT", { desc: "Pendente", color: bgWarning }],
  ["FINISHED", { desc: "Finalizado", color: bgSucess }],
  ["SYSTEM_ERROR", { desc: "Erro", color: bgError }],
]);
