import { useEffect, useState } from "react";
import { TabelaProps } from "../../margens/simulacao-fields/types";
import { useAttendanceContext } from "contexts/attendance-context";
import apiDigitacao from "api/api-digitacao";
import { Toast } from "components/toast";
import { Box, Button, Flex, Grid, useBreakpointValue } from "@chakra-ui/react";
import { InputField } from "components/input-field";
import { DropdownField } from "components/dropdown-field";
import { currencyMaskFormat } from "utils/string-formats";
import AttendanceInput from "components/atendimentos-components/pagina-atendimento/attendance-field/attendance-input";
import {
  getBodySimulacaoSaque,
  parseSimulacaoSaqueGET,
  parseSimulacaoSaquePOST,
  SimulacaoSaqueGETResponse,
  SimulacaoSaquePOSTBody,
  SimulacaoSaquePOSTResponse,
} from "./utils";
import { ModalSimulacaoApi } from "../../margens/simulacao-fields/requests";
import { dropdownPrazo } from "../../margens/simulacao-fields/utils";
import { parseCodBancoToEnumBanco } from "components/atendimentos-components/atendimento-form/contracts-table/consts";
import { openModalDigitacaoCartao } from "../../margens/modal-digitacao-cartao";
import {
  ModalDigitacaoSaque,
  openModalDigitacaoSaque,
} from "../modal-digitacao-saque";
import { ButtonWithLoading } from "components/mailing-components/mailing-table/icon-button-with-loading";
import { AttendanceTabKey } from "components/atendimentos-components/pagina-atendimento/attendance-data-form";

export interface SimulacaoSaqueProps {
  banco?: string;
  numberInstallments?: number;
  codeTable?: string;
  valueInstallment?: number;
  valorLimiteCartao?: number;
  valorSaquePrevisto?: number;
  observacao?: string;
  status?: SimulacaoSaqueGETResponse["status"];
  prevSimulacao?: SimulacaoSaquePOSTBody;
}

export function SimulacaoSaque({
  telaAtendimento,
  tipoSaque,
  onChangeTab,
}: {
  telaAtendimento: boolean;
  tipoSaque: "SAQUE_RCC" | "SAQUE_RMC";
  onChangeTab: (tabKey: AttendanceTabKey) => void;
}) {
  let [simulacaoSaque, setSimulacaoSaque] = useState<SimulacaoSaqueProps>({});
  const [errors, setErrors] = useState<{ [k: string]: string }>({});
  const [tabelasList, setTabelasList] = useState<
    { name: string; value: string; completedTable: TabelaProps }[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSimulacao, setIsLoadingSimulacao] = useState(false);
  const { formControl, dispatch } = useAttendanceContext();
  const attendance = formControl.values;
  const { contratosRMCInss: contratosCartaoRMCInss } = attendance;
  const { contratosRCCInss: contratosCartaoRCCInss } = attendance;
  const isDisabledDigitar = ["BMG", "MASTER"].includes(simulacaoSaque.banco!);
  const isDisabledSimular = ["BMG", "MASTER"].includes(simulacaoSaque.banco!);

  const bancosOptions = bancosSimulacaoSaque.filter(({ value }) =>
    tipoSaque === "SAQUE_RMC"
      ? parseCodBancoToEnumBanco(contratosCartaoRMCInss![0].codBanco!) === value
      : parseCodBancoToEnumBanco(contratosCartaoRCCInss![0].codBanco!) === value
  );

  const selectedTabela = tabelasList.find((curr) => {
    return [simulacaoSaque.codeTable].includes(curr.completedTable.codigo);
  })?.completedTable;

  const modalApi = ModalSimulacaoApi();

  useEffect(() => {
    if (bancosOptions?.[0]) {
      onChangeSimulacaoSaque("banco", bancosOptions?.[0].value);
    }
    if (contratosCartaoRMCInss![0].valorReservado) {
      onChangeSimulacaoSaque(
        "valueInstallment",
        contratosCartaoRMCInss![0].valorReservado
      );
    }
  }, []);

  const onChangeSimulacaoSaque = (k: keyof SimulacaoSaqueProps, value: any) => {
    simulacaoSaque = { ...simulacaoSaque, [k]: value };
    setSimulacaoSaque(simulacaoSaque);
    setErrors((errors) => {
      delete errors[k];
      return { ...errors };
    });
  };

  const simulacaoPOST = async (body: SimulacaoSaquePOSTBody) => {
    return await apiDigitacao.post<SimulacaoSaquePOSTResponse>(
      `/api/simulacoes/inss/atendimento/${attendance.id}/${tipoSaque}/saque/novo/simular`,
      body
    );
  };

  const simulacaoGET = async () => {
    return await apiDigitacao.get<SimulacaoSaqueGETResponse>(
      `/api/simulacoes/inss/atendimento/${attendance.id}/${tipoSaque}/saque/novo/consultar`
    );
  };

  const handleFinalDigitacao = (data: SimulacaoSaquePOSTResponse) => {
    if (data.status === "FINISHED") {
      Toast({ title: "Simulação concluída", status: "success" });
    } else if (data.status === "PENDENT") {
      Toast({ title: "Simulação em andamento", status: "info" });
    } else if (data.status === "SYSTEM_ERROR") {
      Toast({
        title: "Erro ao simular",
        description: data.observacao,
        status: "error",
      });
    }
  };

  const handleSubmitSimulacao = async () => {
    const { isValid, body, errors } = getBodySimulacaoSaque(
      simulacaoSaque,
      attendance,
      dispatch,
      tipoSaque
    );

    if (!isValid) {
      setErrors(errors);
      console.log("Errors => ", errors);
      return;
    }

    setIsLoadingSimulacao(true);
    try {
      const { data } = await simulacaoPOST(body);

      setSimulacaoSaque(parseSimulacaoSaquePOST(data));

      handleFinalDigitacao(data);
    } catch (e: any) {
      const errorMessage = e?.response?.data?.msgInterface;
      if (errorMessage !== "Simulação não encontrada") {
        Toast({ title: errorMessage, status: "error" });
      }
    } finally {
      setIsLoadingSimulacao(false);
    }
  };

  const getSimulacaoResult = async () => {
    setIsLoadingSimulacao(true);
    try {
      const { data } = await simulacaoGET();

      setSimulacaoSaque(parseSimulacaoSaqueGET(data));
    } catch (e: any) {
      const errorMessage = e?.response?.data?.msgInterface;

      if (errorMessage !== "Simulação não encontrada") {
        Toast({
          title: "Erro ao carregar simulações anteriores",
          status: "error",
        });
      }
    } finally {
      setIsLoadingSimulacao(false);
    }
  };

  useEffect(() => {
    getSimulacaoResult();
  }, []);

  useEffect(() => {
    if (simulacaoSaque.banco) {
      modalApi.getTabelasDigitacao(
        simulacaoSaque.banco,
        attendance.convenio!,
        setTabelasList,
        setIsLoading
      );
    }
  }, [simulacaoSaque.banco]);

  const dinamicLimit = useBreakpointValue({ base: 30, "2xl": 40 });

  const limitDesc = telaAtendimento ? 85 : dinamicLimit!;

  const tabelaDesc =
    selectedTabela?.descricao?.length! > limitDesc
      ? selectedTabela?.descricao?.slice(0, limitDesc) + "..."
      : selectedTabela?.descricao;

  return (
    <>
      <Grid templateColumns="1fr 1fr" columnGap="20px" rowGap="40px">
        <Box>
          <InputField
            title="Valor parcela"
            inputProps={{ variant: "outline-custom" }}
            value={currencyMaskFormat(simulacaoSaque.valueInstallment).value}
            onChange={(e) => {
              onChangeSimulacaoSaque(
                "valueInstallment",
                currencyMaskFormat(e.target.value).raw
              );
              setErrors((errors) => {
                delete errors["margin"];
                return { ...errors };
              });
            }}
            errorMessage={errors["valueInstallment"] || errors["margin"]}
          />
        </Box>
        <Box>
          <DropdownField
            title="Banco"
            dropdownProps={{ variant: "outline-custom", w: "100%" }}
            isDisabled
            value={simulacaoSaque.banco}
            onChange={(value) => {
              onChangeSimulacaoSaque("banco", value);
              onChangeSimulacaoSaque("codeTable", undefined);
              onChangeSimulacaoSaque("valorLimiteCartao", undefined);
              onChangeSimulacaoSaque("observacao", undefined);
            }}
            options={bancosOptions}
            errorMessage={errors["banco"]}
          />
        </Box>
        <Box gridArea="auto / span 2">
          <DropdownField
            title="Prazo"
            dropdownProps={{ variant: "outline-custom", w: "100%" }}
            value={simulacaoSaque.numberInstallments}
            onChange={(value) => {
              onChangeSimulacaoSaque("numberInstallments", value);
              onChangeSimulacaoSaque("valorLimiteCartao", undefined);
              onChangeSimulacaoSaque("observacao", undefined);
            }}
            options={dropdownPrazo.map(({ name, value }) => ({
              name,
              value: Number(value),
            }))}
            errorMessage={errors["numberInstallments"]}
          />
        </Box>
        <Box gridArea="auto / span 2">
          <DropdownField
            title="Tabelas"
            dropdownProps={{
              w: "100%",
              variant: "outline-custom",
              children: tabelaDesc,
            }}
            value={simulacaoSaque.codeTable}
            onChange={(value) => {
              onChangeSimulacaoSaque("codeTable", value);
              onChangeSimulacaoSaque("valorLimiteCartao", undefined);
              onChangeSimulacaoSaque("valorSaquePrevisto", undefined);
              onChangeSimulacaoSaque("observacao", undefined);
            }}
            options={tabelasList}
            errorMessage={errors["codeTable"]}
            isLoading={isLoading}
          />
        </Box>

        <Box gridArea="auto / span 2">
          <AttendanceInput
            title="Valor Saque"
            inputProps={{ variant: "outline-custom" }}
            value={currencyMaskFormat(simulacaoSaque.valorLimiteCartao).value}
            errorMessage={errors["valorLimiteCartao"]}
            customTag={
              simulacaoSaque.valorLimiteCartao ? "SIMULADO" : undefined
            }
            isReadyOnly
          />
        </Box>

        <Box gridArea="auto / span 2">
          <AttendanceInput
            title="Observação"
            inputProps={{
              color: "red.500",
              as: "textarea",
              height: "60px",
              minH: "60px",
              resize: "none",
              variant: "outline-custom",
            }}
            value={simulacaoSaque.observacao}
            customTag={simulacaoSaque.observacao ? "SIMULADO" : undefined}
            isReadyOnly
          />
        </Box>

        <Box />
        <Flex justifyContent="flex-end">
          <ButtonWithLoading
            tooltipMessage={
              isDisabledDigitar
                ? `Serviço desabilitado para o banco ${simulacaoSaque.banco}`
                : undefined
            }
            mr="8px"
            onClick={async () => {
              if (isDisabledDigitar) return;
              if (formControl.pendingChanges) {
                return Toast({ title: "Salve as alterações do atendimento" });
              }

              openModalDigitacaoSaque({
                simulacao: simulacaoSaque,
                modalConfig: { allDisabled: false },
                digitacaoFields: { tipoSaque: tipoSaque },
              });
            }}
            opacity={isDisabledDigitar ? "0.5" : ""}
            isDisabled={simulacaoSaque.status !== "FINISHED"}
          >
            Digitar
          </ButtonWithLoading>{" "}
          <ButtonWithLoading
            tooltipMessage={
              isDisabledSimular
                ? `Serviço desabilitado para o banco ${simulacaoSaque.banco}`
                : undefined
            }
            mr="8px"
            onClick={async () => {
              if (isDisabledSimular) return;
              if (simulacaoSaque.status === "PENDENT") {
                getSimulacaoResult();
              } else {
                handleSubmitSimulacao();
              }
            }}
            isLoading={isLoadingSimulacao}
            opacity={isDisabledSimular ? "0.5" : ""}
          >
            {simulacaoSaque.status === "PENDENT" ? "Atualizar" : "Simular"}
          </ButtonWithLoading>{" "}
        </Flex>
      </Grid>
      <ModalDigitacaoSaque onChangeTab={onChangeTab} />
    </>
  );
}

export const bancosSimulacaoSaque = [
  // { name: "Bmg", value: "BMG" },
  // { name: "Master", value: "MASTER" },
  { name: "Pan", value: "PAN" },
];
