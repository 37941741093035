import { Box, Text, TextProps } from "@chakra-ui/react";
import { Dropdown, DropdownProps } from "components/dropdown";
import { PopoverProps } from "components/popover";
import { useRef } from "react";

export interface DropdownFieldProps {
  title?: string;
  value?: any;
  options?: DropdownProps["options"];
  onChange?: DropdownProps["onChange"];
  isInvalid?: boolean;
  errorMessage?: string;
  dropdownProps?: DropdownProps;
  titleProps?: TextProps;
  popoverProps?: Omit<PopoverProps, "children" | "button">;
  messageErrorProps?: TextProps;
  onOpen?: (target: HTMLDivElement) => void;
  onClose?: (target: HTMLDivElement) => void;
  isDisabled?: boolean;
  isLoading?: boolean;
}

export function DropdownField({
  title,
  value,
  isInvalid,
  errorMessage,
  options,
  onChange,
  titleProps,
  dropdownProps,
  popoverProps,
  messageErrorProps,
  onOpen,
  onClose,
  isDisabled,
  isLoading,
}: DropdownFieldProps) {
  const errorBox = useRef<HTMLDivElement>(null);

  const onFocusIn = () => {
    if (errorMessage && errorBox.current) {
      errorBox.current.style.transform = `scale(0.8)`;
      errorBox.current.style.visibility = `hidden`;
      errorBox.current.style.opacity = `0`;
    }
  };
  const onFocusOut = () => {
    if (errorBox.current) {
      errorBox.current.style.transform = ``;
      errorBox.current.style.visibility = ``;
      errorBox.current.style.opacity = ``;
    }
  };

  return (
    <>
      {title ? (
        <Text mb="8px" fontWeight="medium" {...titleProps}>
          {title}
        </Text>
      ) : null}
      <Box pos="relative" role="group">
        <Dropdown
          options={options}
          value={value}
          onChange={onChange}
          isInvalid={isInvalid || !!errorMessage}
          onCloseEvent={(target) => {
            onFocusOut();
            onClose?.(target);
          }}
          onOpenEvent={(target) => {
            onFocusIn();
            onOpen?.(target);
          }}
          isDisabled={isDisabled}
          isLoading={isLoading}
          {...dropdownProps}
        />

        {errorMessage ? (
          <Box
            ref={errorBox}
            bg="secondary.700"
            minW="26px"
            maxW="80%"
            pos="absolute"
            borderRadius="4px"
            p="3px"
            right={0}
            top="25px"
            zIndex="10"
            border="1px solid var(--chakra-colors-secondary-400)"
            transition="0.2s"
            transform="scale(0.8)"
            visibility="hidden"
            opacity={0}
            _groupHover={{
              transform: "scale(1)",
              visibility: "visible",
              opacity: "1",
            }}
          >
            <Box
              w="10px"
              h="10px"
              zIndex="-1"
              transform="rotate(45deg)"
              top="-6px"
              bg="secondary.700"
              right="10px"
              pos="absolute"
              borderTop="1px solid var(--chakra-colors-secondary-400)"
              borderLeft="1px solid var(--chakra-colors-secondary-400)"
              borderRadius="0 0 10px 0"
            />
            <Text
              color="#fff"
              fontWeight="bold"
              fontSize="9"
              whiteSpace="pre-wrap"
              {...messageErrorProps}
            >
              {errorMessage}&nbsp;
            </Text>
          </Box>
        ) : null}
      </Box>
    </>
  );
}
