import { mapObjectToObject } from "components/atendimentos-components/atendimento-form/functions/mapeamentos";
import { KeyForward } from "components/atendimentos-components/atendimento-form/functions/mapeamentos/types";
import { factaCitiesArray } from "components/atendimentos-components/atendimento-form/step-form/tabs-components/digitacao-fields/parts/cities-array";
import {
  maskDateFormat,
  UFsBrasil,
} from "components/atendimentos-components/atendimento-form/step-form/tabs-components/digitacao-fields/parts/const";
import { Attendance } from "components/atendimentos-components/atendimento-form/types";
import { Toast } from "components/toast";
import { fieldValidation } from "utils/field-validation";

export function copyAttendanceDataToDigitacaoINSS(data: Attendance) {
  let formularioFactaFGTS = data.formularioFactaFGTS;
  const possiveisCidades = factaCitiesArray.filter((crr) => {
    return (
      crr.value.uf === data.uf && crr.value.cityName.includes(data.cidade!)
    );
  });
  console.log(possiveisCidades);

  const keyForward: KeyForward<Attendance> = {
    cpf: (value) => {
      formularioFactaFGTS = { ...formularioFactaFGTS, cpf: value };
      return { data: formularioFactaFGTS, field: "formularioFactaFGTS" };
    },
    ufEmissaoDoc: (value) => {
      if (UFsBrasil.find((crr) => crr.value === value))
        formularioFactaFGTS = {
          ...formularioFactaFGTS,
          documentEmissionState: value,
        };
      return { data: formularioFactaFGTS, field: "formularioFactaFGTS" };
    },
    nome: (value) => {
      formularioFactaFGTS = { ...formularioFactaFGTS, name: value };
      return { data: formularioFactaFGTS, field: "formularioFactaFGTS" };
    },
    dataNascimento: (value) => {
      const format = maskDateFormat(value).raw;
      const { isValid } = fieldValidation({ value }).date();
      if (isValid)
        formularioFactaFGTS = { ...formularioFactaFGTS, birthDay: format };
      return { data: formularioFactaFGTS, field: "formularioFactaFGTS" };
    },

    estadoCivil: (value) => {
      formularioFactaFGTS = { ...formularioFactaFGTS, civilStatus: value };
      return { data: formularioFactaFGTS, field: "formularioFactaFGTS" };
    },
    documento: (value) => {
      formularioFactaFGTS = { ...formularioFactaFGTS, documentNumber: value };
      return { data: formularioFactaFGTS, field: "formularioFactaFGTS" };
    },
    dataEmissaoDoc: (value) => {
      const format = maskDateFormat(value).raw;
      const { isValid } = fieldValidation({ value }).date();
      if (isValid)
        formularioFactaFGTS = { ...formularioFactaFGTS, documentDate: format };
      return { data: formularioFactaFGTS, field: "formularioFactaFGTS" };
    },
    nomeMae: (value) => {
      formularioFactaFGTS = { ...formularioFactaFGTS, mothersName: value };
      return { data: formularioFactaFGTS, field: "formularioFactaFGTS" };
    },
    nomePai: (value) => {
      formularioFactaFGTS = { ...formularioFactaFGTS, fathersName: value };
      return { data: formularioFactaFGTS, field: "formularioFactaFGTS" };
    },
    valorSalario: (value) => {
      if (value)
        formularioFactaFGTS = { ...formularioFactaFGTS, incomeValue: value };
      return { data: formularioFactaFGTS, field: "formularioFactaFGTS" };
    },

    endereco: (value) => {
      formularioFactaFGTS = { ...formularioFactaFGTS, addressStreet1: value };
      return { data: formularioFactaFGTS, field: "formularioFactaFGTS" };
    },
    numero: (value) => {
      value = value?.replace(/\D/g, "");
      if (Number(value) !== 0)
        formularioFactaFGTS = { ...formularioFactaFGTS, addressNumber1: value };
      return { data: formularioFactaFGTS, field: "formularioFactaFGTS" };
    },
    complemento: (value) => {
      formularioFactaFGTS = {
        ...formularioFactaFGTS,
        addressComplement1: value,
      };
      return { data: formularioFactaFGTS, field: "formularioFactaFGTS" };
    },
    cidade: (value) => {
      if (possiveisCidades.length === 1) {
        formularioFactaFGTS = {
          ...formularioFactaFGTS,
          addressCity1: possiveisCidades[0].value.cityName,
          addressStateCode1: possiveisCidades[0].value.uf,
        };
      }
      return { data: formularioFactaFGTS, field: "formularioFactaFGTS" };
    },
    bairro: (value) => {
      if (value === "NULL") {
      } else
        formularioFactaFGTS = { ...formularioFactaFGTS, addressNeigh1: value };
      return { data: formularioFactaFGTS, field: "formularioFactaFGTS" };
    },
    cep: (value) => {
      formularioFactaFGTS = { ...formularioFactaFGTS, addressZipCode1: value };
      return { data: formularioFactaFGTS, field: "formularioFactaFGTS" };
    },
    codBancoPagamento: (value) => {
      formularioFactaFGTS = { ...formularioFactaFGTS, bankNumber: value };
      return { data: formularioFactaFGTS, field: "formularioFactaFGTS" };
    },
    sexo: (value) => {
      if (value === "FEMININO")
        formularioFactaFGTS = { ...formularioFactaFGTS, gender: "F" };
      else if (value === "MASCULINO")
        formularioFactaFGTS = { ...formularioFactaFGTS, gender: "M" };
      return { data: formularioFactaFGTS, field: "formularioFactaFGTS" };
    },
  };

  Toast({ title: "Dados do atendimento copiados" });

  return mapObjectToObject({ keyForward, data })?.formularioFactaFGTS;
}

export const organCodeOptions = [
  { name: "APOSENTADO", value: "000501" },
  { name: "PENSIONISTA", value: "000502" },
];
