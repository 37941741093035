import { Dispatch, SetStateAction } from "react";
import {
  CheckFieldProps,
  MailingFilterArray,
  MailingFilterMap,
  MailingFilterProps,
} from "./types";
import { InputFilter } from "./mailing-filter/filters-components/input-filter";
import { currencyMaskFormat, integerInputFormat } from "utils/string-formats";
import { CheckboxesFilter } from "./mailing-filter/filters-components/checkboxes";
import { KeyWordsFilter } from "./mailing-filter/filters-components/key-words";
import { BooleanRadioFilter } from "./mailing-filter/filters-components/boolean-radio";
import { InputListSearch } from "./mailing-filter/filters-components/input-list-search";
import { banks } from "./mailing-filter/consts";
import { DropdownMailingFilter } from "./mailing-filter/filters-components/dropdown";
import { AnimateElement } from "utils/animations";
import { Text } from "@chakra-ui/react";
import { TipoOperacaoMailing } from "../mailing-upload-modal/consts";
import { openModalConfirm } from "components/modal-confirm-new";
import { Toast } from "components/toast";
import { UFsBrasil } from "components/atendimentos-components/atendimento-form/step-form/tabs-components/digitacao-fields/parts/const";
import { municipiosFGTSList } from "./municipios-fgts-list";
import { SecaoCnaeSearch } from "./mailing-filter/filters-components/secao-cnae";

const ufList = [
  "AC",
  "AL",
  "AP",
  "AM",
  "BA",
  "CE",
  "DF",
  "ES",
  "GO",
  "MA",
  "MS",
  "MT",
  "MG",
  "PA",
  "PB",
  "PR",
  "PE",
  "PI",
  "RJ",
  "RN",
  "RS",
  "RO",
  "RR",
  "SC",
  "SP",
  "SE",
  "TO",
];

export const especieCodeList = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 15, 18, 19, 20, 21, 22, 23, 24, 25,
  26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44,
  45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63,
  64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82,
  83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99,
];

export const especieCodeListConsignaveis = [
  1, 2, 3, 4, 5, 6, 7, 8, 18, 19, 20, 21, 22, 23, 24, 26, 27, 28, 29, 32, 33,
  34, 37, 38, 41, 42, 43, 44, 45, 46, 49, 51, 52, 54, 55, 56, 57, 58, 59, 60,
  72, 78, 81, 82, 83, 84, 87, 88, 89, 92, 93, 96,
];

const especieCodeListNaoConsignaveis = especieCodeList.filter(
  (curr) => !especieCodeListConsignaveis.includes(curr)
);

const especieCodeListTradicionais = [21, 41, 42, 46, 57, 93];

const especieCodeListNaoTradicionais = [
  1, 2, 3, 7, 8, 9, 20, 22, 23, 24, 26, 27, 28, 37, 38, 43, 45, 49, 52, 54, 55,
  56, 58, 59, 60, 78, 81, 84, 89, 96,
];

const especieCodeListInvalidez = [4, 5, 6, 32, 33, 34, 51, 83, 92];

export const conveniosOptions = [
  { name: "INSS", value: "INSS" },
  { name: "Prefeitura", value: "pref" },
  { name: "Governo", value: "gov" },
  { name: "FGTS", value: "FGTS" },
];

export const mailingConveniosOptions = [
  { name: "INSS", value: "INSS" },
  { name: "Prefeitura", value: "pref" },
  { name: "Governo", value: "gov" },
  { name: "FGTS", value: "FGTS" },
  { name: "Consig. Trabalhador", value: "CONSIGNADO_TRABALHADOR" },
];

const especieHeaderOptions = [
  {
    name: "Todos",
    options: especieCodeList,
  },
  {
    name: "Todos Consignáveis",
    options: especieCodeListConsignaveis,
  },
  {
    name: "Não Consignáveis",
    options: especieCodeListNaoConsignaveis,
  },
  {
    name: "Tradicionais",
    options: especieCodeListTradicionais,
  },
  {
    name: "Não Tradicionais",
    options: especieCodeListNaoTradicionais,
  },
  {
    name: "Invalidez",
    options: especieCodeListInvalidez,
  },
];

function checkBetween(values: any[] | undefined): CheckFieldProps {
  const includeEmptyFields =
    values?.includes(undefined) || values?.includes("");
  if (values?.length === 2 && !includeEmptyFields) {
    if (values?.[0] <= values?.[1]) return { isValid: true };
    else
      return {
        isValid: false,
        errorMessage: "O primeiro valor deve ser menor ou igual ao segundo",
      };
  } else {
    return { isValid: false, errorMessage: "Prencha os campos corretamente" };
  }
}

function checkBetweenDates(values: any[] | undefined): CheckFieldProps {
  const includeEmptyFields =
    values?.includes(undefined) || values?.includes("");
  if (values?.length === 2 && !includeEmptyFields) {
    if (new Date(values[0]).getTime() <= new Date(values[1]).getTime())
      return { isValid: true };
    else
      return {
        isValid: false,
        errorMessage: "Período inválido",
      };
  } else {
    return { isValid: false, errorMessage: "Prencha os campos corretamente" };
  }
}

function checkUniqueField(values: any[] | undefined): CheckFieldProps {
  if (values?.[0] != null) return { isValid: true };
  else return { isValid: false, errorMessage: "Campo Obrigatório" };
}

const formatFieldRange = (value: any, op: any) =>
  op === "between" ? value : null;

const formatBoolean = (value: any, op: any) => !!value[0];

const formatSimpleValue = (value: any, op: any) => value;

export function mailingFilters({
  onChangeFilter,
  setInvalidFields,
  filtersValues,
  invalidFields,
  novaApi,
}: {
  onChangeFilter: MailingFilterProps["onChangeFilter"];
  filtersValues: MailingFilterProps["filtersValues"];
  invalidFields: { [k: string]: string };
  setInvalidFields: Dispatch<SetStateAction<{ [k: string]: string }>>;
  novaApi: boolean;
}): MailingFilterArray[] {
  return [
    {
      key: novaApi ? "SALARIO" : "salario",
      isVisible: (convenio) =>
        ["INSS", "pref", "gov", "CONSIGNADO_TRABALHADOR"].includes(convenio),
      checkField: (operation, values) => checkBetween(values),
      format: formatFieldRange,
      render: (
        <InputFilter
          title="Salário"
          description="Valor do salário do lead ou servidor"
          mapKey={novaApi ? "SALARIO" : "salario"}
          operators={{ Range: "between" }}
          onChangeFilter={onChangeFilter}
          filtersValues={filtersValues}
          invalidFields={invalidFields}
          formatValue={currencyMaskFormat}
        />
      ),
    },
    {
      key: novaApi ? "IDADE" : "idade",
      isVisible: (convenio) =>
        ["gov", "pref", "INSS", "CONSIGNADO_TRABALHADOR"].includes(convenio),
      checkField: (operation, values) => checkBetween(values),
      format: formatFieldRange,
      render: (
        <InputFilter
          title="Idade"
          description="Idade do servidor ou lead"
          mapKey={novaApi ? "IDADE" : "idade"}
          operators={{ Range: "between" }}
          onChangeFilter={onChangeFilter}
          filtersValues={filtersValues}
          invalidFields={invalidFields}
          inputProps={{ type: "number" }}
        />
      ),
    },
    {
      key: novaApi ? "DDB" : "ddb",
      isVisible: (convenio) => ["INSS"].includes(convenio),
      checkField: (operation, values) => {
        const operations: { [k: string]: CheckFieldProps } = {
          between: checkBetweenDates(values),
          equal: checkUniqueField(values),
        };
        return operations[operation];
      },
      format: formatFieldRange,
      render: (
        <InputFilter
          title="DDB - Início do Benefício (despacho do benefício)"
          description="Período do despacho do benefício"
          mapKey={novaApi ? "DDB" : "ddb"}
          operators={
            novaApi
              ? { Range: "between" }
              : { Range: "between", Igual: "equal" }
          }
          onChangeFilter={onChangeFilter}
          filtersValues={filtersValues}
          invalidFields={invalidFields}
          inputProps={{ type: novaApi ? "date" : "month" }}
          formatValue={(value) => ({ value, raw: value })}
        />
      ),
    },
    {
      key: novaApi ? "POSSUI_REPRESENTANTE_LEGAL" : "representante_legal",
      isVisible: (convenio) => ["INSS"].includes(convenio),
      format: formatBoolean,
      render: (
        <BooleanRadioFilter
          title="Possui Representante Legal"
          description="Se o beneficiario tem cartão ou não"
          mapKey={
            novaApi ? "POSSUI_REPRESENTANTE_LEGAL" : "representante_legal"
          }
          operators={{ Sim: 1, Não: 0 }}
          onChangeFilter={onChangeFilter}
          filtersValues={filtersValues}
          parseValue={{ 0: "equal", 1: "equal" }}
        />
      ),
    },
    {
      key: novaApi ? "BANCO_PAGADOR" : "bancoPag",
      isVisible: (convenio) => ["INSS"].includes(convenio),
      format: formatSimpleValue,
      checkField: (operation, values) => {
        const isValid = values?.length > 0;
        const errorMessage = "Adicione pelo menos um código de banco";
        return { isValid, errorMessage: !isValid ? errorMessage : undefined };
      },
      render: (
        <InputListSearch
          title="Banco Pagador"
          description="Código do banco que o lead recebe o benefício (Banco pagador)"
          mapKey={novaApi ? "BANCO_PAGADOR" : "bancoPag"}
          operators={{ Igual: "in", Diferente: "not_in" }}
          onChangeFilter={onChangeFilter}
          filtersValues={filtersValues}
          invalidFields={invalidFields}
          inputProps={{ placeholder: "Pesquise por bancos" }}
          options={banks}
          inputSearchProps={{
            optionLabelRender: (bank) => `${bank.value} - ${bank.name}`,
          }}
          getOptionByValue={(codBanco) =>
            banks.find((banco) => Number(banco.value) === codBanco)!
          }
          parseValue={(optionValue) => Number(optionValue)}
        />
      ),
    },
    {
      key: novaApi ? "POSSUI_CARTAO_RMC" : "possui_cartao",
      isVisible: (convenio) => ["INSS"].includes(convenio),
      format: formatBoolean,
      render: (
        <BooleanRadioFilter
          title="Possui Cartão RMC"
          description="Se o beneficiario tem cartão ou não"
          mapKey={novaApi ? "POSSUI_CARTAO_RMC" : "possui_cartao"}
          operators={{ Sim: 1, Não: 0 }}
          onChangeFilter={onChangeFilter}
          filtersValues={filtersValues}
          parseValue={{ 0: "equal", 1: "equal" }}
        />
      ),
    },
    {
      key: novaApi ? "MARGEM_DISPONIVEL" : "margem",
      isVisible: (convenio) => ["INSS"].includes(convenio),
      checkField: (operation, values) => checkBetween(values),
      format: formatFieldRange,
      render: (
        <InputFilter
          title="Margem Disponível"
          description="Valor da margem do lead disponível para consignação"
          mapKey={novaApi ? "MARGEM_DISPONIVEL" : "margem"}
          operators={{ Range: "between" }}
          onChangeFilter={onChangeFilter}
          filtersValues={filtersValues}
          invalidFields={invalidFields}
          formatValue={currencyMaskFormat}
        />
      ),
    },
    {
      key: novaApi ? "MARGEM_RMC" : "margem_rmc",
      isVisible: (convenio) => ["INSS"].includes(convenio),
      checkField: (operation, values) => checkBetween(values),
      format: formatFieldRange,
      render: (
        <InputFilter
          title="Margem RMC"
          description="Valor da margem  para cartão do lead disponível para consignação"
          mapKey={novaApi ? "MARGEM_RMC" : "margem_rmc"}
          operators={{ Range: "between" }}
          onChangeFilter={onChangeFilter}
          filtersValues={filtersValues}
          invalidFields={invalidFields}
          formatValue={currencyMaskFormat}
        />
      ),
    },
    {
      key: novaApi ? "ESPECIE" : "especie",
      isVisible: (convenio) => ["INSS"].includes(convenio),
      format: formatSimpleValue,
      render: (
        <CheckboxesFilter
          title="Espécie"
          description="Código da espécie do benefício recebido pelo lead"
          mapKey={novaApi ? "ESPECIE" : "especie"}
          operators={{ Igual: "in" }}
          onChangeFilter={onChangeFilter}
          filtersValues={filtersValues}
          options={especieCodeList}
          headerOptions={especieHeaderOptions}
        />
      ),
    },
    {
      key: novaApi ? "UF" : "uf",
      isVisible: (convenio) =>
        ["INSS", "CONSIGNADO_TRABALHADOR"].includes(convenio),
      format: formatSimpleValue,
      render: (
        <CheckboxesFilter
          title="UF"
          description="Estado do endereço dos leads"
          mapKey={novaApi ? "UF" : "uf"}
          operators={{ Igual: "in" }}
          onChangeFilter={onChangeFilter}
          filtersValues={filtersValues}
          options={ufList}
          headerOptions={[{ name: "Todos", options: ufList }]}
        />
      ),
    },
    {
      key: novaApi ? "CIDADE" : "cidade",
      isVisible: (convenio) => ["INSS"].includes(convenio),
      checkField: (operation, values) => checkUniqueField(values),
      format: formatSimpleValue,
      render: (
        <KeyWordsFilter
          title="Cidade"
          description="Município do endereço dos leads"
          mapKey={novaApi ? "CIDADE" : "cidade"}
          operators={{ Igual: "in", Diferente: "not_in" }}
          onChangeFilter={onChangeFilter}
          filtersValues={filtersValues}
          invalidFields={invalidFields}
          inputProps={{ placeholder: "Adicionar cidades" }}
        />
      ),
    },
    // {
    //   key:  novaApi ? "bancoOp" : "bancoOperacao" ,
    //   isVisible: (convenio) => ["INSS"].includes(convenio),
    //   checkField: (operation, values) => {
    //     const isValid = values?.length > 0;
    //     const errorMessage = "Adicione pelo menos um código de banco";
    //     return { isValid, errorMessage: !isValid ? errorMessage : undefined };
    //   },
    //   render: (
    //     <BankListSearch
    //       title="Banco Operação"
    //       description="Código do banco da parcela contratada pelo cliente (Banco da operação)"
    //       mapKey="bancoOperacao"
    //       operators={{ Igual: "in", Diferente: "not_in" }}
    //       onChangeFilter={onChangeFilter}
    //       filtersValues={filtersValues}
    //       invalidFields={invalidFields}
    //       inputProps={{ placeholder: "Pesquise por bancos" }}
    //       options={banks}
    //     />
    //   ),
    // },
    {
      key: novaApi ? "TAXA_CONTRATO" : "taxa",
      isVisible: (convenio) => ["INSS"].includes(convenio),
      checkField: (operation, values) => checkBetween(values),
      format: formatFieldRange,
      render: (
        <InputFilter
          title="Taxa Contrato"
          description="Filtro taxa contrato ativos"
          mapKey={novaApi ? "TAXA_CONTRATO" : "taxa"}
          operators={{ Range: "between" }}
          onChangeFilter={onChangeFilter}
          filtersValues={filtersValues}
          invalidFields={invalidFields}
          formatValue={currencyMaskFormat}
        />
      ),
    },
    {
      key: novaApi ? "VALOR_PARCELAS" : "vl_parcelas",
      isVisible: (convenio) => ["gov", "pref", "INSS"].includes(convenio),
      checkField: (operation, values) => checkBetween(values),
      format: formatFieldRange,
      render: (
        <InputFilter
          title="Valor das Parcelas"
          description="Valor das parcelas dos contratos"
          mapKey={novaApi ? "VALOR_PARCELAS" : "vl_parcelas"}
          operators={{ Range: "between" }}
          onChangeFilter={onChangeFilter}
          filtersValues={filtersValues}
          invalidFields={invalidFields}
          formatValue={integerInputFormat}
        />
      ),
    },
    {
      key: novaApi ? "QUANTIDADE_PARCELAS_PAGAS" : "quantidade_parcela_paga",
      isVisible: (convenio) => ["INSS"].includes(convenio),
      checkField: (operation, values) => checkBetween(values),
      format: formatFieldRange,
      render: (
        <InputFilter
          title="Quantidade de Parcelas Pagas"
          description="Filtro de quantidade de parcelas pagas"
          mapKey={
            novaApi ? "QUANTIDADE_PARCELAS_PAGAS" : "quantidade_parcela_paga"
          }
          operators={{ Range: "between" }}
          onChangeFilter={onChangeFilter}
          filtersValues={filtersValues}
          invalidFields={invalidFields}
          formatValue={integerInputFormat}
        />
      ),
    },
    {
      key: novaApi ? "QUANTIDADE_EMPRESTIMOS" : "qt_emprestimos",
      isVisible: (convenio) => ["INSS"].includes(convenio),
      checkField: (operation, values) => checkBetween(values),
      format: formatFieldRange,
      render: (
        <InputFilter
          title="Quantidade de Empréstimos"
          description="Filtro quantidade de empréstimos"
          mapKey={novaApi ? "QUANTIDADE_EMPRESTIMOS" : "qt_emprestimos"}
          operators={{ Range: "between" }}
          onChangeFilter={onChangeFilter}
          filtersValues={filtersValues}
          invalidFields={invalidFields}
          formatValue={integerInputFormat}
        />
      ),
    },
    {
      key: "uf_servidor",
      isVisible: (convenio) => ["gov", "pref"].includes(convenio),
      checkField: (operation, values) => checkUniqueField(values),
      format: formatSimpleValue,
      render: (
        <KeyWordsFilter
          title="UF Servidor"
          description="Estado do Endereço dos Servidores"
          mapKey="uf_servidor"
          operators={{ Igual: "in", Diferente: "not_in" }}
          onChangeFilter={onChangeFilter}
          filtersValues={filtersValues}
          invalidFields={invalidFields}
          inputProps={{ placeholder: "Adicione UFs ex.: SP" }}
        />
      ),
    },
    {
      key: "municipio_servidor",
      isVisible: (convenio) => ["gov", "pref"].includes(convenio),
      checkField: (operation, values) => checkUniqueField(values),
      format: formatSimpleValue,
      render: (
        <KeyWordsFilter
          title="Município Servidor"
          description="Município Servidor"
          mapKey="municipio_servidor"
          operators={{ Igual: "in", Diferente: "not_in" }}
          onChangeFilter={onChangeFilter}
          filtersValues={filtersValues}
          invalidFields={invalidFields}
        />
      ),
    },
    {
      key: novaApi ? "TIPO_EMPRESTIMO" : "tipo_emprestimo",
      isVisible: (convenio) => ["INSS"].includes(convenio),
      checkField: (operation, values) => checkUniqueField(values),
      format: formatSimpleValue,
      render: (
        <DropdownMailingFilter
          title="Tipo Empréstimo"
          description="Tipo de empréstimo dos leads"
          mapKey={novaApi ? "TIPO_EMPRESTIMO" : "tipo_emprestimo"}
          operators={{ Igual: "in" }}
          onChangeFilter={onChangeFilter}
          filtersValues={filtersValues}
          options={tipoEmprestimosOptions}
          invalidFields={invalidFields}
        />
      ),
    },
    {
      key: "UF",
      isVisible: (convenio) => ["FGTS"].includes(convenio),
      checkField: (operation, values) => checkUniqueField(values),
      format: formatSimpleValue,
      render: (
        <DropdownMailingFilter
          title="UF"
          description="Estado"
          mapKey="UF"
          operators={{ Igual: "in" }}
          onChangeFilter={onChangeFilter}
          filtersValues={filtersValues}
          options={UFsBrasil}
          invalidFields={invalidFields}
          containerProps={{ gridArea: "auto" }}
        />
      ),
    },
    {
      key: "CIDADE",
      isVisible: (convenio) => ["FGTS"].includes(convenio),
      format: formatSimpleValue,
      checkField: (operation, values) => {
        const isValid = values?.length > 0;
        const errorMessage = "Adicione pelo menos um código de banco";
        return { isValid, errorMessage: !isValid ? errorMessage : undefined };
      },
      render: (
        <InputListSearch
          title="Cidades"
          description="Cidades"
          mapKey={"CIDADE"}
          operators={{ Igual: "in" }}
          onChangeFilter={onChangeFilter}
          filtersValues={filtersValues}
          invalidFields={invalidFields}
          inputProps={{ placeholder: "Pesquise por cidades" }}
          options={municipiosFGTSList.filter((city) =>
            filtersValues.get("UF")?.value?.length
              ? filtersValues.get("UF")?.value?.includes(city.uf) ?? true
              : true
          )}
          inputSearchProps={{
            optionLabelRender: (option) => `${option.name} - ${option.uf}`,
          }}
          getOptionByValue={(cityCode) =>
            municipiosFGTSList.find((city) => city.value === cityCode)!
          }
          sequentialSearch
        />
      ),
    },
    {
      key: "SECAO_CNAE",
      isVisible: (convenio) => ["FGTS"].includes(convenio),
      format: formatSimpleValue,
      checkField: (operation, values) => {
        const isValid = values?.length > 0;
        const errorMessage = "Adicione pelo menos um código de banco";
        return { isValid, errorMessage: !isValid ? errorMessage : undefined };
      },
      render: (
        <SecaoCnaeSearch
          title="Seção CNAE"
          description="CNAEs"
          mapKey={"SECAO_CNAE"}
          operators={{ Igual: "in" }}
          onChangeFilter={onChangeFilter}
          filtersValues={filtersValues}
          invalidFields={invalidFields}
          inputProps={{ placeholder: "Pesquisar seções" }}
          options={cnaesList}
          inputSearchProps={{
            optionLabelRender: (option) => `${option.value} - ${option.name}`,
            openOnFocus: true,
          }}
          getOptionByValue={(cityCode) =>
            cnaesList.find((city) => city.value === cityCode)!
          }
        />
      ),
    },
    {
      key: "SALDO_FGTS",
      isVisible: (convenio) => ["FGTS"].includes(convenio),
      checkField: (operation, values) => checkBetween(values),
      format: formatFieldRange,
      render: (
        <InputFilter
          title="Saldo FGTS"
          description="Valor do saldo FGTS lead ou servidor"
          mapKey={"SALDO_FGTS"}
          operators={{ Range: "between" }}
          onChangeFilter={onChangeFilter}
          filtersValues={filtersValues}
          invalidFields={invalidFields}
          formatValue={currencyMaskFormat}
        />
      ),
    },
    {
      key: "CONSIDERAR_SECAO_CNAES_SECUNDARIAS",
      isVisible: (convenio) => ["FGTS"].includes(convenio),
      format: formatBoolean,
      render: null,
    },
    {
      key: "TEMPO_CONTRIBUICAO",
      isVisible: (convenio) => ["CONSIGNADO_TRABALHADOR"].includes(convenio),
      checkField: (operation, values) => checkBetween(values),
      format: formatFieldRange,
      render: (
        <InputFilter
          title="Tempo Contribuição (meses)"
          description="Tempo de contribuição previdenciária"
          mapKey={"TEMPO_CONTRIBUICAO"}
          operators={{ Range: "between" }}
          onChangeFilter={onChangeFilter}
          filtersValues={filtersValues}
          invalidFields={invalidFields}
          inputProps={{ type: "number" }}
          // formatValue={currencyMaskFormat}
          containerProps={{ gridArea: "auto / span 2" }}
        />
      ),
    },
  ];
}

const cnaesList = [
  {
    name: "AGRICULTURA, PECUÁRIA, PRODUÇÃO FLORESTAL, PESCA E AQÜICULTURA",
    value: "A",
  },
  {
    name: "INDÚSTRIAS EXTRATIVAS",
    value: "B",
  },
  {
    name: "INDÚSTRIAS DE TRANSFORMAÇÃO",
    value: "C",
  },
  {
    name: "ELETRICIDADE E GÁS",
    value: "D",
  },
  {
    name: "ÁGUA, ESGOTO, ATIVIDADES DE GESTÃO DE RESÍDUOS E DESCONTAMINAÇÃO",
    value: "E",
  },
  {
    name: "CONSTRUÇÃO",
    value: "F",
  },
  {
    name: "COMÉRCIO; REPARAÇÃO DE VEÍCULOS AUTOMOTORES E MOTOCICLETAS",
    value: "G",
  },
  {
    name: "TRANSPORTE, ARMAZENAGEM E CORREIO",
    value: "H",
  },
  {
    name: "ALOJAMENTO E ALIMENTAÇÃO",
    value: "I",
  },
  {
    name: "INFORMAÇÃO E COMUNICAÇÃO",
    value: "J",
  },

  {
    name: "ATIVIDADES FINANCEIRAS, DE SEGUROS E SERVIÇOS RELACIONADOS",
    value: "K",
  },

  {
    name: "ATIVIDADES IMOBILIÁRIAS",
    value: "L",
  },

  {
    name: "ATIVIDADES PROFISSIONAIS, CIENTÍFICAS E TÉCNICAS",
    value: "M",
  },

  {
    name: "ATIVIDADES ADMINISTRATIVAS E SERVIÇOS COMPLEMENTARES",
    value: "N",
  },

  {
    name: "ADMINISTRAÇÃO PÚBLICA, DEFESA E SEGURIDADE SOCIAL",
    value: "O",
  },

  {
    name: "EDUCAÇÃO",
    value: "P",
  },
  {
    name: "SAÚDE HUMANA E SERVIÇOS SOCIAIS",
    value: "Q",
  },
  {
    name: "ARTES, CULTURA, ESPORTE E RECREAÇÃO",
    value: "R",
  },
  {
    name: "OUTRAS ATIVIDADES DE SERVIÇOS",
    value: "S",
  },
  {
    name: "SERVIÇOS DOMÉSTICOS",
    value: "T",
  },
  {
    name: "ORGANISMOS INTERNACIONAIS E OUTRAS INSTITUIÇÕES EXTRATERRITORIAIS",
    value: "U",
  },
];

export const tipoEmprestimosOptions = [
  { name: "76 - Cartão RMC", value: 76 },
  { name: "44 - Cartão RCC", value: 44 },
  { name: "98 - Emprestimo Consignado", value: 98 },
];

export const validateGenerateMailingFields = ({
  filtersValues,
  filtersArray,
  invalidFields,
  setInvalidFields,
}: {
  filtersValues: Map<string, MailingFilterMap>;
  filtersArray: MailingFilterArray[];
  invalidFields: { [k: string]: string };
  setInvalidFields: Dispatch<SetStateAction<{ [k: string]: string }>>;
}) => {
  const keys = Array.from(filtersValues.entries());
  let isValidResult = true;
  const modal = document.getElementById("mailing-modal")!;
  let scrollY = modal?.scrollTop;
  let destination: number | null = null;
  keys.forEach(([key, currentFilter]) => {
    const { checkField } = filtersArray.find((crr) => crr.key === key)! || {};
    const { isValid, errorMessage } =
      checkField?.(currentFilter?.op!, currentFilter?.value)! || {};
    if (checkField != null && !isValid) {
      setInvalidFields({ ...invalidFields, [key]: errorMessage! });
      isValidResult = false;
      const target = document.getElementById(`mailing-filter-${key}`)!;
      const currYPos = minLimit(0, target?.offsetTop - 30);
      if (destination == null || currYPos < destination) destination = currYPos;
    }
  });
  if (!isValidResult) {
    AnimateElement(1000, [scrollY, destination!], (top) => {
      modal?.scrollTo({ top });
    });
  }
  return { isValid: isValidResult };
};

export function handleGenerateMailing({
  filtersValues,
  filtersArray,
  invalidFields,
  setInvalidFields,
  quantity,
  convenio,
  name,
  subConvenio,
  handleSubmit,
  tipoOperacaoData,
}: {
  filtersValues: Map<string, MailingFilterMap>;
  filtersArray: MailingFilterArray[];
  invalidFields: { [k: string]: string };
  setInvalidFields: Dispatch<SetStateAction<{ [k: string]: string }>>;
  quantity: string | undefined;
  name: string | undefined;
  convenio: string;
  subConvenio: string;
  tipoOperacaoData: {
    tipoOperacao: "" | TipoOperacaoMailing;
    bancosFromLabel: string | undefined;
    bancosToLabel: string | undefined;
    bankData: {
      bancosFrom: number[];
      bancosTo: number[];
    };
  };
  handleSubmit(): Promise<void>;
}) {
  const {
    bancosFromLabel,
    bancosToLabel,
    bankData: { bancosFrom, bancosTo },
    tipoOperacao,
  } = tipoOperacaoData;

  const { isValid } = validateGenerateMailingFields({
    filtersArray,
    filtersValues,
    invalidFields,
    setInvalidFields,
  });

  if (!isValid) return;

  const convenioData = ["gov", "pref"].includes(convenio)
    ? subConvenio
    : convenio;

  if (!convenioData) {
    const errorMessage: any = { gov: " governo", pref: "a prefeitura" };
    return Toast({
      title: `Escolha um${errorMessage[convenio] ?? " convênio"}!`,
    });
  }

  // Validação Tipo Operação
  if (!tipoOperacao) {
    return Toast({
      title: "Preecha o campo Tipo Operação!",
    });
  }
  if (bancosFromLabel) {
    if (!bancosFrom.length)
      return Toast({
        title: `Preecha o campo ${bancosFromLabel}!`,
      });
  }
  if (bancosToLabel) {
    if (!bancosTo.length)
      return Toast({
        title: `Preecha o campo ${bancosToLabel}!`,
      });
  }

  if (!quantity) {
    return Toast({
      title: "Preecha o Quantidade!",
    });
  }

  if (!name) {
    return Toast({ title: "Preecha o campo Nome!" });
  }

  openModalConfirm({
    message: (
      <Text>
        O custo previsto para a geração desse mailing é de até{" "}
        <b>R$ {(parseFloat(quantity) * 0.0025).toFixed(2).replace(".", ",")}</b>
        . Confirmar?
      </Text>
    ),
    onConfirm() {
      handleSubmit();
    },
    confirmButtonStyle: { variant: undefined },
  });
}

function minLimit(limit: number, number: number) {
  if (number < limit) return limit;
  return number;
}
