import {
  DeleteIcon,
  RepeatIcon,
  SettingsIcon,
  WarningTwoIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  IconButton,
  Progress,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import api from "api/api";
import { DownloadSMSRelatory } from "components/campanha-sms-components/download-sms-relatory";
import {
  ModalCampanhaSMS,
  openModalCampanhaSMS,
} from "components/campanha-sms-components/modal-campanha-sms";
import {
  ModalErrorsCampanhaSMS,
  openModalErrorsCampanhaSMS,
} from "components/campanha-sms-components/modal-errors-campanha-sms";
import { FunilDeDados } from "components/charts/funil-de-dados";
import DynamicTable from "components/dynamic-table";
import { RowStatus } from "components/dynamic-table/row-status";
import { Column } from "components/dynamic-table/types";
import { GetLayout } from "components/get-layout";
import { IconButtonWithLoading } from "components/mailing-components/mailing-table/icon-button-with-loading";
import { openModalConfirm } from "components/modal-confirm-new";
import { useEffect, useState } from "react";
import { FaPlay } from "react-icons/fa";
import BarsChart from "components/charts/bars-chart";
import { validateFilterCampanhaSMSBody } from "components/campanha-sms-components/download-sms-relatory/util";
import { Toast } from "components/toast";
import { getErrorMessage } from "utils/util";

export interface CampanhaSMSRow {
  id: string;
  nome: string;
  mensagem: string | null;
  idUser: number;
  idCustomer: number;
  idMailing: number | null;
  statusSms:
    | "AGUARDANDO CONFIGURAÇÃO"
    | "FINALIZADO"
    | "EM ANDAMENTO"
    | "AGUARDANDO INÍCIO"
    | null;
  statusCampanha:
    | "EM_ANDAMENTO"
    | "AGUARDANDO_CONFIGURACAO"
    | "NAO_INICIADA"
    | "CRIANDO_SMS"
    | "PROCESSANDO"
    | "ERRO"
    | "FINALIZADO"
    | null;
  quantidade: number | null;
  quantidadeMaximaTelLead: number | null;
  errorsLength: number;
  successLength: number;
  waitingLength: number;
  paused: boolean;
  dataCriacao: string;
  errorsMessages: { message: string; count: number }[];
  errorMessageCampanha: string | null;
  quantidadeCpf: number;
}

export interface FilterCampanhaSMS {
  dataInicial: string;
  dataFinal: string;
  campanhaIds: string[];
}

//quantidade solicitada (seria a soma de ENVIADO + ERRO)
//quantidade de envios,
//quantidade de respostas
//atendimentos iniciados

const initialValue = {
  campanhaIds: [],
  dataFinal: "",
  dataInicial: "",
};

const parse: any = {
  ENVIADO: "QUANTIDADE ENVIADOS",
  QTD_RESPOSTAS: "QUANTIDADE RESPOSTAS",
  QTD_ATENDIMENTOS: "QUANTIDADE ATENDIMENTOS",
  ERRO: "ERROS",
  NAO_ENTREGUE: "NÃO ENTREGUE",
  REJEITADO_BROKER: "REJEITADO BROKER",
  EXPIRADA: "EXPIRADA",
};

export const CampanhaSMS = () => {
  const [loading, setLoading] = useState({
    barsChart: false,
    powerhubChart: false,
    campaignList: false,
  });
  const [filteredCampanhas, setFilteredCampanhas] = useState<CampanhaSMSRow[]>(
    []
  );
  const [allCampanhas, setAllCampanhas] = useState<CampanhaSMSRow[]>([]);
  const [estatisticas, setEstatisticas] = useState<
    { name: string; value: number }[]
  >([]);
  const [barsChartData, setBarsChartData] = useState<
    { name: string; value: number }[]
  >([]);
  const [filter, setFilter] = useState<FilterCampanhaSMS>(initialValue);

  const colors = new Map([
    // ["ENVIADO_FILA", "#202c5f"],
    ["QUANTIDADE ENVIADOS", "#00c455"],
    ["QUANTIDADE RESPOSTAS", "#3E54B7"],
    ["QUANTIDADE ATENDIMENTOS", "#BEC6E9"],
    ["ERROS", "#aa0000"],
    ["QUANTIDADE SOLICITADA", "#202c5f"],
    // ["AGUARDANDO_ENVIO", "#3E54B7"],
    // ["TENTANDO_ENVIAR", "#BEC6E9"],
    // ["ENTREGUE", "#00c455"],
    // ["NAO_ENTREGUE", "#f13426"],
    // ["REJEITADO_BROKER", "#f6bc76"],
    // ["EXPIRADA", "#f6bc76"],
    // ["VALIDATION", "#BEC6E9"],
    // ["STATUS_EM_ANDAMENTO", "#BEC6E9"],
  ]);
  const estatisticsColors = estatisticas.map(({ name }) => colors.get(name)!);

  const getPowerhubChart = async () => {
    setLoading((loading) => ({ ...loading, powerhubChart: true }));
    const { isValid, body, errors } = validateFilterCampanhaSMSBody(filter);

    try {
      const { data }: { data: { quantidade: number; status: string }[] } =
        await api.post(`/campanha-sms/statistics`, isValid ? body : {});
      const errorsLength =
        data.find((curr) => curr.status === "ERRO")?.quantidade || 0;
      const enviadoLength =
        data.find((curr) => curr.status === "ENVIADO")?.quantidade || 0;
      const columns = [
        {
          status: "ENVIADO",
          quantidade: 0,
        },
        {
          status: "ERRO",
          quantidade: 0,
        },
        {
          status: "QTD_RESPOSTAS",
          quantidade: 0,
        },
        {
          status: "QTD_ATENDIMENTOS",
          quantidade: 0,
        },
      ];
      console.log(errorsLength + enviadoLength);
      setEstatisticas(
        [
          {
            status: "QUANTIDADE SOLICITADA",
            quantidade: errorsLength + enviadoLength,
          },
          ...columns,
        ].map((curr: { quantidade: number; status: string }) => {
          const quantidade =
            data.find((item) => {
              return item.status === curr.status;
            })?.quantidade ||
            (curr.status === "QUANTIDADE SOLICITADA" ? curr.quantidade : 0);

          return {
            name: parse[curr.status ?? ""] ?? curr.status,
            value: quantidade,
          };
        })
      );
    } catch (e) {
    } finally {
      setLoading((loading) => ({ ...loading, powerhubChart: false }));
    }
  };

  const getBarsChartData = async () => {
    setLoading((loading) => ({ ...loading, barsChart: true }));
    const { isValid, body, errors } = validateFilterCampanhaSMSBody(filter);
    try {
      const { data }: { data: { quantidade: number; status: string }[] } =
        await api.post(`/campanha-sms/statistics/kolmeya`, isValid ? body : {});

      const columns: { name: string; value: number }[] = [
        { name: "NAO_ENTREGUE", value: 0 },
        { name: "REJEITADO_BROKER", value: 0 },
        { name: "EXPIRADA", value: 0 },
      ];
      data.forEach((item) => {
        if (!columns.find((col) => col.name === item.status)) {
          columns.push({ name: item.status, value: item.quantidade });
        }
      });
      setBarsChartData(
        columns.map((item) => {
          const value =
            data.find((curr) => item.name === curr.status)?.quantidade || 0;
          return { name: parse[item.name] ?? item.name, value };
        })
      );
    } catch (e: any) {
      const errorMessage = getErrorMessage({
        error: e,
        defaultMessage: "Erro ao carregar",
      });
      Toast({ title: errorMessage, status: "error" });
    } finally {
      setLoading((loading) => ({ ...loading, barsChart: false }));
    }
  };

  useEffect(() => {
    getPowerhubChart();
    getBarsChartData();
  }, []);

  const formatCampanhas = (data: any) => {
    return Object.keys(data.result).map((key) => {
      const result = data.result as any;
      const crr = result[key] as any[];
      const firstData = crr[0];
      const errorsLength =
        crr.find((crr) => crr.statusSms === "ERRO")?.quantidade || 0;
      const successLength =
        crr.find((crr) => crr.statusSms === "ENVIADO")?.quantidade || 0;
      const waitingLength =
        crr.find((crr) => crr.statusSms === "AGUARDANDO_ENVIO")?.quantidade ||
        0;

      const totalMessages = errorsLength + successLength + waitingLength;
      const isFinally = (errorsLength + successLength) / totalMessages === 1;
      const isWaitingConfig = firstData.mensagem == null;
      const isPaused = firstData.paused === true;
      let status = "AGUARDANDO INÍCIO";
      if (isWaitingConfig) status = "AGUARDANDO CONFIGURAÇÃO";
      else if (isFinally) status = "FINALIZADO";
      else if (!isPaused) status = "EM ANDAMENTO";
      return {
        ...firstData,
        statusCampanha: firstData.statusCampanha,
        errorsLength,
        successLength,
        waitingLength,
        errorsMessages: crr
          .filter((campanha) => campanha.errorMessage)
          .map((c) => ({ message: c.errorMessage, count: c.quantidade })),
      };
    });
  };

  const initialLoading = async () => {
    setLoading((loading) => ({ ...loading, campaignList: true }));
    const { isValid, body, errors } = validateFilterCampanhaSMSBody(filter);

    try {
      const { data } = await api.post("/campanha-sms", isValid ? body : {});
      setFilteredCampanhas(formatCampanhas(data));
      setAllCampanhas(formatCampanhas(data));
    } catch (e: any) {
      const errorMessage = getErrorMessage({
        error: e,
        defaultMessage: "Erro ao obter lista de campanhas",
      });
      Toast({ title: errorMessage, status: "error" });
    } finally {
      setLoading((loading) => ({ ...loading, campaignList: false }));
    }
  };

  useEffect(() => {
    initialLoading();
  }, []);

  const retry =
    ({ id }: CampanhaSMSRow) =>
    async () => {
      try {
        const { data } = await api.put(`/campanha-sms/${id}/replay`);
        setFilteredCampanhas((prev) =>
          prev.map((row) => {
            if (row.id === id) row.statusCampanha = "CRIANDO_SMS";
            return row;
          })
        );
      } catch (e: any) {
        const errorMessage = getErrorMessage({
          error: e,
          defaultMessage: "Erro ao continuar campanha",
        });
        Toast({ title: errorMessage, status: "error" });
      } finally {
      }
    };

  const play =
    ({ id }: CampanhaSMSRow) =>
    async () => {
      try {
        const { data } = await api.put(`/campanha-sms/${id}/start`);
        setFilteredCampanhas((prev) =>
          prev.map((row) => {
            if (row.id === id) {
              row.statusCampanha = "CRIANDO_SMS";
            }
            return row;
          })
        );
      } catch (e: any) {
        const errorMessage = getErrorMessage({
          error: e,
          defaultMessage: "Erro ao continuar campanha",
        });
        Toast({ title: errorMessage, status: "error" });
      } finally {
      }
    };

  const updateCampaignList = async (withLoading = false) => {
    if (withLoading)
      setLoading((loading) => ({ ...loading, campaignList: true }));
    const { isValid, body, errors } = validateFilterCampanhaSMSBody(filter);

    if (!isValid) Toast({ title: "Filtro inválido", status: "error" });

    try {
      const response = await api.post("/campanha-sms", isValid ? body : {});
      setFilteredCampanhas(formatCampanhas(response.data));
    } catch (e: any) {
      const errorMessage = getErrorMessage({
        error: e,
        defaultMessage: "Erro ao carregar campanhas",
      });
      Toast({ title: errorMessage, status: "error" });
    } finally {
      if (withLoading)
        setLoading((loading) => ({ ...loading, campaignList: false }));
    }
  };

  const acceptDeleteCampanha = async (row: any) => {
    setLoading((loading) => ({ ...loading, campaignList: true }));
    try {
      await api.delete(`/campanha-sms/${row.id}`);
      await initialLoading();
    } catch (error: any) {
      const errorMessage = getErrorMessage({
        error,
        defaultMessage: "Erro ao excluir campanha!",
      });
      Toast({ title: errorMessage, status: "error" });
    }
    await updateCampaignList();
    setLoading((loading) => ({ ...loading, campaignList: false }));
  };

  const renderRowCampanhaStatus = (statusCampanha: any) => {
    switch (statusCampanha) {
      case "AGUARDANDO_CONFIGURACAO":
        return "AGUARDANDO CONFIGURAÇÃO";
      case "NAO_INICIADA":
        return "NÃO INICIADA";
      case "CRIANDO_SMS":
        return "CRIANDO SMSs";
      case "PROCESSANDO":
        return "PROCESSANDO";
      case "ERRO":
        return "ERRO";
      case "EM_ANDAMENTO":
        return "EM ANDAMENTO";
      case "FINALIZADO":
        return "FINALIZADO";
      default:
        break;
    }
  };

  const columns: Column[] = [
    {
      name: "Nome",
      key: "nome",
    },
    {
      name: "Dt. Criação",
      render: (row) => {
        return row.dataCriacao
          ? new Date(row.dataCriacao).toLocaleDateString()
          : null;
      },
    },
    {
      name: "Status",
      render: (row) => {
        return row.statusCampanha ? (
          <RowStatus status={row.statusCampanha}>
            {renderRowCampanhaStatus(row.statusCampanha)}
          </RowStatus>
        ) : null;
      },
      key: "status",
      sortClick: true,
    },
    {
      name: "Qtd Erros",
      render: (row) => {
        return row.errorsLength;
      },
    },
    {
      name: "Qtd de cpfs enviados",
      render: (row) => {
        return row.quantidadeCpf;
      },
    },
    {
      name: "Qtd Telefones Enviados",
      render: (row) => {
        const totalSended = row.errorsLength + row.successLength;
        const totalMessages =
          row.errorsLength + row.successLength + row.waitingLength;
        return (
          <Box>
            <Text mb="4px">
              {totalSended}/{totalMessages}
            </Text>
            <Progress
              value={(totalSended / totalMessages) * 100}
              size="xs"
              bg="gray.200"
              borderRadius="10"
              colorScheme="gray"
            />
          </Box>
        );
      },
    },

    {
      name: "Ações",
      render: (row: CampanhaSMSRow) => {
        const totalSended = row.errorsLength + row.successLength;
        const totalMessages =
          row.errorsLength + row.successLength + row.waitingLength;
        const isFinished = totalSended / totalMessages === 1;
        const enableRetry =
          (row.statusCampanha !== "PROCESSANDO" &&
            row.errorsMessages
              .map(({ message: m }) => {
                m = m.toLowerCase();
                return m.includes("saldo") || m.includes("indisponível");
              })
              .includes(true)) ||
          row.errorMessageCampanha;
        const isPlay = row.statusCampanha === "NAO_INICIADA";

        return (
          <Flex>
            <IconButtonWithLoading
              tooltipMessage="Ver erros da campanha"
              aria-label=""
              icon={<WarningTwoIcon />}
              mr="8px"
              onClick={() => openModalErrorsCampanhaSMS(row)}
              isDisabled={
                !row.errorsMessages.length && !row.errorMessageCampanha
              }
            />
            <IconButtonWithLoading
              tooltipMessage="Opções da campanha"
              aria-label=""
              icon={<SettingsIcon />}
              mr="8px"
              onClick={() => openModalCampanhaSMS(row)}
              isDisabled={
                !(
                  row.statusCampanha === "FINALIZADO" ||
                  row.statusCampanha === "AGUARDANDO_CONFIGURACAO" ||
                  row.statusCampanha === "NAO_INICIADA"
                ) || isFinished
              }
            />
            <IconButtonWithLoading
              tooltipMessage={
                isPlay ? "Iniciar Campanha" : "Refazer envio de SMSs com erros"
              }
              aria-label=""
              icon={isPlay ? <FaPlay /> : <RepeatIcon />}
              mr="8px"
              onClick={isPlay ? play(row) : retry(row)}
              isDisabled={isPlay ? false : !enableRetry}
            />
            <Tooltip label="Deletar campanha">
              <IconButton
                aria-label=""
                icon={<DeleteIcon />}
                variant="danger"
                isDisabled={
                  row.statusCampanha === "CRIANDO_SMS" ||
                  row.statusCampanha === "PROCESSANDO" ||
                  row.statusCampanha === "EM_ANDAMENTO"
                }
                onClick={() =>
                  openModalConfirm({
                    message: `Deseja excluir "${row.nome}" ?`,
                    onConfirm: () => acceptDeleteCampanha(row),
                    confirmButtonStyle: {
                      variant: "danger",
                      leftIcon: <DeleteIcon />,
                      loadingText: "Excluindo",
                    },
                  })
                }
              />
            </Tooltip>
          </Flex>
        );
      },
    },
  ];

  return (
    <GetLayout>
      <Flex justifyContent="space-between" px="10px" mb="30px">
        <Text fontSize="25" fontWeight="bold">
          Campanhas SMS
        </Text>

        <Flex gap="20px" alignItems="center">
          <DownloadSMSRelatory
            tableRows={allCampanhas}
            filter={filter}
            setFilter={setFilter}
            update={() => {
              getPowerhubChart();
              getBarsChartData();
              updateCampaignList(true);
            }}
          />
          <Button
            leftIcon={<RepeatIcon />}
            onClick={() => {
              getPowerhubChart();
              getBarsChartData();
              updateCampaignList(true);
            }}
            isLoading={loading.campaignList}
            loadingText="Atualizando"
          >
            Atualizar
          </Button>
        </Flex>
      </Flex>
      <Grid
        templateColumns="1fr 1fr"
        gap="16px"
        mb="20px"
        w="100%"
        minH="266px"
      >
        <Center flexDir="column" borderRadius="16px" bg="#fff" p="16px">
          <Text fontSize="20" mr="auto" fontWeight="bold" mb="20px">
            Estatísticas Powerhub
          </Text>
          <Center mx="auto" w="95%">
            <FunilDeDados
              data={estatisticas}
              colors={estatisticsColors}
              isLoading={loading.powerhubChart}
            />
          </Center>
        </Center>
        <Center flexDir={"column"} borderRadius="16px" bg="#fff" p="16px">
          <Text fontSize="20" mr="auto" fontWeight="bold" mb="20px">
            Estatísticas API SMS
          </Text>
          <BarsChart
            data={barsChartData}
            id="campanhas-sms"
            isLoading={loading.barsChart}
          />
        </Center>
      </Grid>
      <DynamicTable
        columns={columns}
        rows={filteredCampanhas}
        isLoading={loading.campaignList}
      />
      <ModalCampanhaSMS
        updateCampanhas={(updated) => {
          const newFilteredCampanhasValue =
            typeof updated === "function"
              ? updated(filteredCampanhas)
              : updated;
          const newAllCampanhasValue =
            typeof updated === "function" ? updated(allCampanhas) : updated;
          setFilteredCampanhas(newFilteredCampanhasValue);
          setAllCampanhas(newAllCampanhasValue);
        }}
      />
      <ModalErrorsCampanhaSMS />
    </GetLayout>
  );
};
