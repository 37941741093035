import { CloseIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Grid, Link, Text } from "@chakra-ui/react";
import apiDigitacao from "api/api-digitacao";
import { Attendance } from "components/atendimentos-components/atendimento-form/types";
import { CustomModal } from "components/custom-modal";
import DynamicTable from "components/dynamic-table";
import { Column } from "components/dynamic-table/types";
import { useState } from "react";
import { makeEvent, useEventListener } from "services/events";
import { DigitadoRow } from "..";
import { Toast } from "components/toast";
import {
  bgError,
  bgSucess,
  bgWarning,
  StatusFormat,
} from "components/dynamic-table/row-status";

interface StatusDigitacaoRow {
  datetime: string;
  status: string;
  description?: string;
}

interface INSSData {
  banco?: string | null;
  status?: null | "PENDENT" | "FINISHED";
  codeRequestInss?: string | null;
  numeroProposta?: string | null;
  urlFormalizacao?: string | null;
  message?: string | null;
  valorLiberadoCliente?: number;
}

interface ModalInitialProps {
  row: DigitadoRow;
  onChangeRow: (k: keyof DigitadoRow | null, v: any) => void;
  statusList?: StatusDigitacaoRow[];
}

export function ModalConsultarProposta({
  attendance,
}: {
  attendance: Attendance;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let [modalData, setModalData] = useState<Partial<ModalInitialProps>>({});
  const [inssData, setInssData] = useState<INSSData>({});

  const onOpen = async (props: ModalInitialProps) => {
    setIsOpen(true);
    getStatusList(props.row);
    onChange(null, props);
  };

  const onClose = () => {
    setIsOpen(false);
    setModalData({});
  };

  const onChange = (k: keyof ModalInitialProps | null, value: any) => {
    if (k == null) {
      modalData = value;
      setModalData(modalData);
    } else {
      modalData = { ...modalData, [k]: value };
      setModalData(modalData);
    }
  };

  const getStatusList = async (row: DigitadoRow) => {
    let keys: {
      [k: string]: keyof DigitadoRow;
    } = {
      urlFormalizacao: "linkFormalizacao",
      numeroProposta: "ade",
      codeRequestInss: "codeRequestDigitacao",
      message: "messageDigitacao",
      status: "status",
      banco: "banco",
      valorLiberadoCliente: "valorLiquidoCliente",
    };
    const url = `/api/digitacoes/inss/atendimento/${attendance.id}/consultar/${row.id}`;
    setIsLoading(true);
    try {
      const { data } = await apiDigitacao.get<INSSData>(url);
      setInssData(data);
      Object.keys(data).forEach((k) => {
        let value = data[k as keyof INSSData];
        if (keys[k] && value != null) {
          if (k === "status") {
            if (value === "PENDENT") value = "PENDING";
            modalData.onChangeRow?.(keys[k], value);
          } else modalData.onChangeRow?.(keys[k], value);
        }
      });
    } catch (e) {
      Toast({
        title: "Erro ao consultar o acompanhamento da proposta",
        description: "Tente novamente",
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEventListener("openModalConsultarProposta", onOpen);

  const modalFooter = (
    <>
      <Button
        variant="outline"
        leftIcon={<CloseIcon w="12px" h="12px" />}
        onClick={onClose}
      >
        Fechar
      </Button>
    </>
  );

  return (
    <CustomModal
      modalTitle="Consultar"
      isOpen={isOpen}
      onClose={onClose}
      modalFooter={modalFooter}
      size={"3xl"}
      isLoading={isLoading}
    >
      <Flex
        flexDir="column"
        border="1px solid #E8EAED"
        bg="#F5F7F9"
        p="10px 15px"
        borderRadius="5px"
        _hover={{ bg: "gray.100" }}
        transition="0.2s"
      >
        <Grid templateColumns="1fr 1fr 1fr 1fr" gap="10px">
          <Box>
            <Text fontWeight="bold" mb="8px">
              Banco:
            </Text>{" "}
            <Text whiteSpace="nowrap">{inssData.banco}</Text>
          </Box>
          <Box>
            <Text fontWeight="bold" mb="8px">
              Status Fila:
            </Text>{" "}
            <StatusFormat
              bg={parseStatus.get(inssData.status || "")?.color}
              whiteSpace="nowrap"
            >
              {parseStatus.get(inssData.status || "")?.desc || ""}
            </StatusFormat>
          </Box>
          <Box gridArea="auto / span 2">
            <Text fontWeight="bold" mb="8px">
              Observação:
            </Text>{" "}
            <Text>{inssData.message}</Text>
          </Box>
          <Box>
            <Text fontWeight="bold" mb="8px">
              Status Digitação:
            </Text>{" "}
            <StatusFormat
              bg={parseCodigo.get(inssData.codeRequestInss || "")?.color}
              whiteSpace="nowrap"
            >
              {parseCodigo.get(inssData.codeRequestInss || "")?.desc || ""}
            </StatusFormat>
          </Box>

          <Box>
            <Text fontWeight="bold" mb="8px">
              ADE:
            </Text>{" "}
            <Text whiteSpace="nowrap">{inssData.numeroProposta}</Text>
          </Box>
          <Box gridArea="auto / span 2">
            <Text fontWeight="bold" mb="8px">
              URL Formalização:
            </Text>{" "}
            <Link
              target="_blank"
              // whiteSpace="nowrap"
              href={inssData.urlFormalizacao || ""}
            >
              {inssData.urlFormalizacao}
            </Link>
          </Box>
        </Grid>
      </Flex>
    </CustomModal>
  );
}

export const openModalConsultarProposta = (props: ModalInitialProps) =>
  makeEvent("openModalConsultarProposta", props);

const parseCodigo = new Map([
  ["SUCCESS", { desc: "Sucesso", color: bgSucess }],
  ["SYSTEM_ERROR", { desc: "Erro", color: bgError }],
]);
const parseStatus = new Map([
  ["PENDENT", { desc: "Pendente", color: bgWarning }],
  ["FINISHED", { desc: "Finalizado", color: bgSucess }],
  ["SYSTEM_ERROR", { desc: "Erro", color: bgError }],
]);
